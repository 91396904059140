import { Button, Col, Form, Input, message, Modal, notification, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { register, searchUser } from '../../../reducers/authReducer';
import InputOTPs from '../../Auth/Register/InputOTPs';
import axios from 'axios';
import { REACT_APP_API_KEY } from '../../../constant/data';

const SearchAccount = ({ open, onClose,handleCreateSuccess }) => {
  const supplierId = localStorage.getItem('supplierId');
  const [listUsers, setListUsers] = useState([])
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const searchUserBranch = () => {
    dispatch(
      searchUser({
        supplierId:supplierId,
        query:form.getFieldValue('name')
      }, {
        onSuccess: (res) => {
          setListUsers(res?.data?.data?.data)
        },
        onError: (er) => {
          notification.error({
            message: `Không tìm thấy tài khoản nào có số điện thoại này. Vui lòng thêm tài khoản ncc trước khi tạo.`,
          });
        },
      }))
  }

  const onFinish = (values) => {
    searchUserBranch()
  };

  return (
    <Modal
      title={`TÌM KIẾM TÀI KHOẢN CHI NHÁNH`}
      visible={open}
      onCancel={onClose}
      width={600}
      bodyStyle={{
        borderRadius: '10px',
      }}
      footer={null}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
        style={{ maxWidth: 1000 }}
        scrollToFirstError
      >
        <Form.Item  extra="Nhập số điện thoại hoặc username của admin chi nhánh">
          <Row gutter={12}>
            <Col span={18}>
              <Form.Item
                name="name"
                noStyle
                rules={[{ required: true, message: 'Please input the captcha you got!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button type="primary" onClick={searchUserBranch}>Tìm kiếm</Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default SearchAccount;
