import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Breadcrumb, Button, Col, Form, Input, notification, Row, Table, Tooltip } from 'antd';

import formatMoney from '../../components/ConvertMoney';
import { updBill } from '../../reducers/billReducer';
import axios from '../../services/Api';

import moment from 'moment';

const UpdBill = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formCustomer] = Form.useForm();
  const [formBooking] = Form.useForm();
  const { TextArea } = Input;
  const id = window.location.href.split('updBill/')[1];
  const [dataBill, setDataBill] = useState([]);
  const [dataIns, setDataIns] = useState([]);
  const [money, setMoney] = useState(0);
  const [createdDate, setCreatedDate] = useState();
  const [bookingCode, setBookingCode] = useState('');
  const [discountMoney, setDiscountMoney] = useState(0);
  const [discountMoneyNow, setDiscountMoneyNow] = useState(0);
  const [totalMoney, setTotalMoney] = useState(0);

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  // const totalMoney = useMemo(() => {
  //   const result = +money - +discountMoney;
  //   return +result;
  // }, [discountMoney, money]);

  useEffect(() => {
    if (id !== undefined) {
      getDataBillById();
    }
  }, []);

  const getDataBillById = () => {
    axios.get(`/bill/get-id?id=${id}`).then((response) => {
      setDataBill(response?.data?.data?.billDetails);
      formCustomer.setFieldsValue({
        customerName: response.data.data.customerName,
        phone: response.data.data.phone,
        mail: response.data.data.mail,
      });
      setDiscountMoneyNow(response.data.data.discountMoney);
      setMoney(response.data.data.initialPrice);
      setBookingCode(response.data.data.bookingCode);
      setCreatedDate(moment(response.data.data.createdDate).format('DD/MM/YYYY'));
      setDiscountMoney(response.data.data.discountMoney || 0);
      setTotalMoney(response.data.data.totalMoney || 0);
    });
  };

  useMemo(() => {
    const arr = dataBill;
    for (let i = 0; i < arr.length; i++) {
      const newArr = {
        id: arr[i]?.id,
        price: arr[i]?.price,
        employeeId: arr[i]?.employeeId,
        serviceUserId: arr[i]?.serviceId,
      };
      setDataIns((prev) => [...prev, newArr]);
    }
  }, [dataBill]);

  const onUpd = (value) => {
    const body = {
      id: id,
      totalMoney: totalMoney,
      discountMoney: discountMoney,
      customerName: value?.customerName,
      phone: value?.phone,
      mail: value?.mail,
      billDetails: dataIns,
    };
    dispatch(
      updBill(body, {
        onSuccess: () => {
          notification.success({
            message: `Sửa thông tin hóa đơn thành công`,
          });
          history.push('/bill');
        },
        onError: () => {
          notification.error({
            message: `Sửa thông tin hóa đơn thất bại`,
          });
        },
      })
    );
  };

  const columns = [
    {
      title: 'Tên dịch vụ',
      dataIndex: 'service',
      key: 'service',
      width: '150px',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Giá dịch vụ',
      dataIndex: 'price',
      key: 'price',
      width: '150px',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Nhân viên phục vụ',
      dataIndex: 'employee',
      key: 'employee',
      width: '150px',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'realTime',
      key: 'realTime',
      width: '180px',
      ellipsis: {
        showTitle: false,
      },
      responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
      render: (value) => (
        <Tooltip placement="topLeft" title={moment(value).format('DD/MM/YYYY - HH:mm:ss')}>
          <span> {moment(value).format('DD/MM/YYYY - HH:mm:ss')}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'endTime',
      key: 'endTime',
      width: '180px',
      ellipsis: {
        showTitle: false,
      },
      responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
      render: (value) => (
        <Tooltip placement="topLeft" title={moment(value).format('DD/MM/YYYY - HH:mm:ss')}>
          <span> {moment(value).format('DD/MM/YYYY - HH:mm:ss')}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Mã giảm giá',
      dataIndex: 'voucherCode',
      key: 'voucherCode',
      width: '150px',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Số tiền giảm',
      dataIndex: 'discountVoucher',
      key: 'discountVoucher',
      width: '150px',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {FormatMoney(money) + 'VND'}
        </Tooltip>
      ),
    },
    {
      title: 'Nhân viên phục vụ',
      dataIndex: 'employee',
      key: 'employee',
      width: '150px',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/bill'}>
                {' '}
                Danh sách hóa đơn{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>
              Chỉnh sửa hóa đơn [ {bookingCode} - {createdDate} ]
            </b>
          </div>
        </Breadcrumb>
      </Row>
      <br />
      <Form form={formCustomer} onFinish={onUpd} labelCol={{ span: 6 }} wrapperCol={{ span: 24 }}>
        <Row span={24} style={{ borderTop: '1px solid #d9d9d9' }}>
          <Col
            span={9}
            style={{
              borderRight: '1px solid #d9d9d9',
              paddingTop: '10px',
              paddingRight: '10px',
            }}
          >
            <Form.Item>
              <h1 style={{ fontWeight: 'bold' }}>Thông tin Thành viên</h1>
            </Form.Item>
            <Row span={24}>
              <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
                <Form.Item
                  label="Họ tên:"
                  style={{ fontWeight: '500' }}
                  name="customerName"
                  rules={[{ required: true, message: 'Nhập tên Thành viên' }]}
                >
                  <Input placeholder="Tên Thành viên" />
                </Form.Item>

                <Form.Item
                  label="Điện thoại:"
                  style={{ fontWeight: '500' }}
                  name="phone"
                  children
                  rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                >
                  <Input placeholder="Số điện thoại" maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}/>
                </Form.Item>

                <Form.Item label="Email" style={{ fontWeight: '500' }} name="mail">
                  <Input placeholder="Nhập Email" />
                </Form.Item>
              </Col>

              <Col span={24} style={{ paddingTop: '10px' }}>
                {/* <Form.Item label="Tiền giảm" style={{ fontWeight: 'bold' }}>
                  <Input
                    suffix="VND"
                    style={{ fontWeight: '500', color: 'black' }}
                    placeholder="Tiền giảm"
                    value={discountMoney}
                    onChange={(e) => {
                      setDiscountMoney(e.target.value);
                      setTotalMoney(+money - +e.target.value);
                    }}
                  />
                </Form.Item> */}
                {/* <Form.Item label="Tổng tiền đã giảm" style={{ fontWeight: 'bold' }}>
                  <Input
                    value={FormatMoney(discountMoneyNow) + 'VND'}
                    disabled
                    style={{
                      borderRadius: 5,
                      border: '1px solid #37B59D',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                    placeholder="Tổng tiền đã giảm"
                  />
                </Form.Item> */}
                <Form.Item label="Tổng tiền đã giảm" style={{ fontWeight: '500' }}>
                  <Input
                    value={FormatMoney(discountMoneyNow) + 'VND'}
                    disabled
                    style={{ fontWeight: '500', color: 'red' }}
                    placeholder="Tổng tiền"
                  />
                </Form.Item>
                <Form.Item label="Tổng tiền gốc" style={{ fontWeight: '500' }}>
                  <Input
                    value={FormatMoney(money) + 'VND'}
                    disabled
                    style={{ fontWeight: '500', color: 'red' }}
                    placeholder="Tổng tiền"
                  />
                </Form.Item>
                <Form.Item label="Tổng tiền" style={{ fontWeight: '500' }}>
                  <Input
                    value={FormatMoney(totalMoney) + 'VND'}
                    disabled
                    style={{ fontWeight: '500', color: 'red' }}
                    placeholder="Tổng tiền"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={15} style={{ paddingLeft: '10px', paddingTop: '10px' }}>
            <Form autoComplete="off">
              <Form.Item>
                <h1 style={{ fontWeight: 'bold' }}>Thông tin dịch vụ</h1>
              </Form.Item>

              <Form.Item>
                <Table columns={columns} dataSource={dataBill} size="small" pagination={false} scroll={{ x: 'calc(450px + 50%)' }}/>
              </Form.Item>
            </Form>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button
                style={{
                  marginLeft: '6px',
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                }}
                type="primary"
                htmlType="submit"
              >
                Xác nhận
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default UpdBill;
