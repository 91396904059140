import { postFormDataFile } from '../constant/configAxios';
import { REACT_APP_API_KEY } from '../constant/data';
import axios from '../services/Api';

export const UPLOAD_FILE = 'UPLOAD_FILE';

export const uploadFile = (formData, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: UPLOAD_FILE,
      data: await postFormDataFile(`${REACT_APP_API_KEY}/auth/test-public/upload-file`, { obj: formData }),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

