import axios from '../services/Api';

export const INSBILL = 'INSBILL';
export const UPDBILL = 'UPDBILL';

export const insBill = (bookingId, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSBILL,
      data: await axios.post(`/bill/insert?bookingId=${bookingId}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const updBill = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: UPDBILL,
      data: await axios.post(`/bill/update`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};
