import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  NotificationOutlined,
  FileSearchOutlined,
  GiftFilled,
  MessageOutlined,
  FileImageOutlined,
  ProfileOutlined,
  ReadOutlined,
  RocketOutlined,
  StarOutlined,
  TeamOutlined,
  UserAddOutlined,
  DeploymentUnitOutlined,
} from '@ant-design/icons';
import { Badge, Image, Layout, Menu } from 'antd';
import axios from '../../services/Api';
import logo from '../../assets/logowhite.png';

import './DefaultLayout.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { REACT_APP_API_KEY_IMAGE } from '../../constant/data';
import { getSupplierInfo } from '../../reducers/supplierReducer';

const POOLING_TIME_DEFAULT = 1000 * 60 * 2;
export default function DefaultSidebar({ collapsed }) {
  const dispatch = useDispatch();
  const dataUser = useSelector((store) => store?.authReducer.infoUser);
  const supplierId = localStorage.getItem('supplierId');
  const [totalStatus, setTotalStatus] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [images, setImages] = useState('');

  const { Sider } = Layout;
  const getItem = (label, key, icon, children) => {
    return { label, key, icon, children };
  };

  useEffect(() => {
    const today = new Date();
    const todayDate = new Date().toLocaleDateString('sv-SE');
    today.setDate(today.getDate() - 7);
    const date = new Date(today).toLocaleDateString('sv-SE');
    setStartDate(moment(date).format('DD-MM-YYYY') + ' ' + '00:00:00');
    setEndDate(moment(todayDate).format('DD-MM-YYYY') + ' ' + '23:59:59');
  }, []);

  const getSupplier = () => {
    dispatch(
      getSupplierInfo({}, {
        onSuccess: (res) => {
          if (res.data.data.length > 0) {
            const data = res.data.data[0];
            setImages(data.image === null ? images : data?.image);
          }
        },
      })
    );
  };

  useEffect(() => {
    getSupplier()
  }, []);

  const getTotalStatus = () => {
    axios
      .get(`/booking/count?supplier=${supplierId}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => {
        setTotalStatus(response?.data?.data?.waiting);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(!!supplierId){
        getTotalStatus();
      }
    }, POOLING_TIME_DEFAULT);

    return () => {
        clearInterval(interval);
    };
}, [POOLING_TIME_DEFAULT]);

  useEffect(() => {
    if(!!supplierId){
      getTotalStatus();
    }
  }, [supplierId,startDate,endDate]);

  const dataMenu = [
    getItem(
      <a style={{ color: '#FFFFFF' }}>Cộng đồng</a>,
      '1',
      <DeploymentUnitOutlined className="icon-color" />,
      [
        getItem(
          <Link style={{ color: '#FFFFFF' }} to={'/'}>
            {' '}
            Cộng đồng{' '}
          </Link>,
          '1-1'
        ),
        getItem(
          <Link style={{ color: '#FFFFFF' }} to={'/child-supplier'}>
            {' '}
            Chi nhánh{' '}
          </Link>,
          '1-2'
        ),
      ]
    ),
    getItem(
      <Link style={{ color: '#FFFFFF' }} to={'/booking'}>
        Booking <Badge size="small" count={!!totalStatus  ? totalStatus : 0} />
      </Link>,
      '2',
      <ProfileOutlined className="icon-color" />,
    ),
    (dataUser?.email == "vveco@gmail.com") ?   getItem(
      <Link style={{ color: '#FFFFFF' }} to={'/banners'}>
        Banners{' '}
      </Link>
      ,
      '20',
      <FileImageOutlined className="icon-color"/>
      ,
    ) : '',
    getItem(
      <a style={{ color: '#FFFFFF' }}> Dịch vụ</a>,
      '3',
      <RocketOutlined className="icon-color" />,
      [
        getItem(
          <Link style={{ color: '#FFFFFF' }} to={'/grService'}>
            {' '}
            Nhóm dịch vụ{' '}
          </Link>,
          '3-1'
        ),
        getItem(
          <Link style={{ color: '#FFFFFF' }} to={'/serviceVb'}>
            {' '}
            Dịch vụ{' '}
          </Link>,
          '3-2'
        ),
      ]
    ),
    getItem(
      <Link style={{ color: '#FFFFFF' }} to={'/bill'}>
        {' '}
        Hóa đơn{' '}
      </Link>,
      '4',
      <FileSearchOutlined className="icon-color" />
    ),
    getItem(
      <Link style={{ color: '#FFFFFF' }} to={'/staff'}>
        {' '}
        Nhân viên{' '}
      </Link>,
      '5',
      <UserAddOutlined className="icon-color" />
    ),
    getItem(
      <Link style={{ color: '#FFFFFF' }} to={'/customer'}>
        {' '}
        Thành viên{' '}
      </Link>,
      '6',
      <TeamOutlined className="icon-color" />
    ),
    // getItem(
    //   <Link style={{ color: '#FFFFFF' }} to={'/report'}>
    //     Thống kê{' '}
    //   </Link>,
    //   '7',
    //   <BarChartOutlined className="icon-color" />
    // ),
    // getItem(<Link style={{ color: "#FFFFFF" }} to={"/setting"}> Cấu hình </Link>, "8", <SettingFilled className="icon-color" />),
    getItem(
      <Link style={{ color: '#FFFFFF' }} to={'/discount'}>
        {' '}
        Voucher{' '}
      </Link>,
      '9',
      <GiftFilled className="icon-color" />
    ),
    getItem(
      <Link style={{ color: '#FFFFFF' }} to={'/blogs'}>
        {' '}
        Bài viết
      </Link>,
      '10',
      <ReadOutlined className="icon-color" />
    ),
    (dataUser?.email == "vveco@gmail.com") ?  getItem(<Link style={{ color: "#FFFFFF" }} to={"/noti-to-sb"}> Gửi thông báo </Link>, "11", <NotificationOutlined className="icon-color" />) : '',
    // getItem(<Link style={{ color: "#FFFFFF" }} to={"/feedback"}> Đánh giá</Link>, "12", <StarOutlined className="icon-color" />),
    getItem(
      <a style={{ color: '#FFFFFF' }}> Đánh giá</a>,
      '11',
      <StarOutlined className="icon-color" />,
      [
        getItem(
          <Link style={{ color: '#FFFFFF' }} to={'/feedBackSupplier'}>
            {' '}
            Đánh giá của cộng đồng{' '}
          </Link>,
          '11-1'
        ),
        getItem(
          <Link style={{ color: '#FFFFFF' }} to={'/feedBackService'}>
            {' '}
            Đánh giá của dịch vụ{' '}
          </Link>,
          '11-2'
        ),
      ]
    ),
    // getItem(
    //   <Link style={{ color: '#FFFFFF' }} to={'/message'} target="_blank">
    //     {' '}
    //     Tin nhắn
    //   </Link>,
    //   '12',
    //   <MessageOutlined className="icon-color" />
    // ),

    // getItem(<a style={{ color: "#FFFFFF" }}>Demo</a>, "131", <CodeSandboxOutlined className="icon-color" />,
    //   [
    //     getItem(<Link style={{ color: "#FFFFFF" }} to={"/demoUserRef"}>useRef</Link>, "131-1"),
    //     getItem(<Link style={{ color: "#FFFFFF" }} to={"/demoMemo"}>Memo </Link>, "131-2"),
    //     getItem(<Link style={{ color: "#FFFFFF" }} to={"/demoUseMemo"}>useMemo </Link>, "131-3"),
    //     getItem(<Link style={{ color: "#FFFFFF" }} to={"/demoUseReducer"}>useReducer </Link>, "131-4"),
    //     getItem(<Link style={{ color: "#FFFFFF" }} to={"/todoAppwithuseReducer"}>TodoAppwithuseReducer </Link>, "131-5"),
    //   ]
    // ),
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ overflow: 'auto', height: '100vh' }}
    >
      <Image
        src={images ? `${REACT_APP_API_KEY_IMAGE}${images}` : logo}
        preview={false}
        style={{
          paddingTop: 15,
          width: '40%',
          height: '40%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <Menu theme="dark" mode="inline" items={dataMenu} />
    </Sider>
  );
}
