import React from 'react';
import { useDispatch } from 'react-redux';

import { Form, Modal, Select } from 'antd';
// import { addAccount } from "../../reducers/staffReducer";

const InsSFSchedule = (props) => {
  const dispatch = useDispatch();
  const { show, handleCancel } = props;
  const [form] = Form.useForm();

  const handleChange = (value) => {};

  const handleCancelIns = () => {
    handleCancel();
    form.setFieldsValue({
      username: '',
      password: '',
      name: '',
      email: '',
      phone: '',
    });
  };

  const onIns = (values) => {
    const body = {
      code: values?.code,
      name: values?.name,
      address: values?.address,
      phone: values?.phone,
    };
    handleCancelIns();
  };

  return (
    <Modal
      title={`ĐĂNG KÝ CA LÀM VIỆC`}
      visible={show}
      width="25%"
      onCancel={handleCancelIns}
      okButtonProps={{
        form: 'insSFSchedule',
        key: 'submit',
        htmlType: 'submit',
      }}
      bodyStyle={{
        borderRadius: '10px',

        height: '10%',
      }}
    >
      <Form
        form={form}
        id="insSFSchedule"
        onFinish={onIns}
        autoComplete="off"
        hideRequiredMark
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        {/* <Form.Item
          label="Chọn ngày"
          name="date"
          style={{ fontWeight: "bold", marginRight: 120 }}
        >
          <Space style={{ width: 150, marginLeft: 30, borderRadius: 15 }}>
            <DatePicker onChange={onChange} />
          </Space>
        </Form.Item> */}

        <Form.Item
          label="Ca làm việc"
          name="checkPassword"
          style={{ fontWeight: 'bold', marginRight: 120 }}
          rules={[{ required: true, message: 'Chọn thời gian bắt đầu làm việc' }]}
        >
          <Select
            placeholder="Chọn ca làm việc"
            style={{
              width: 150,
              marginLeft: 30,
              borderRadius: 5,
            }}
            onChange={handleChange}
            options={[
              {
                value: 'Ca1',
                label: '7h30 - 9h30',
              },
              {
                value: 'Ca2',
                label: '9h30 - 11h30',
              },

              {
                value: 'Ca3',
                label: '11h30 - 13h30',
              },
              {
                value: 'Ca4',
                label: '13h30 - 15h30',
              },
              {
                value: 'Ca5',
                label: '15h30 - 17h30',
              },
              {
                value: 'Ca6',
                label: '17h30 - 19h30',
              },
              {
                value: 'Ca7',
                label: '19h30 - 21h30',
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Chọn nhân viên"
          name="employee"
          style={{ fontWeight: 'bold', marginRight: 120 }}
          rules={[{ required: true, message: 'Vui lòng chọn nhân viên' }]}
        >
          <Select
            placeholder="Chọn nhân viên"
            style={{
              width: 150,
              marginLeft: 30,
              borderRadius: 5,
            }}
            onChange={handleChange}
            options={[
              {
                value: 'nv1',
                label: 'Kiều Duy Lưu',
              },
              {
                value: 'nv2',
                label: 'Hoàng Đình Du',
              },

              {
                value: 'nv3',
                label: 'Trần Anh Vũ',
              },
              {
                value: 'nv4',
                label: 'Phạm Thế Công',
              },
              {
                value: 'nv5',
                label: 'Nguyễn Đức Minh',
              },
            ]}
          />
        </Form.Item>
        {/* 
        <Form.Item
          label="Email"
          name="email"
          style={{ fontWeight: "bold" }}
          rules={[{ required: true, message: "Nhập Email" }]}
        >
          <Input
            style={{ borderRadius: 5, border: "1px solid #37B59D" }}
            placeholder="Email"
          />
        </Form.Item>  */}
      </Form>
    </Modal>
  );
};
export default InsSFSchedule;
