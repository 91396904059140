import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'antd';

const DemoUserRef = () => {
  const [count, setCount] = useState(60);
  const timerId = useRef();
  const prevCount = useRef();
  const h1Ref = useRef();
  // const handleStart = () => {
  //     setInterval(() => {
  //         setCount(prev => prev - 1)
  //     }, 1000)
  // }

  // lay giá trị trc đó của count
  useEffect(() => {
    prevCount.current = count;
  }, [count]);

  useEffect(() => {
    const rect = h1Ref.current.getBoundingClientRect();
    console.log('rect', rect);
  });

  const handleStart = () => {
    timerId.current = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);
  };

  const handleStop = () => {
    clearInterval(timerId.current);
  };

  console.log(count, prevCount.current);

  return (
    <div style={{ padding: 20 }}>
      <h1 ref={h1Ref}>{count}</h1>
      <Button
        type="primary"
        style={{
          marginRight: '6px',
          backgroundColor: 'rgb(55, 181, 157)',
          borderRadius: 5,
          border: 'rgb(55, 181, 157)',
        }}
        onClick={handleStart}
      >
        Đếm ngược
      </Button>
      <Button
        type="primary"
        style={{
          marginRight: '6px',
          backgroundColor: 'rgb(55, 181, 157)',
          borderRadius: 5,
          border: 'rgb(55, 181, 157)',
        }}
        onClick={handleStop}
      >
        Dừng
      </Button>
    </div>
  );
};

export default DemoUserRef;
