import React, { useReducer, useRef } from 'react';

const initState = {
  job: '',
  jobs: [],
};

const SET_JOB = 'SET_JOB';
const ADD_JOB = 'ADD_JOB';
const DELETE_JOB = 'DELETE_JOB';

const setJob = (payload) => {
  return {
    type: SET_JOB,
    payload,
  };
};

const addJob = (payload) => {
  return {
    type: ADD_JOB,
    payload,
  };
};
const deleteJob = (payload) => {
  return {
    type: DELETE_JOB,
    payload,
  };
};

const reducer = (state, action) => {
  console.log('action', action);
  console.log('Prev State', state);
  let newState;

  switch (action.type) {
    case SET_JOB:
      newState = {
        ...state,
        job: action.payload,
      };
      break;
    case ADD_JOB:
      newState = {
        ...state,
        jobs: [...state.jobs, action.payload],
      };
      break;
    case DELETE_JOB:
      const newJobs = [...state.jobs];
      newJobs.splice(action.payload, 1);
      newState = {
        ...state,
        jobs: newJobs,
      };
      break;
    default:
      throw new Error('action');
  }
  console.log('New State', newState);
  return newState;
};

function TodoAppwithuseReducer() {
  const [state, dispatch] = useReducer(reducer, initState);
  const { job, jobs } = state;

  const inputRef = useRef();

  const handleSubmit = () => {
    dispatch(addJob(job));
    dispatch(setJob(''));
    inputRef.current.focus();
  };

  return (
    <div style={{ padding: '0 20px' }}>
      <h3>Todo</h3>
      <input
        ref={inputRef}
        value={job}
        placeholder="Enter todo..."
        onChange={(e) => {
          dispatch(setJob(e.target.value));
        }}
      />
      <button style={{ marginLeft: '10px' }} onClick={handleSubmit}>
        Add
      </button>
      <ul>
        {jobs.map((job, index) => (
          <li key={index}>
            {job}
            <button style={{ marginLeft: '10px' }} onClick={() => dispatch(deleteJob(index))}>
              {' '}
              -- Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TodoAppwithuseReducer;
