import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const markers = [
  {
    id: 1,
    name: 'Vị trí của bạn',
    position: { lat: 21.022837, lng: 105.799625 },
  },
];

function GoogleMaps({ markerDefaults, setMarkerDefaults }) {
  const [activeMarker, setActiveMarker] = useState(null);
  const [makerItem, setMakerItem] = useState(markers);
  const mapRef = useRef(null);
  const searchBox = useRef(null);
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (!!markerDefaults) {
      setMakerItem([
        {
          id: 1,
          name: 'Vị trí của bạn',
          position: markerDefaults,
        },
      ]);
    }
  }, [markerDefaults]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
  };

  const mapContainerStyle = {
    width: '400px',
    height: '400px',
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleCenterChanged = () => {
    const newCenter = mapRef.current.getCenter().toJSON();
    setMakerItem([
      {
        id: 1,
        name: 'Vị trí của bạn',
        position: newCenter,
      },
    ]);
    setMarkerDefaults(newCenter);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    makerItem.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
    mapRef.current = map;
    mapRef.current.addListener('center_changed', handleCenterChanged);
  };

  return (
    // <LoadScript libraries={['places']} googleMapsApiKey="AIzaSyAtQwrcO3SLM-5PmFgHLFd31FE-x_o3cg8">
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: '100%', height: '400px' }}
    >
      {makerItem.map(({ id, name, position }) => (
        <Marker key={id} position={position} onClick={() => handleActiveMarker(id)}>
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{name}</div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
    // </LoadScript>
  );
}

export default GoogleMaps;
