import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Col, DatePicker, Divider, Form, Input, Modal, Radio, Row, Select, Steps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const ChangeRole = (props) => {
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { show, handleCancel } = props;
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Step } = Steps;

  const [current, setCurrent] = useState(0);

  const handleCancelIns = () => {
    handleCancel();
    form.setFieldsValue({
      code: '',
      name: '',
      address: '',
      phone: '',
    });
  };

  const onIns = (values) => {
    const body = {
      code: values?.code,
      name: values?.name,
      address: values?.address,
      phone: values?.phone,
    };
    console.log('body', body);
    handleCancelIns();
  };

  // const onChangeTime = (date, dateString) => {
  //   console.log(date, dateString);
  // };

  // const onChange = (value) => {
  //   console.log("onChange:", value);
  //   setCurrent(value);
  // };

  // Select

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Modal
      title={`Gán quyền`}
      visible={show}
      onCancel={handleCancelIns}
      width={'25%'}
      okButtonProps={{ form: 'addRole', htmlType: 'submit' }}
      bodyStyle={{
        borderRadius: '10px',
      }}
    >
      <Form
        form={form}
        id="addRole"
        onFinish={onIns}
        autoComplete="off"
        hideRequiredMark
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Quyền "
          style={{ fontWeight: 'bold' }}
          name="name"
          rules={[{ required: true, message: 'Chọn quyền' }]}
        >
          <Select
            autoSize
            style={{
              borderRadius: 5,
              border: '1px solid #37B59D',
            }}
            onChange={handleChange}
            options={[
              {
                value: 'Nhân viên',
                label: 'Nhân viên',
              },
              {
                value: 'Admin',
                label: 'Admin',
              },
            ]}
            placeholder="Vui lòng chọn role"
          />
        </Form.Item>
        {/* <Form.Item
          label="Mô tả  "
          style={{ fontWeight: "bold" }}
          name="description"
          rules={[{ required: true, message: "Nhập mô tả" }]}
        >
          <TextArea
            rows={4}
            style={{
              borderRadius: 5,
              border: "1px solid #37B59D",
            }}
            placeholder="Mô tả"
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};
export default ChangeRole;
