import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Form, Image, Input, notification, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

import { getInfoUser, saveInfo } from '../../reducers/authReducer';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../constant/data';
import { handleBeforeUpload } from '../../utils';

export default function InfoUser() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });

  const dataUser = useSelector((store) => store.authReducer.infoUser);
  const [images, setImages] = useState('');

  useEffect(() => {
    dispatch(getInfoUser());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue(dataUser);
  }, [dataUser]);

  const handleChangeLogo = (info) => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    if (status === 'done') {
      const urlList = fileList.map((file) => {
        return file.response?.data;
      });
      setImages(urlList);
    } else if (status === 'error') {
    }
  };

  const onSave = (values) => {
    dispatch(saveInfo({...values},{
      onSuccess: (res) => {
        if(res?.data?.data?.status?.statusCode == '200'){
          notification.success({
            message: `Lưu thông tin thành công !`,
          });
        }
        else{
          notification.error({
            message: res?.data?.data?.status?.message
          });
        }
      
      },
      onError: (res) => {
        notification.error({
          message: res.data?.status?.message,
        });
      },
    }));
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Thông tin tài khoản</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ marginTop: '10px' }}>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={22} lg={18} xl={18} xxl={18}>
          <Form
            form={form}
            onFinish={onSave}
            autoComplete="off"
            labelCol={{ xs: 24, sm: 11, md: 8, lg: 6, xl: 6, xxl: 4 }}
            wrapperCol={{ xs: 24, sm: 13, md: 16, lg: 18, xl: 18, xxl: 20 }}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Ảnh đại diện" style={{ fontWeight: '500' }}>
                  <ImgCrop rotate>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                      headers={JWT_HEADER}
                      method="POST"
                      onChange={handleChangeLogo}
                      beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                    >
                      {images ? (
                        <Image
                          className="imgLogo"
                          src={`${REACT_APP_API_KEY_IMAGE}${images}`}
                          alt="avatar"
                          preview={false}
                        />
                      ) : (
                        <div style={{ fontWeight: 'bold' }}>
                          <PlusOutlined />
                          <div style={{ marginTop: 4, fontWeight: 'bold' }}>Logo</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Form.Item
                  label="Họ và Tên"
                  name="name"
                  rules={[{ required: true, message: 'Nhập họ và tên' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Họ và Tên" />
                </Form.Item>
                <Form.Item
                  label="Số điện thoại"
                  name="phone"
                  rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Số điện thoại"  maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Nhập Email' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ"
                  name="address"
                  rules={[{ message: 'Nhập địa chỉ' }]}
                  style={{ fontWeight: '500' }}
                >
                  <TextArea autoSize placeholder="Địa chỉ" />
                </Form.Item>
              </Col>
              <Col span={24}>
                  <Button
                      style={{ backgroundColor: '#37B59D', borderRadius: 5, border: "#37B59D", color: 'white', float: 'right' }}
                      type="primary"
                      htmlType="submit"
                  >
                      Lưu thông tin
                  </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={1} lg={4} xl={4} xxl={4} />
      </Row>
    </>
  );
}
