import React from 'react';

import { Dropdown, Menu } from 'antd';
import { LockOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

const UserDropdown = ({ onClick, logOut, onChangePassword, children }) => {
  const items = [
   
    {
      label: (
        <div onClick={onClick} style={{ color: '#333333' }}>
          {' '}
          <UserOutlined /> Thông tin
        </div>
      ),
      key: '1',
    },
    {
      label: (
        <div onClick={onChangePassword} style={{ color: '#333333' }}>
          {' '}
          <LockOutlined /> Đổi mật khẩu
        </div>
      ),
      key: '2',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div onClick={logOut} style={{ color: '#333333' }}>
          {' '}
          <LogoutOutlined /> Đăng xuất
        </div>
      ),
      key: '3',
    },
  ];

  return (
    <Dropdown trigger={['click']} menu={{ items }}>
      <div
        style={{
          width: '85%',
          display: 'inline-block',
          textAlign: 'left',
          cursor: 'pointer',
          borderRadius: '30px',
        }}
      >
        {children}
      </div>
    </Dropdown>
  );
};

export default UserDropdown;
