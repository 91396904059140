import React, { useEffect, useState } from 'react';

import { Col, DatePicker, Form, Input, Modal, notification, Row, Select, TimePicker } from 'antd';

export default function UpdDiscount(props) {
  const { TextArea } = Input;
  //   const dispatch = useDispatch();
  const { show, handleCancel } = props;
  const [form] = Form.useForm();
  const supplierId = localStorage.getItem('supplierId');
  return (
    <Modal
      width={'40%'}
      title={`Sửa ưu đãi `}
      visible={show}
      onCancel={handleCancel}
      okButtonProps={{
        form: 'insGroupService',
        key: 'submit',
        htmlType: 'submit',
      }}
      bodyStyle={{ borderRadius: '10px', overflowY: 'scroll', height: 'calc(70vh)' }}
    >
      <Form
        form={form}
        id="insGroupService"
        // onFinish={onIns}
        autoComplete="off"
        hideRequiredMark
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Mã ưu đãi"
          style={{ fontWeight: 'bold' }}
          name="name"
          rules={[{ required: true, message: 'Nhập mã ưu đãi  ' }]}
        >
          <TextArea
            autoSize
            style={{
              borderRadius: 5,
              border: '1px solid #37B59D',
            }}
            placeholder="Mã ưu đãi "
          />
        </Form.Item>
        <Form.Item
          label="Mức giảm "
          style={{ fontWeight: 'bold' }}
          name="name"
          rules={[{ required: true, message: 'Nhập mức giảm  ' }]}
        >
          <TextArea
            autoSize
            style={{
              borderRadius: 5,
              border: '1px solid #37B59D',
            }}
            placeholder="Mức giảm  "
          />
        </Form.Item>
        <Form.Item
          label="Dịch vụ áp dụng  "
          style={{ fontWeight: 'bold' }}
          name="description"
          rules={[{ required: true, message: 'Chọn dịch vụ áp dụng  ' }]}
        >
          <Select
            autoSize
            style={{
              borderRadius: 5,
              border: '1px solid #37B59D',
            }}
            dropdownRender={() => (
              <div
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                Chăm sóc da
              </div>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Ghi chú "
          style={{ fontWeight: 'bold' }}
          name="name"
          rules={[{ required: true, message: 'Nhập ghi chú  ' }]}
        >
          <TextArea
            autoSize
            style={{
              borderRadius: 5,
              border: '1px solid #37B59D',
            }}
            placeholder="Ghi chú  "
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
