import axios from '../services/Api';

export const INSSERTCOMMENTSVADMIN = 'INSSERTCOMMENTSVADMIN';
export const INSSERTCOMMENTSUPADMIN = 'INSSERTCOMMENTSUPADMIN';

export const addCommentAdmin = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSSERTCOMMENTSVADMIN,
      data: await axios.post(`/feedback/admin/comment-service`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const addCommentSupplier = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSSERTCOMMENTSUPADMIN,
      data: await axios.post(`/feedback/admin/comment-supplier`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};
