import { Button, Form, Input, message, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { register } from '../../../reducers/authReducer';
import InputOTPs from '../../Auth/Register/InputOTPs';
import axios from 'axios';
import { REACT_APP_API_KEY } from '../../../constant/data';

const ModalRegisterUser = ({ open, onClose,handleCreateSuccess }) => {
  const handleCancelIns = () => {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [payloadRegister, setPayloadRegister] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getOTP = async (payloadRegister) => {
    return await axios({
      method: 'POST',
      url: `${REACT_APP_API_KEY}/auth/send-otp`,
      params: {gmail:payloadRegister?.email},
    });
  }

  const onRegister = (values) => {
    if (values?.password === values?.repassword) {
      const body = {
        username: values?.username,
        password: values?.password,
        email: values?.email,
        phone: values?.phone,
        name: values?.name,
        avatar: '',
      };
      setPayloadRegister(body)
      setIsLoading(true)
      getOTP(body).then(res =>{
        if(res?.status == 200){
          if(res?.data?.status?.statusCode == 200){
            message.success("Mã OTP đã được gửi tới gmail của bạn!")
            setIsModalVisible(true)
            setTimeout(function() { 
              setIsLoading(false)
            }, 1000);
          }
          else{
            message.error(res?.data?.status?.message || "Lỗi hệ thống!")
            setTimeout(function() { 
              setIsLoading(false)
            }, 1000);
          }
        }
        else{
          message.error("Lỗi hệ thống!")
          setTimeout(function() {   
            setIsLoading(false)
          }, 1000);
        }
      })
    } else {
      notification.error({
        message: `Mật khẩu không trùng khớp`,
      });
    }

  };
  return (
    <Modal
      title={`THÊM MỚI TÀI KHOẢN CHI NHÁNH`}
      visible={open}
      onCancel={onClose}
      width={'400px'}
      bodyStyle={{
        borderRadius: '10px',
      }}
      footer={null}
    >
      <Form
        form={form}
        id="insUser-form"
        name="normal_Resgiter"
        onFinish={onRegister}
        autoComplete="off"
      >
        <Form.Item
          style={{ fontWeight: 'bold' }}
          name="username"
          rules={[{ required: true, message: 'Nhập tên đăng nhập' }]}
        >
          <Input placeholder="Tên đăng nhập" />
        </Form.Item>

        <Form.Item
          style={{ fontWeight: 'bold' }}
          name="password"
          rules={[{ required: true, message: 'Nhập mật khẩu' }]}
        >
          <Input.Password placeholder="Mật khẩu" />
        </Form.Item>

        <Form.Item
          style={{ fontWeight: 'bold' }}
          name="repassword"
          rules={[{ required: true, message: 'Nhập lại mật khẩu' }]}
        >
          <Input.Password placeholder="Nhập lại mật khẩu" />
        </Form.Item>

        <Form.Item
          style={{ fontWeight: 'bold' }}
          name="name"
          rules={[{ required: true, message: ' Nhập Họ và tên' }]}
        >
          <Input placeholder="Họ và tên" />
        </Form.Item>

        <Form.Item
          style={{ fontWeight: 'bold' }}
          name="phone"
          rules={[{ required: true, message: 'Nhập số điện thoại' }]}
        >
          <Input placeholder="Nhập số điện thoại"   
          maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}/>
        </Form.Item>

        <Form.Item
          style={{ fontWeight: 'bold' }}
          name="email"
          rules={[{ required: true, message: 'Nhập email' }]}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: '100%',
              height: '40px',
              borderRadius: 6,
              backgroundColor: '#1877f2',
              border: 'none',
              fontWeight: 'bold',
              fontSize: '1.1em',
            }}
            loading={isLoading}
          >
            Xác nhận
          </Button>
        </Form.Item>
        <hr />
      </Form>
      {
        isModalVisible && <InputOTPs
        isLogged
        onCreateSuccess={(id) => {
          setIsModalVisible(false)
          onClose()
          handleCreateSuccess({
            id,
            username: form?.getFieldValue('username'),
            name: form?.getFieldValue('name'),
          })
        }}
        isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} getOTP={getOTP} payloadRegister={
          payloadRegister
        } />
      }
    </Modal>
  );
};
export default ModalRegisterUser;
