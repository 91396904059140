import axios from '../services/Api';

export const INSSERTGROUP = 'INSSERTGROUP';
export const DELETEGROUP = 'DELETEGROUP';

export const addGroup = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSSERTGROUP,
      data: await axios.post(`/group-service/save`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const deleteGroup = (id, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: DELETEGROUP,
      data: await axios.post(`group-service/delete?id=${id}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};
