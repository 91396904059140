import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb, Button, Col, notification, Popover, Row, Space, Table, Tooltip } from 'antd';
import {
  EyeOutlined,
  HomeOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import formatMoney from '../../components/ConvertMoney';
import Filter from '../../constant/Filter';
import BookingSelect from '../../constant/selectBooking';
import CustomSreach from '../../constant/Sreach';
import axios from '../../services/Api';

import InsDiscount from './InsDiscount';
import ShowDiscountSV from './ShowDiscountSV';
import UpdDiscount from './UpdDiscount';

import moment from 'moment';

export default function Discount() {
  const supplierId = localStorage.getItem('supplierId');
  const [totalPage, setTotalPage] = useState('');
  const [showUpd, setShowUpd] = useState({ show: false, id: null });
  const [showIns, setShowIns] = useState({ show: false });
  const [showDetail, setShowDetail] = useState({ show: false, id: null, detail: {} });
  const [dataTable, setDataTable] = useState([]);
  const [query, setQuery] = useState('');
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [service, setService] = useState([]);
  const serviceList = service?.map((e, i) => ({
    key: e.id,
    value: e.id,
    label: e.name,
    price: e.price,
    description: e.description,
  }));

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  useEffect(() => {
    if (supplierId !== '') {
      getDataDiscountSupplier();
      getDataService();
    }
  }, [supplierId, page, size, showIns.show, serviceId, renderSreach]);

  useEffect(() => {
    if (supplierId !== '') {
      getDataService();
    }
  }, []);

  const getDataService = () => {
    axios.get(`/service/get/supplier?supplier=${supplierId}&page=0&size=1000`).then((response) => {
      setService(response?.data?.data);
    });
  };

  const onChangeSelect = (id, data) => {
    setServiceId(id);
  };

  const getDataDiscountSupplier = () => {
    // if (serviceId !== undefined) {
    //   axios
    //     .get(`voucher/get-service?serviceId=${serviceId}&page=${page}&size=${size}`)
    //     .then((response) => {
    //       setDataTable(response?.data?.data);
    //       setTotalPage(response?.data?.totalSize);
    //     });
    // } else {
    axios
      .get(`voucher/get-supplier?supplierId=${supplierId}&page=${page}&size=${size}`)
      .then((response) => {
        setDataTable(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
    // }
  };

  const onSearch = () => {
    if (query !== '') {
      // axios.get(`/service/search?supplierId=${supplierId}&query=${query}`).then(response => {
      //   setDataTable(response?.data?.data)
      // });
      notification.open({
        message: 'chức năng tìm kiếm',
      });
    } else {
      setRenderSreach(!renderSreach);
    }
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const onReloadSearch = () => {
    setQuery('');
    setRenderSreach(!renderSreach);
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      render: (t, r, i) => i + 1,
      width: '5%',
    },
    {
      title: 'Tên Voucher',
      dataIndex: 'title',
      key: 'title',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian áp dụng ',
      dataIndex: 'createDate',
      key: 'createDate',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
      render: (value) => (
        <Tooltip placement="topLeft" title={moment(value).format('DD-MM-YYYY kk:mm:ss')}>
          <span> {moment(value).format('DD-MM-YYYY kk:mm:ss')}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'endTime',
      key: 'endTime',
      ellipsis: {
        showTitle: false,
      },
      responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
      width: 100,
      render: (value) =>
        value !== null ? (
          <Tooltip placement="topLeft" title={moment(value).format('DD-MM-YYYY HH:mm:ss')}>
            <span> {moment(value).format('DD-MM-YYYY HH:mm:ss')}</span>
          </Tooltip>
        ) : (
          <span>0</span>
        ),
    },
    {
      title: 'Mức giảm ',
      dataIndex: 'coin',
      key: 'coin',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Số lượng ',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Dịch vụ áp dụng ',
      dataIndex: 'serviceName',
      key: 'serviceName  ',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Mô tả ',
      dataIndex: 'description',
      key: 'description',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: '10%',
      render: (record) => (
        <Space>
          <Popover content={<h4> Chi tiết Voucher</h4>}>
            <EyeOutlined
              style={{
                background: '#0693E3',
                borderRadius: 5,
                fontSize: '12px',
                color: 'white',
                padding: 5,
                border: '1px solid #0693E3',
              }}
              onClick={() => onShowDetail(record)}
            />
          </Popover>

          {/* <Popover content={<h4> Sửa Voucher</h4>}>
            <EditOutlined
              style={{
                background: "rgb(55, 181, 157)",
                borderRadius: 5,
                fontSize: "12px",
                color: "white",
                padding: 5,
                border: "1px solid rgb(55, 181, 157)",
              }}
            // onClick={() => onShowUpd(record)}
            />
          </Popover> */}

          {/* <Popconfirm
            title="Bạn có chắc chắn muốn xóa Voucher ?"
          // onConfirm={() => onDelete(record)}
          >
            <Popover content={<h4> Xóa Voucher</h4>}>
              <DeleteOutlined
                style={{
                  background: "rgb(240, 65, 52)",
                  borderRadius: 5,
                  fontSize: "12px",
                  color: "white",
                  padding: 5,
                  border: "1px solid rgb(240, 65, 52)",
                }}
              />
            </Popover>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  const onShowDetail = (record) => {
    setShowDetail({
      show: true,
      id: record?.id,
      detail: record,
    });
  };

  const onCannelShowDetail = () => {
    setShowDetail({
      show: false,
      id: null,
      detail: {},
    });
  };

  const onShowIns = async () => {
    setShowIns({
      show: true,
    });
  };

  const onCannelShowIns = async () => {
    setShowIns({
      show: false,
    });
  };

  const onShowUpd = () => {
    setShowUpd({
      show: true,
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  {' '}
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Danh sách Voucher</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24}>
            <Col span={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
            <Col span={12}>
              <Button
                style={{
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                }}
                type="primary"
                onClick={onShowIns}
              >
                <PlusOutlined />
                Thêm
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    <div className="selectCustomer">
                      <BookingSelect
                        styles={{ width: '80%', fontWeight: 'bold' }}
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        placeholder="Chọn dịch vụ"
                        options={serviceList}
                        onChange={onChangeSelect}
                      />
                    </div>
                  </Col>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach
                      styleInput={{ width: '80%' }}
                      placeholder="Tìm kiếm"
                      value={query}
                      onChange={onChangeQuery}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onSearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
            scroll={{ x: 'calc(450px + 50%)' }}
          />
        </Col>
      </Row>
      {showIns.show && <InsDiscount show={showIns.show} handleCancel={onCannelShowIns} />}
      {showUpd.show && <UpdDiscount show={showUpd.show} id={showUpd.id} handleCancel={onShowUpd} />}
      <ShowDiscountSV
        show={showDetail.show}
        id={showDetail.id}
        detailVoucher={showDetail.detail}
        handleCancel={onCannelShowDetail}
      />
    </>
  );
}
