import React from 'react';

import { Form, Modal, Select } from 'antd';

const UpdSFSchedule = (props) => {
  const { show, handleCancel } = props;
  const [form] = Form.useForm();

  const handleChange = (value) => {};
  const handleCancelUpd = () => {
    handleCancel();
    form.setFieldsValue({
      username: '',
      password: '',
      name: '',
      email: '',
      phone: '',
    });
  };

  const onUpd = (values) => {
    const body = {
      code: values?.code,
      name: values?.name,
      address: values?.address,
      phone: values?.phone,
    };
    handleCancelUpd();
  };

  return (
    <Modal
      title={`CHỈNH SỬA CA LÀM VIỆC`}
      visible={show}
      width="30%"
      onCancel={handleCancelUpd}
      okButtonProps={{
        form: 'insSFSchedule',
        key: 'submit',
        htmlType: 'submit',
      }}
      bodyStyle={{
        borderRadius: '10px',

        height: '10%',
      }}
    >
      <Form
        form={form}
        id="insSFSchedule"
        onFinish={onUpd}
        autoComplete="off"
        hideRequiredMark
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item
          label="Chọn ca làm việc"
          name="checkPassword"
          style={{ fontWeight: 'bold', marginRight: 50 }}
          rules={[{ required: true, message: 'Chọn ca làm việc' }]}
        >
          <Select
            placeholder="Chọn ca"
            style={{
              width: 150,
              marginLeft: 30,
            }}
            onChange={handleChange}
            options={[
              {
                value: '8h30',
                label: '8h30',
              },
              {
                value: '9h30',
                label: '9h30',
              },

              {
                value: '10h30',
                label: '10h30',
              },
              {
                value: '11h30',
                label: '11h30',
              },
              {
                value: '12h30',
                label: '12h30',
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Thời gian kết thúc làm việc"
          name="endTime"
          style={{ fontWeight: 'bold', marginRight: 50 }}
          rules={[{ required: true, message: 'Chọn thời gian kết thúc làm việc' }]}
        >
          <Select
            placeholder="Chọn thời gian kết thúc"
            style={{
              width: 150,
              marginLeft: 30,
            }}
            onChange={handleChange}
            options={[
              {
                value: '8h30',
                label: '8h30',
              },
              {
                value: '9h30',
                label: '9h30',
              },

              {
                value: '10h30',
                label: '10h30',
              },
              {
                value: '11h30',
                label: '11h30',
              },
              {
                value: '12h30',
                label: '12h30',
              },
            ]}
          />
        </Form.Item>

        {/* <Form.Item
          label="Số điện thoại"
          name="phone"
          style={{ fontWeight: "bold" }}
          rules={[{ required: true, message: "Nhập số điện thoại" }]}
        >
          <Input
            style={{ borderRadius: 5, border: "1px solid #37B59D" }}
            placeholder="Số điện thoại"
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          style={{ fontWeight: "bold" }}
          rules={[{ required: true, message: "Nhập Email" }]}
        >
          <Input
            style={{ borderRadius: 5, border: "1px solid #37B59D" }}
            placeholder="Email"
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};
export default UpdSFSchedule;
