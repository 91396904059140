import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, Row, Col, message, Typography } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import './style.css'
import { getTypeNotification, getTypeReceiveNoti, searchNCC, sendNotification } from '../../reducers/staffReducer';

const NotiList = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const sendType = Form.useWatch('sendType', form);
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [listTypeSend, setListTypeSend] = useState([]);
  const [listType, setListType] = useState([]);
  const [dataSearch, setDataSearch] = useState('');
  const [resultSearch, setResultSearch] = useState({});
  const dataUser = useSelector((store) => store?.authReducer.infoUser);
  
  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });

  const onSearch = () => 
  {
    if(dataSearch?.length < 10){
      message.error('Số điện thoại không đúng định dạng')
      return
    }
    dispatch(
      searchNCC({params:{query:dataSearch}}, {
        onSuccess: (res) => {
          if(!!res?.data?.data?.data && res?.data?.data?.data.length > 0){
              setResultSearch(res?.data?.data?.data[0])
              form.setFieldsValue({...form.getFieldsValue,supplierId: res?.data?.data?.data[0]?.id,titleCommunity:res?.data?.data?.data[0]?.name}) 
            }
          else{
            message.error('Không tìm thấy cộng đồng')
          }
        },
        onError: (res) => {
          message.error('Không tìm thấy cộng đồng')
        },
      })
    );
  };

  const getTypeReceive = async () => {
    dispatch(
      getTypeReceiveNoti({}, {
        onSuccess: (res) => {
          if(!!res?.data?.data?.data){
            setListTypeSend( Object.entries(res?.data?.data?.data))
          }
        
        },
        onError: (res) => {
        
        },
      })
    );
  }  
  
  const getTypeNoti = () => {
    dispatch(
      getTypeNotification({}, {
        onSuccess: (res) => {
          if(!!res?.data?.data?.data){
            setListType(res?.data?.data?.data)
          }
        },
        onError: (res) => {
        
        },
      })
    );
  }  

  const sendNotifi = (payload) => {
    dispatch(
      sendNotification(payload, {
        onSuccess: (res) => {
          if(res?.data?.data?.status?.statusCode == 200){
            if(!!res?.data?.data?.data){
              setListType( Object.entries(res?.data?.data?.data))
            }
            message.success('Gửi thành công')
          }
          else{
            message.error(res?.data?.data?.status?.message || 'Đã có lỗi xảy ra')
          }
        },
        onError: (res) => {
        
        },
      })
    );
  }  
  

  useEffect(() => {
    getTypeReceive()
    getTypeNoti()
  }, []);
  
  const onFinish = (values) => {
    sendNotifi({...values})
  };

  return (
    <>
       <div className="form-container">
      <h1 className="form-title">Gửi thông báo</h1>
      <Form
        form={form}
        name="notificationForm"
        layout="vertical"
        onFinish={onFinish}
        className="custom-form"
        autoComplete="off" 
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Đối tượng nhận thông báo"
              name="type"
              rules={[{ required: true, message: "Vui lòng chọn loại thông báo!" }]}
            >
              <Select style={{ width: '100%' }}>
                {
                  listType.map(item =>  <Option value={item}>{item}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Loại thông báo"
              name="sendType"
              rules={[{ required: true, message: "Vui lòng chọn hình thức!" }]}
            >
              <Select style={{ width: '100%' }}>
                {
                  listTypeSend.map(item =>  <Option value={item[0]}>{item[1]}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {
          sendType == 3 &&
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Số điện thoại cộng đồng"
                name="phoneSupplier"
              >
              <div style={{display:"flex"}}> 
                <Input placeholder="Số điện thoại cộng đồng"  maxLength={10} onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} 
                  onChange={(e) => {
                    setDataSearch(e.target.value)
                  }} 
                  />
              <Button 
              onClick={onSearch}
              style={{width:"50px",padding:'0 5px 0 10px'}} type="primary" icon={<SearchOutlined />} >
            
              </Button></div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tên cộng đồng"
                name="titleCommunity"
                rules={[{ required: true, message: "Vui lòng nhập số điện thoại để tìm cộng đồng!" }]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12} style={{display:'none'}}>
              <Form.Item
                label="Tên cộng đồng"
                name="supplierId"
                rules={[{ required: true, message: "Vui lòng nhập số điện thoại để tìm cộng đồng!" }]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        }

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Tiêu đề"
              name="title"
              rules={[{ required: true, message: "Hãy nhập tiêu đề!" }]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Nội dung"
              name="content"
              rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
            >
              <TextArea autoSize placeholder="Content (URL)" style={{minHeight:'40px'}} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
        <Col>
        <Form.Item>
          <Button color="default" variant="filled" htmlType="reset">
            Reset
          </Button>
        </Form.Item>
        </Col>
        <Col>
        <Form.Item>
          <Button type="primary" htmlType="submit" >
            Gửi thông báo
          </Button>
        </Form.Item>
        </Col>
        </Row>
       


      </Form>
    </div>
    </>
  );
};

export default NotiList;
