import React, { useCallback, useState } from 'react';

import { Button } from 'antd';

import Content from './Content';
import ContentCallBack from './ContentCallBack';

function DemoMemo() {
  // 1. memo() => higher order component (HOC)
  // 2. useCallBack
  // component nhận rất nhiều prop
  // tránh component con render ko cần thiết thì dùng memo,
  // component sử dụng memo thì mới dùng useCallback

  const [count, setCount] = useState(0);

  const increase = () => {
    setCount((prev) => prev + 1);
  };

  const increaseCallback = useCallback(() => {
    setCount((prev) => prev + 1);
  }, []);
  console.log('render-manchinh');

  return (
    <div style={{ padding: '10px 32px' }}>
      <h1>{count}</h1>
      <Content />
      <ContentCallBack onIncrease={increaseCallback} />

      <Button
        type="primary"
        style={{
          marginRight: '6px',
          backgroundColor: 'rgb(55, 181, 157)',
          borderRadius: 5,
          border: 'rgb(55, 181, 157)',
        }}
        onClick={increase}
      >
        click me!
      </Button>
    </div>
  );
}
export default DemoMemo;
