import React, { useState } from 'react';

import { Breadcrumb, Col, Row, Table } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import CustomSreach from '../../../constant/Sreach';

import './StaffSchedule.css';

const StaffSchedule = () => {
  const data = [
    // {
    //   key: "1",
    //   name: "John Brown",
    //   money: "￥300,000.00",
    //   address: "New York No. 1 Lake Park",
    // },
    // {
    //   key: "2",
    //   name: "Jim Green",
    //   money: "￥1,256,000.00",
    //   address: "London No. 1 Lake Park",
    // },
    // {
    //   key: "3",
    //   name: "Joe Black",
    //   money: "￥120,000.00",
    //   address: "Sidney No. 1 Lake Park",
    // },
  ];

  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      name: `Kiều Duy Lưu ${i}`,
      age: 32,
      timeSchedule: `Ca 9h-11h30`,
    });
  }

  const columns = [
    {
      title: 'Nhân viên',
      dataIndex: 'name',
      render: (text) => <a>{text}</a>,
      width: '30%',
    },

    {
      title: 'Ca làm việc',
      dataIndex: 'timeSchedule',
    },
  ];

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/">
              <b>Danh sách ca làm việc</b>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: '20px' }}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <div className="customer-search">
            <CustomSreach onClick={onsearch} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} />

        <Col span={24} style={{ paddingTop: '20px' }}>
          <Table columns={columns} dataSource={data} bordered size="small" />
        </Col>
      </Row>
    </>
  );
};

export default StaffSchedule;
