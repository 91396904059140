import React, { useMemo, useRef, useState } from 'react';

import { Button, Input } from 'antd';

//memo là 1 HOC, viết ôm 1 component, dùn để tránh component bị re-render trong tình huống ko cần thiết
// useMemo là 1 Hooks, viết trong 1 function component, tránh thực hiện 1 logic ko cần thiết

function DemoUseMemo() {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [products, setProducts] = useState([]);

  const nameRef = useRef();

  const handleSubmit = () => {
    setProducts([
      ...products,
      {
        name,
        price: +price, // chuyển chuỗi sang số dùng Number, parseInt, +
      },
    ]);
    setName('');
    setPrice('');
    nameRef.current.focus();
  };

  const total = useMemo(() => {
    const result = products.reduce((result, prod) => {
      console.log('result', result, prod);
      console.log('Tính toán lại...');
      return result + prod.price;
    }, 0);

    return result;
  }, [products]);

  return (
    <div style={{ padding: 20 }}>
      <Input
        ref={nameRef}
        value={name}
        placeholder="Enter name ..."
        onChange={(e) => setName(e.target.value)}
        style={{ marginRight: '6px', marginBottom: '6px', borderRadius: 5, width: '300px' }}
      />
      <br />
      <Input
        value={price}
        placeholder="Enter price ..."
        onChange={(e) => setPrice(e.target.value)}
        style={{ marginRight: '6px', marginBottom: '6px', borderRadius: 5, width: '300px' }}
      />
      <br />
      <Button
        type="primary"
        style={{
          marginRight: '6px',
          backgroundColor: 'rgb(55, 181, 157)',
          borderRadius: 5,
          border: 'rgb(55, 181, 157)',
          marginBottom: '6px',
        }}
        onClick={handleSubmit}
      >
        Add
      </Button>

      <div>
        <b> Total: {total}</b>
        <ul>
          {products.map((product, index) => (
            <li key={index}>
              {product.name} - {product.price}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DemoUseMemo;
