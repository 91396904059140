import React, { useReducer } from 'react';

const initState = 0;

const UP_ACTION = 'UP_ACTION';
const DOWN_ACTION = 'DOWN_ACTION';

//Reducer
const reducer = (state, action) => {
  console.log('running...');
  switch (action) {
    case UP_ACTION:
      return state + 1;
    case DOWN_ACTION:
      return state - 1;
    default:
      throw new Error('action');
  }
};

function DemoUseReducer() {
  const [count, dispatch] = useReducer(reducer, initState);
  return (
    <div>
      <h1>{count}</h1>
      <button onClick={() => dispatch(DOWN_ACTION)}>Down</button>
      <button onClick={() => dispatch(UP_ACTION)}>UP</button>
    </div>
  );
}
export default DemoUseReducer;
