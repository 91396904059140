import axios from '../services/Api';

export const INSSERTDISCOUNT = 'INSSERTDISCOUNT';
export const DELETEDISCOUNT = 'DELETEDISCOUNT';

export const addDiscount = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSSERTDISCOUNT,
      data: await axios.post(`/voucher/insert`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

// export const deleteBLog = (id, meta) => async (dispatch) => {
//     try {
//         const result = await dispatch({
//             type: DELETEBLOG,
//             data: await axios.post(`/blog/delete?id=${id}`)
//         });
//         meta.onSuccess(result);
//     } catch (err) {
//         meta.onError(err);
//     }
// }
