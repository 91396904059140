import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';

// import SockJsClient from 'react-stomp';
import { Button, Col, Input, Menu, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import logo from '../../../assets/logovuong.png';
import axios from '../../../services/Api';

import './ConversationList.css';

import moment from 'moment';
import shave from 'shave';

export default function ConversationList(props) {
  const dispatch = useDispatch();

  const supplierId = localStorage.getItem('supplierId');
  const [conversations, setConversations] = useState([]);
  const [totalData, setTotalData] = useState();
  const [sizeScroll, setSizeScroll] = useState(15);
  const [size, setSize] = useState(15);
  const [pageScroll, setPageScroll] = useState(1);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // console.log("conversations", conversations)
  // console.log("length", conversations?.length)
  // console.log("totalData", totalData)

  useEffect(() => {
    getConversation();
  }, []);

  useEffect(() => {
    shave('.conversation-snippet', 40);
  }, []);

  const getConversation = async () => {
    axios
      .get(`/conversation/get?supplierId=${supplierId}&page=${page}&size=${size}`)
      .then((response) => {
        setTotalData(response.data.totalSize);
        const data = response.data.data;
        setConversations(data);
        setHasMore(true);
      });
  };

  const handleInfinite = async () => {
    if (conversations.length < totalData) {
      axios
        .get(`/conversation/get?supplierId=${supplierId}&page=${pageScroll}&size=${sizeScroll}`)
        .then((response) => {
          const data = response.data.data;
          setConversations(conversations.concat(data));
          setHasMore(true);
          setPageScroll((prev) => prev + 1);
        });
    } else {
      setHasMore(false);
    }
  };

  const onViewFalse = (id, conversation) => {
    props.onsubmit(id, conversation);
  };

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={handleInfinite}
      hasMore={hasMore}
      useWindow={false}
    >
      <div className="conversation-list">
        <div className="toolbar">
          <Row
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            span={24}
            style={{ top: '0px', position: 'sticky' }}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
              <h1 className="toolbar-h1">Tin nhắn</h1>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
              <div style={{ textAlign: 'center', marginBottom: 20 }}>
                <Input
                  style={{ width: '94%' }}
                  placeholder="Tìm kiếm"
                  // onChange={(e) => handleSeacch(e)}
                  // onKeyPress={handleKeypress}
                  // value={textSearch}
                  prefix={<SearchOutlined />}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="menuConversation">
          <Menu mode="inline">
            {conversations?.map((conversation) => (
              <Menu.Item
                key={conversation.id}
                style={{ height: '80px', padding: 10 }}
                onClick={() => onViewFalse(conversation?.id, conversation)}
              >
                <Row span={24} style={{ marginTop: 12 }}>
                  <Col xs={24} sm={24} md={7} lg={7} xl={6} xxl={5}>
                    <img className="conversation-photo" src={logo} alt="conversation" />
                  </Col>
                  <Col xs={0} sm={0} md={17} lg={17} xl={18} xxl={19}>
                    <h4
                      className={
                        conversation.unread == 1
                          ? 'conversation-title-unread'
                          : 'conversation-title'
                      }
                    >
                      {conversation?.name}
                      <span className="chat-time">
                        {moment(conversation?.createTime).format('DD-MM-YYYY')}
                      </span>
                    </h4>
                    <div
                      className={conversation.unread == 1 ? 'fix-line-css-unread' : 'fix-line-css'}
                    >
                      {conversation?.lastMessage}
                    </div>
                  </Col>
                </Row>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
    </InfiniteScroll>
  );
}
