import React, { memo } from 'react';

import { Button, Col, Row, Tabs } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  FileDoneOutlined,
  SwapRightOutlined,
} from '@ant-design/icons';

const { TabPane } = Tabs;

const TabStatus = ({
  onChangeStatusDC,
  onChangeStatusHuy,
  onChangeStatusDPV,
  onChangeStatus,
  onInsBill,
  totalStatus,
}) => {
  const onChangeTab = (key) => {
    const tabKey = {
      1: 'Tất cả',
      2: 'Đang chờ',
      3: 'Đang phục vụ',
      4: 'Đã phục vụ',
      5: 'Đã hủy',
    };
    onChangeStatus(tabKey[key]);
  };

  const onChangeStatusDCButton = (id) => {
    onChangeStatusDC(id);
  };

  const onChangeStatusHuyButton = (id) => {
    onChangeStatusHuy(id);
  };

  const onChangeStatusDPVButton = (id) => {
    onChangeStatusDPV(id);
  };

  const onInsBillTab = () => {
    onInsBill();
  };
  // console.log("re-render-Tab")

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTab} size="small">
        {/* <TabPane tab={`Tất cả (${totalStatus?.total || 0})`} key="1" /> */}

        <TabPane tab={`Đang chờ (${totalStatus?.waiting || 0})`} type key="2">
          <Button
            style={{
              fontWeight: 'bold',
              backgroundColor: '#00d084',
              border: '#00d084',
            }}
            type="primary"
            onClick={() => onChangeStatusDCButton()}
          >
            <SwapRightOutlined />
            Đang phục vụ
          </Button>
          <Button
            style={{
              fontWeight: 'bold',
              marginLeft: '10px',
              backgroundColor: '#f47373',
              border: '#f47373',
            }}
            type="primary"
            onClick={() => onChangeStatusHuyButton()}
          >
            <CloseOutlined />
            Hủy
          </Button>
        </TabPane>

        <TabPane tab={`Đang phục vụ (${totalStatus?.service || 0})`} key="3">
          {/* <Button
            style={{
              fontWeight: 'bold',
              backgroundColor: '#00d084',
              border: '#00d084',
            }}
            type="primary"
            onClick={() => onChangeStatusDCButton()}
          >
            <SwapRightOutlined />
            Đang phục vụ
          </Button> */}
          <Button
            style={{
              fontWeight: 'bold',
              marginLeft: '10px',
              backgroundColor: '#607D8B',
              border: '#607D8B',
            }}
            type="primary"
            onClick={() => onChangeStatusDPVButton()}
          >
            <CheckOutlined />
            Đã phục vụ
          </Button>
          {/* <Button
            style={{
              fontWeight: 'bold',
              marginLeft: '10px',
              backgroundColor: '#f47373',
              border: '#f47373',
            }}
            type="primary"
            onClick={() => onChangeStatusHuyButton()}
          >
            <CloseOutlined />
            Hủy
          </Button> */}
        </TabPane>

        <TabPane tab={`Đã phục vụ (${totalStatus?.served || 0})`} key="4">
          <Button
            style={{
              fontWeight: 'bold',
              backgroundColor: '#0693E3',
              border: '#0693E3',
            }}
            type="primary"
            onClick={() => onInsBillTab()}
          >
            <FileDoneOutlined />
            Xuất hóa đơn
          </Button>
        </TabPane>

        <TabPane tab={`Đã hủy (${totalStatus?.cancelled || 0})`} key="5" />
      </Tabs>
    </>
  );
};

export default memo(TabStatus);
