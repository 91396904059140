import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Image, Input, Modal, notification, Upload } from 'antd';

import { addGroup } from '../../reducers/grService';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../constant/data';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import { handleBeforeUpload } from '../../utils';

export default function InsGroup(props) {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { show, handleCancel } = props;
  const [form] = Form.useForm();
  const [image, setImage] = useState('');

  const onIns = (values) => {
    const body = {
      name: values?.name,
      status: true,
      description: values?.description,
      image:image
    };
    dispatch(
      addGroup(body, {
        onSuccess: (res) => {
          if(res?.data?.data?.status?.statusCode != 200){
            notification.error({
              message: res?.data?.data?.status?.message,
            });
          }else notification.success({
            message: `Thêm nhóm dịch vụ thành công`,
          });
          handleCancelIns();
        },
        onError: (message) => {
          notification.error({
            message: `Thêm nhóm dịch vụ thất bại`,
          });
        },
      })
    );
  };

  const handleCancelIns = () => {
    handleCancel();
    form.setFieldsValue({
      name: '',
      description: '',
    });
  };

  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });



  const handleChangeLogo = (info) => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    if (status === 'done') {
      const urlList = fileList.map((file) => {
        return file.response?.data;
      });
      setImage(urlList?.length ? urlList[urlList?.length - 1] : null);
    } else if (status === 'error') {
    }
  };


  return (
    <Modal
      title={`THÊM MỚI NHÓM DỊCH VỤ`}
      open={show}
      onCancel={handleCancel}
      okButtonProps={{
        form: 'insGroupService',
        key: 'submit',
        htmlType: 'submit',
      }}
    >
      <Form
        form={form}
        id="insGroupService"
        onFinish={onIns}
        autoComplete="off"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item label="Logo" style={{ fontWeight: '500' }}>
          <ImgCrop rotate>
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
              headers={JWT_HEADER}
              method="POST"
              onChange={handleChangeLogo}
              beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
            >
              {image ? (
                <Image
                  className="imgLogo"
                  src={`${REACT_APP_API_KEY_IMAGE}${image}`}
                  alt="avatar"
                  preview={false}
                />
              ) : (
                <div style={{ fontWeight: 'bold' }}>
                  <PlusOutlined />
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Tên nhóm dịch vụ "
          name="name"
          rules={[{ required: true, message: 'Nhập tên nhóm  dịch vụ' }]}
        >
          <TextArea autoSize placeholder="Tên nhóm  dịch vụ" />
        </Form.Item>
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Mô tả  "
          name="description"
          rules={[{ required: true, message: 'Nhập mô tả' }]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="Mô tả" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
