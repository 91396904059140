import React from 'react';
import { useDispatch } from 'react-redux';

import { Form, Input, message, Modal, notification } from 'antd';

import { changPassLogin } from '../../reducers/authReducer';

export default function ChangePassUserLogin(props) {
  const dispatch = useDispatch();
  const { show, handleCancel } = props;
  const [form] = Form.useForm();

  const onChangpass = async (values) => {
    if (values?.newPassword !== values?.checkPassword) {
      message.error('Xác nhận mật khẩu không đúng');
    } else {
      const body = {
        currentPassword: values?.currentPassword,
        newPassword: values?.newPassword,
      };
      dispatch(
        changPassLogin(body, {
          onSuccess: () => {
            notification.success({
              message: `Đổi mật khẩu thành công`,
            });
            onClose();
          },
          onError: () =>
            notification.error({
              message: `Đổi mật khẩu thất bại - mật khẩu cũ không đúng`,
            }),
        })
      );
    }
  };

  const onClose = () => {
    handleCancel();
    form.setFieldsValue({
      currentPassword: '',
      newPassword: '',
      checkPassword: '',
    });
  };
  return (
    <Modal
      title={`Đổi mật khẩu`}
      open={show}
      okButtonProps={{ form: 'insPassUser-form', key: 'submit', htmlType: 'submit' }}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        id="insPassUser-form"
        name="nest-messages"
        onFinish={onChangpass}
        autoComplete="off"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Mật khẩu cũ"
          name="currentPassword"
          rules={[{ required: true, message: 'Nhập mật khẩu cũ' }]}
        >
          <Input.Password placeholder="Nhập mật khẩu mới" />
        </Form.Item>
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Mật khẩu mới"
          name="newPassword"
          rules={[{ required: true, message: 'Nhập mật khẩu mới' }]}
        >
          <Input.Password placeholder="Nhập mật khẩu mới" />
        </Form.Item>
        <Form.Item
          label="Xác nhận mật khẩu"
          style={{ fontWeight: '500' }}
          name="checkPassword"
          rules={[{ required: true, message: 'Nhập lại mật khẩu mới' }]}
        >
          <Input.Password placeholder="Xác nhận mật khẩu mới" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
