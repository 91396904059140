import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
  message
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import logo from '../../../assets/logochuan.png';
import signinbg from "../../../assets/mo-hinh-kinh-doanh.jpg";
import { login } from '../../../reducers/authReducer';
import "./main.css";

import {
  DribbbleOutlined,
  GithubOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

// import './Login.css';
const Login = () => {
const history = useHistory();
const dispatch = useDispatch();
const [form] = Form.useForm();
const token = useSelector((store) => store.authReducer.token);
const checkSupplier = useSelector((store) => store.authReducer.checkSupplier);



const onLogin = ({ username, password }) => {
  const body = {
    username,
    password,
  };
  dispatch(
    login(body, {
      onSuccess: (res) => {
        console.log(res)
        history.push('/');
      },
      onError: () => {
        message.error('Sai tài khoản hoặc mật khẩu.');
      },
    })
  );
};

  return token ? (
    checkSupplier === true ? (
      <Redirect to="/" />
    ) : (
      <Redirect to="/createSupplier" />
    )
  ) : (
  <Layout className="layout-default layout-signin">
    <Content className="signin">
      <Row gutter={[24, 0]} justify="space-around">
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 6, offset: 2 }}
          md={{ span: 12 }}
        >
      
            <div>
              <img width="60%" src={logo} alt="logo" />
            </div>

          <Title className="mb-15">Đăng nhập</Title>
          <Title className="font-regular text-muted" level={5}>
          Đăng nhập vào Cộng đồng
          </Title>
          <Form
            form={form}
            id="insUser-form"
            name="normal_Resgiter"
            onFinish={onLogin}
            autoComplete="off"
          >
            <Form.Item
              style={{ fontWeight: "bold" }}
              name="username"
              rules={[{ required: true, message: "Nhập tên đăng nhập" }]}
            >
              <Input
                style={{
        
                  borderRadius: 6,
                  fontSize: "1.1em",
                }}
                placeholder="Tên đăng nhập"
              />
            </Form.Item>

            <Form.Item
              style={{ fontWeight: "bold" }}
              name="password"
              rules={[{ required: true, message: "Nhập mật khẩu" }]}
            >
              <Input.Password
                style={{
      
                  borderRadius: 6,
                  fontSize: "1.1em",
                }}
                placeholder="Mật khẩu"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: 6,
                  backgroundColor: "#1877f2",
                  border: "none",
                  fontWeight: "bold",
                  fontSize: "1.2em",
                }}
              >
                Đăng nhập
              </Button>
            </Form.Item>
            <hr />
            <Link to="/register">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '100%',
                      height: '50px',
                      borderRadius: 6,
                      backgroundColor: '#37D67A',
                      border: 'none',
                      fontWeight: 'bold',
                      fontSize: '1.2em',
                    }}
                  >
                    Tạo tài khoản
                  </Button>
                </Link>
          </Form>
        </Col>
        <Col
          className="sign-img"
          style={{ padding: 12 }}
          xs={{ span: 24 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
        >
          <img src={signinbg} alt="" />
        </Col>
      </Row>
    </Content>
    <Footer>
      <Menu mode="horizontal">

      </Menu>
      
      <p className="copyright">
        {" "}
        Copyright © 2024 by<a href="#pablo">VVECO</a>.{" "}
      </p>
    </Footer>
  </Layout>
  );
};

export default Login;