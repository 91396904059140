import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Table,
  Tooltip,
} from 'antd';

import formatMoney from '../../../components/ConvertMoney';
import BookingSelect from '../../../constant/selectBooking';
import { addBooking } from '../../../reducers/bookingReducer';
import axios from '../../../services/Api';

import moment from 'moment';
function InsCalendarBooking(props) {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { show, start, end, resourceId, handleCancel } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const supplierId = localStorage.getItem('supplierId');

  const [userId, setUserId] = useState(0);
  const [totalmoney, setTotalmoney] = useState([0]);
  const [money, setMoney] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [service, setService] = useState([]);
  const serviceList = service?.map((e, i) => ({
    key: e.id,
    value: e.id,
    label: e.name,
    price: e.price,
    description: e.description,
  }));
  const [bookingDetails, setBookingDetails] = useState([]);

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  const columns = [
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Giá dịch vụ',
      dataIndex: 'price',
      key: 'price',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '22%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    if (supplierId !== '') {
      getDataService();
    }
  }, []);

  const getDataService = () => {
    axios.get(`/service/get/supplier?supplier=${supplierId}&page=0&size=1000`).then((response) => {
      setService(response?.data?.data);
    });
  };

  const handleCancelIns = () => {
    handleCancel();
    form.setFieldsValue({
      customerName: '',
      phone: '',
      mail: '',
      innerNote: '',
    });
    setService([]);
    setBookingDetails([]);
  };

  useEffect(() => {
    const money = sumArray(totalmoney);
    setMoney(money);
  }, [totalmoney]);

  function sumArray(totalmoney) {
    let sum = 0;
    totalmoney.map(function (value) {
      sum += value;
    });
    return sum;
  }

  const onChangeSelect = (id, data) => {
    const arrData = data?.map((e, i) => ({
      id: e.value,
      name: e.name,
      price: e.price,
      description: e.description,
      intendTime: moment(start).format('DD-MM-YYYY HH:mm:ss'),
      employeeId: resourceId,
      serviceUserId: e.value,
    }));
    setDataTable(arrData);
    const arrDataDetails = data?.map((e, i) => ({
      quantity: 1,
      price: e.price,
      intendTime: moment(start).format('DD-MM-YYYY HH:mm:ss'),
      employeeId: resourceId,
      serviceUserId: e.value,
    }));
    setTotalmoney(data?.map((e) => e?.price));
    setBookingDetails(arrDataDetails);
  };

  const onIns = (value) => {
    const body = {
      id: 0,
      totalMoney: money,
      paidMoney: 0,
      discountPercent: 0,
      discountMoney: 0,
      customerName: value?.customerName,
      phone: value?.phone,
      mail: value?.mail,
      innerNote: value?.innerNote !== null ? value?.innerNote : '',
      supplierId: supplierId,
      bookingDetails: bookingDetails,
    };
    console.log('body', body);
    dispatch(
      addBooking(userId, body, {
        onSuccess: () => {
          notification.success({
            message: `Thêm booking thành công`,
          });
          handleCancelIns();
        },
        onError: () => {
          notification.error({
            message: `Thêm booking thất bại`,
          });
        },
      })
    );
  };

  return (
    <Modal
      title={`Thêm booking`}
      open={show}
      onCancel={handleCancel}
      width="80%"
      okButtonProps={{ form: 'insBooking', key: 'submit', htmlType: 'submit' }}
    >
      <Form
        id="insBooking"
        form={form}
        onFinish={onIns}
        labelCol={{ xs: 6, sm: 6, md: 6, lg: 8, xl: 6, xxl: 6 }}
        wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}
      >
        <Row span={24} style={{ borderTop: '1px solid #d9d9d9' }}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={10}
            xl={10}
            xxl={9}
            style={{
              borderRight: '1px solid #d9d9d9',
              paddingTop: '10px',
              paddingRight: '10px',
            }}
          >
            <Form.Item>
              <h1 style={{ fontWeight: 'bold' }}>Thông tin Thành viên</h1>
            </Form.Item>
            <Row span={24}>
              <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
                {/* <Form.Item label="Thành viên" style={{ fontWeight: "bold" }} >
                  <div className='selectCustomer' >
                    <BookingSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Chọn Thành viên"
                    // value={fieldState.province}
                    // options={provinceList}
                    // onChange={onChangeSelect('province')}
                    />
                  </div>
                </Form.Item> */}

                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Họ tên"
                  name="customerName"
                  rules={[{ required: true, message: 'Nhập tên Thành viên' }]}
                >
                  <Input placeholder="Tên Thành viên" />
                </Form.Item>

                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Điện thoại"
                  name="phone"
                  rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                >
                  <Input placeholder="Số điện thoại"  maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} />
                </Form.Item>

                <Form.Item style={{ fontWeight: '500' }} label="Email" name="mail">
                  <Input placeholder="Nhập Email" />
                </Form.Item>

                <Form.Item style={{ fontWeight: '500' }} label="Ghi chú" name="innerNote">
                  <TextArea autoSize placeholder="Ghi chú" />
                </Form.Item>
              </Col>

              <Col span={24} style={{ paddingTop: '10px' }}>
                <Form.Item style={{ fontWeight: '500' }} label="Tổng tiền">
                  <Input
                    value={FormatMoney(money) + 'VND'}
                    disabled
                    style={{ fontWeight: 'bold', color: '#0693E3' }}
                    placeholder="Tổng tiền"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={14}
            xl={14}
            xxl={15}
            style={{ paddingLeft: '10px', paddingTop: '10px' }}
          >
            <Form.Item>
              <h1 style={{ fontWeight: 'bold' }}>Thông tin dịch vụ</h1>
            </Form.Item>

            <Form.Item style={{ fontWeight: 'bold' }}>
              <div className="selectCustomer">
                <BookingSelect
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  placeholder="Chọn dịch vụ"
                  options={serviceList}
                  onChange={onChangeSelect}
                />
              </div>
            </Form.Item>

            <Form.Item>
              <Table columns={columns} dataSource={dataTable} size="small" pagination={false} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default memo(InsCalendarBooking);
