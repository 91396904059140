import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Avatar, Col, Layout, Modal, Row } from 'antd';
import { AlignCenterOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import UserDropdown from '../../components/UserDropdown';
import { reset } from '../../reducers/authReducer';
import BadgeNote from '../../screens/NotiWeb/BadgeNote';
import ChangePassUserLogin from '../User/ChangePassUserLogin';

import './DefaultLayout.css';
const { Header } = Layout;

export default function DefaultHeader({ toggle, collapsed }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showchangePass, setShowchangePass] = useState({ show: false });

  const [check, setCheck] = useState(true);
  const [resize, setResize] = useState();
  // console.log("resize", resize)
  const onCancelShow = () => {
    setShowchangePass({ show: false });
  };

  const logOut = () => {
    Modal.confirm({
      title: 'Bạn chắc chắn đăng xuất ?',
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        localStorage.clear();
        dispatch(reset());
        history.replace('/login');
      },
      onCancel() {},
    });
  };

  const onChangeProfile = () => {
    history.replace('/infoUser');
  };

  const onChangePassword = () => {
    setShowchangePass({ show: true });
  };

  useEffect(() => {
    // console.log("màn hinh", window.innerWidth)
    setResize(window.innerWidth);
    if (window.innerWidth < 1600) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [resize !== window.innerWidth]);

  const onShowMini = () => {
    // console.log("123")
  };

  return (
    <Header className="site-layout-background" style={{ padding: 0, backgroundColor: '#D9E3F0' }}>
      <Row xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} span={6}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger custom-ic',
            onClick: toggle,
          })}
        </Col>

        <Col span={18}>
          <Row span={24}>
            <Col xs={11} sm={13} md={16} lg={17} xl={18} xxl={21} />
            <Col xs={8} sm={7} md={5} lg={4} xl={3} xxl={1}>
              <div
                className="hoverNoti"
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  width: '25%',
                  marginLeft: '60%',
                  borderRadius: '25px',
                }}
              >
                <BadgeNote />
              </div>
            </Col>
            <Col xs={5} sm={4} md={3} lg={3} xl={3} xxl={2}>
              <div
                className="hoverUser"
                style={{
                  borderRadius: '25px',
                  textAlign: 'center',
                  marginLeft: '20%',
                }}
              >
                <UserDropdown
                  onClick={onChangeProfile}
                  logOut={logOut}
                  onChangePassword={onChangePassword}
                >
                  <Avatar src={'https://cdn-icons-png.flaticon.com/512/4944/4944197.png'} />
                </UserDropdown>
              </div>
            </Col>
          </Row>
        </Col>

        {/* <Col span={18}>
          {check ?
            <div className='showAll'>
              <Row span={24}>
                <Col span={20} ></Col>
                <Col span={2}>
                  <div className="hoverNoti" style={{ cursor: 'pointer', textAlign: "center", width: '25%', marginLeft: '60%', borderRadius: '25px' }}>
                    <BadgeNote />
                  </div>
                </Col>
                <Col span={2} >
                  <div className="hoverUser" style={{ borderRadius: '25px', textAlign: "center", marginLeft: '20%' }}>
                    <UserDropdown onClick={onChangeProfile} logOut={logOut} onChangePassword={onChangePassword}>
                      <Avatar
                        src={'https://cdn-icons-png.flaticon.com/512/4944/4944197.png'}
                      />
                    </UserDropdown>
                  </div>
                </Col>
              </Row>
            </div>
            :
            <div className='ShowMini'>
              <Row span={24}>
                <Col span={20} ></Col>
                <Col span={2} >
                  {React.createElement(AlignCenterOutlined, {
                    className: "trigger custom-ic",
                    onClick: onShowMini,
                  })}
                </Col>
                <Col span={2} >
                  <div className="hoverUser" style={{ borderRadius: '25px', textAlign: "center" }}>
                    <UserDropdown onClick={onChangeProfile} logOut={logOut} onChangePassword={onChangePassword}>
                      <Avatar
                        src={'https://www.w3schools.com/howto/img_avatar.png'}
                      />
                    </UserDropdown>
                  </div>
                </Col>
              </Row>
            </div>
          }
        </Col> */}
      </Row>

      <ChangePassUserLogin show={showchangePass.show} handleCancel={onCancelShow} />
    </Header>
  );
}
