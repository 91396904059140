import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Switch, Typography, notification } from 'antd';

import BookingSelect from '../../constant/selectBooking';
import { addDiscount } from '../../reducers/discountReducer';
import axios from '../../services/Api';

import moment from 'moment';

export default function InsDiscount(props) {
  const { TextArea } = Input;
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Title, Paragraph, Text, Link } = Typography;
  const { show, handleCancel, serviceName } = props;
  const supplierId = localStorage.getItem('supplierId');
  const [endTime, setEndTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [serviceId, setServiceId] = useState();
  const [tCoin, setTCoin] = useState();
  const [service, setService] = useState([]);
  const serviceList = service?.map((e, i) => ({
    key: e.id,
    value: e.id,
    label: e.name,
    price: e.price,
    description: e.description,
  }));

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  useEffect(() => {
    if (supplierId !== '') {
      getDataService();
    }
  }, []);

  const getDataService = () => {
    axios.get(`/service/get/supplier?supplier=${supplierId}&page=0&size=1000`).then((response) => {
      setService(response?.data?.data);
    });
  };

  const onIns = (values) => {
    if (endTime !== undefined && endTime !== '' && startTime !== undefined && startTime !== '') {
      const body = {
        title: values?.title,
        description: values?.description,
        endTime: endTime,
        startDate: startTime,
        coin: +values?.discountMoney,
        typeCoin: 1,
        serviceId: serviceId || 0,
        supplierId,
        quantity: +values?.quantity,
        isPublic: values?.isPublic,
      };
      dispatch(
        addDiscount(body, {
          onSuccess: (res) => {
            if(res?.data?.status?.statusCode == 200){
              notification.success({
                message: `Thêm Voucher thành công`,
              });
              handleCancelIns();
            }
            else{
              notification.error({
                message: res?.data?.data?.status?.message || `Thêm Voucher không thành công thành công`,
              });
              handleCancelIns();
            }
          },
          onError: (error) => {
            notification.error({
              message: error?.data?.status?.message || `Thêm Voucher thất bại`,
            });
          },
        })
      );
    } else {
      notification.error({
        message: `Chọn thời gian kết thúc`,
      });
    }
  };

  const handleCancelIns = () => {
    handleCancel();
    form.setFieldsValue({
      title: '',
      description: '',
      discountMoney: '',
      quantity: '',
    });
    setEndTime('');
    setServiceId();
    setService([]);
  };

  const onChangeTime = (date, dateString) => {
    setEndTime(dateString);
  };

  const onChangeTimeStart = (date, dateString) => {
    setStartTime(dateString);
  };

  const time = new Date();
  const hours = moment(time).format('HH');
  const minute = moment(time).format('mm');

  const disabledTime = (current) => {
    if (current < new Date()) {
      const hoursCurrent = moment(current).format('HH');
      const minuteCurrent = moment(current).format('mm');
      if (hoursCurrent === hours) {
        if (minuteCurrent === minute) {
          return {
            disabledHours: () => range(0, hours),
            disabledMinutes: () => range(0, +minute + 10),
          };
        }
      }
    }
  };

  const disabledDate = (current) => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const date = new Date(today);
    return current && current < date;
  };

  const onChangeSelect = (id, data) => {
    setServiceId(id);
  };

  const getVCoin = () => {
    axios.get(`supplier/get-coin?supplierId=${supplierId}`).then((response) => {
      setTCoin(response?.data?.data?.vcoin);
    });
  };

  useEffect(() => {
    getVCoin()
  }, []);

  return (
    <Modal
      title={`Thêm mới Voucher`}
      open={show}
      onCancel={handleCancel}
      okButtonProps={{
        form: 'insDiscountSV',
        key: 'submit',
        htmlType: 'submit',
      }}
    >
      <Form
        form={form}
        id="insDiscountSV"
        onFinish={onIns}
        autoComplete="off"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Tên Voucher"
          style={{ fontWeight: '500' }}
          name="title"
          rules={[{ required: true, message: 'Nhập tên Voucher' }]}
        >
          <TextArea autoSize placeholder="Tên Voucher" />
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Thời gian bắt đầu "
              style={{ fontWeight: '500' }}
              rules={[{ required: true, message: 'Nhập thời gian bắt đầu' }]}
            >
              <DatePicker
                showTime={{
                  format: 'HH:mm:ss',
                }}
                format="DD-MM-YYYY HH:mm:ss"
                placeholder="Chọn thời gian"
                onChange={onChangeTimeStart}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Thời gian kết thúc "
              style={{ fontWeight: '500' }}
              rules={[{ required: true, message: 'Nhập thời gian kết thúc' }]}
            >
              <DatePicker
                showTime={{
                  format: 'HH:mm:ss',
                }}
                format="DD-MM-YYYY HH:mm:ss"
                placeholder="Chọn thời gian"
                onChange={onChangeTime}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ paddingRight: '5px' }}>
            <Form.Item style={{ fontWeight: '500' }} label="Chọn dịch vụ">
              <div className="selectCustomer">
                <BookingSelect
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  placeholder="Chọn dịch vụ"
                  options={serviceList}
                  onChange={onChangeSelect}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Mức giảm "
              style={{ fontWeight: '500' }}
              name="discountMoney"
              rules={[{ required: true, message: 'Nhập mức giảm' }]}
            >
               <InputNumber
                placeholder="Mức giảm"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                style={{width:'100%'}}
              />
              {/* <Input placeholder="Mức giảm" /> */}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ paddingRight: '5px' }}>
            <Form.Item
              label="Số lượng"
              style={{ fontWeight: '500' }}
              name="quantity"
              rules={[{ required: true, message: 'Nhập số lượng ' }]}
            >
              <InputNumber
                placeholder="Số lượng"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                style={{width:'100%'}}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ paddingRight: '5px' }}>
            <Form.Item
              label="Public"
              style={{ fontWeight: '500' }}
              name="isPublic"
              rules={[{ required: true, message: 'Nhập số lượng ' }]}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="TCoin hiện có"
          rules={[{ message: '' }]}
          style={{ fontWeight: '500' }}
        >
          <Input
            value={`${tCoin?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || ''}đ`}
            disabled
            placeholder=""
          />
        </Form.Item>
        <Form.Item
          label="Mô tả"
          style={{ fontWeight: '500' }}
          name="description"
          rules={[{ required: true, message: 'Nhập mô tả' }]}
        >
          <TextArea autoSize={{ minRows: 2, maxRows: 6 }} placeholder="Mô tả" />
        </Form.Item>
        <Paragraph>
          <pre>Lưu ý khi tạo voucher: TCOIN là điểm do VBNOW cung cấp để cộng đồng dùng để tạo voucher. Cộng đồng chỉ có thể tạo ra voucher dựa trên số lượng TCOIN hiện có. Cộng đồng có thể liên hệ VBNOW để cấp thêm điểm thưởng tạo voucher.</pre>
        </Paragraph>

      </Form>
    </Modal>
  );
}
