import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Breadcrumb, Col, notification, Popconfirm, Row } from 'antd';
import { HomeOutlined, TableOutlined } from '@ant-design/icons';

import { updTimeBooking } from '../../../reducers/bookingReducer';
import axios from '../../../services/Api';

import InsCalendarBooking from './InsCalendarBooking';
import UpdTimeCalendar from './UpdTimeCalendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

import moment from 'moment';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

function CalendarBooking() {
  const dispatch = useDispatch();

  const supplierId = localStorage.getItem('supplierId');
  const [myEvents, setMyEvents] = useState([]);
  const [infoEmployee, setInfoEmployee] = useState([]);
  const [showIns, setShowIns] = useState({
    show: false,
    start: null,
    end: null,
    resourceId: null,
  });
  const [showUpdTime, setShowUpdTime] = useState({
    show: false,
    data: null,
    checkStartTime: true,
  });
  const [checkCallApi, setCheckCallApi] = useState(true);

  const today = new Date();
  const todayDate = new Date().toLocaleDateString('sv-SE');
  today.setDate(today.getDate() - 0);
  const date = new Date(today).toLocaleDateString('sv-SE');
  const startTime = moment(date).format('DD-MM-YYYY') + ' ' + '00:00:00';
  const endTime = moment(todayDate).format('DD-MM-YYYY') + ' ' + '23:59:59';

  useEffect(() => {
    getDataCalendar();
    getDataStaff();
    setCheckCallApi(true);
  }, [checkCallApi === false, showUpdTime.show, showIns.show]);

  const getDataCalendar = () => {
    axios
      .get(
        `/booking/calendar?supplierId=${supplierId}&startTime=${startTime}&endTime=${endTime}&page=0&size=100`
      )
      .then((response) => {
        let newData = response?.data?.data.map((data) => {
          return {
            id: data?.id,
            title: data?.serviceName,
            start: new Date(data?.startTime),
            end: new Date(data?.endTime),
            resourceId: data?.employeeId,
            employeeName: data?.employeeName,
          };
        });
        setMyEvents([...newData]);
      });
  };

  const getDataStaff = () => {
    axios.get(`/employee/get?supplierId=${supplierId}&page=0&size=1000`).then((response) => {
      let newData = response?.data?.data.map((data) => {
        return {
          resourceId: data?.id,
          resourceTitle: data?.fullName,
        };
      });
      setInfoEmployee([...newData]);
    });
  };

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: new Date(),
      views: ['day'],
    }),
    []
  );

  const moveEvent = ({ event, start, end, resourceId, isAllDay: droppedOnAllDaySlot = false }) => {
    const time = new Date();
    const timeconvert = moment(time).format('HH:mm:ss');
    if (start < new Date()) {
      notification.warning({
        message: `Không thể xếp lịch sau ${timeconvert}`,
      });
    } else {
      if (event.end < new Date()) {
        notification.warning({
          message: `Không thể xếp lịch sau ${timeconvert}`,
        });
      } else {
        if (event.start < new Date()) {
          notification.warning({
            message: `Không thể xếp lịch`,
          });
        } else {
          const { allDay } = event;
          if (!allDay && droppedOnAllDaySlot) {
            event.allDay = true;
          }
          setMyEvents((prev) => {
            const existing = prev.find((ev) => ev.id === event.id) ?? {};
            const filtered = prev.filter((ev) => ev.id !== event.id);
            return [...filtered, { ...existing, start, end, resourceId, allDay }];
          });
          onUpdMove(event, start, end, resourceId);
        }
      }
    }
  };

  const onUpdMove = (event, start, end, resourceId) => {
    const data = {
      id: event?.id,
      startTime: moment(start).format('DD-MM-YYYY HH:mm:ss'),
      endTime: moment(end).format('DD-MM-YYYY HH:mm:ss'),
      employeeId: resourceId,
    };
    dispatch(
      updTimeBooking(data, {
        onSuccess: () => {},
        onError: (err) => {
          notification.error({
            message: `${err}`,
          });
          setCheckCallApi(false);
        },
      })
    );
  };

  const resizeEvent = ({ event, start, end }) => {
    const time = new Date();
    const timeconvert = moment(time).format('HH:mm:ss');
    if (event.end < new Date()) {
      notification.warning({
        message: `Không thể xếp lịch sau ${timeconvert}`,
      });
    } else {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
      onUpdResize(event, start, end);
    }
  };

  const onUpdResize = (event, start, end) => {
    const data = {
      id: event?.id,
      startTime: moment(start).format('DD-MM-YYYY HH:mm:ss'),
      endTime: moment(end).format('DD-MM-YYYY HH:mm:ss'),
      employeeId: event?.resourceId,
    };
    dispatch(
      updTimeBooking(data, {
        onSuccess: () => {},
        onError: (err) => {
          notification.error({
            message: `${err}`,
          });
          setCheckCallApi(false);
        },
      })
    );
  };

  const onSelectEvent = useCallback((calEvent) => {
    const time = new Date();
    const timeconvert = moment(time).format('HH:mm:ss');
    if (calEvent?.end < new Date()) {
      notification.warning({
        message: `Không thể sửa Booking sau ${timeconvert}`,
      });
    } else {
      if (calEvent?.start < new Date()) {
        setShowUpdTime({ show: true, data: calEvent, checkStartTime: false });
      } else {
        setShowUpdTime({ show: true, data: calEvent, checkStartTime: true });
      }
    }
  }, []);

  const handleAddBooking = ({ event, start, end, resourceId }) => {
    // console.log("data", event)
    const time = new Date();
    const timeconvert = moment(time).format('HH:mm:ss');
    if (start < new Date()) {
      notification.warning({
        message: `Không thể thêm lịch sau ${timeconvert}`,
      });
    } else {
      // const title = window.prompt('New Event Name')
      // if (title) {
      //     const a = {
      //         title: title,
      //         start: start,
      //         end: end,
      //         resourceId: resourceId,
      //     }
      //     setMyEvents(prev => [...prev, a])
      // }
      setShowIns({ show: true, start: start, end: end, resourceId: resourceId });
    }
  };

  const onCancelShowIns = useCallback(() => {
    setShowIns({ show: false, start: null, end: null, resourceId: null });
  }, []);

  const onCancelShowUpdTime = useCallback(() => {
    setShowUpdTime({ show: false, data: null });
  }, []);

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  {' '}
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/booking'}>
                  {' '}
                  <TableOutlined /> Danh sách Booking
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Lịch Booking</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: '20px' }}>
        <Col span={24}>
          <Fragment>
            <div>
              <DragAndDropCalendar
                style={{ height: '78vh' }}
                step={30}
                resizable
                selectable
                views={views}
                toolbar={false}
                defaultDate={defaultDate}
                defaultView={Views.DAY}
                events={myEvents}
                localizer={localizer}
                resources={infoEmployee}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                onEventDrop={moveEvent}
                onEventResize={resizeEvent}
                onSelectSlot={handleAddBooking}
                onSelectEvent={onSelectEvent}
              />
            </div>
          </Fragment>
        </Col>
      </Row>
      <InsCalendarBooking
        show={showIns.show}
        start={showIns.start}
        end={showIns.end}
        resourceId={showIns.resourceId}
        handleCancel={onCancelShowIns}
      />
      <UpdTimeCalendar
        show={showUpdTime.show}
        data={showUpdTime.data}
        checkStartTime={showUpdTime.checkStartTime}
        handleCancel={onCancelShowUpdTime}
      />
    </>
  );
}

export default CalendarBooking;
