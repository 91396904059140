import React from 'react';

import { Select } from 'antd';
const { Option } = Select;

export default function CustomSelect({
  options = [],
  onChange,
  styles,
  defaultValue,
  title,
  value,
  placeholder,
  showSearch,
  filterOption,
  mode,
  keyName = '',
  stylesParent
}) {
  return (
    <div style={stylesParent}>
      <div>{title}</div>
      <Select
        mode={mode}
        filterOption={filterOption}
        showSearch={showSearch}
        defaultValue={defaultValue}
        style={{ ...styles }}
        onChange={(ev) => {
          onChange(ev, keyName);
        }}
        value={value}
        placeholder={placeholder}
      >
        {options.map((e, i) => (
          <Option key={i} value={e.value}>
            {e.label}
          </Option>
        ))}
      </Select>
    </div>
  );
}
