import {
  Input,
  Button,
  Form,
  Typography,
  Layout,
  Space,
  message,
  Modal,
  notification,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { InputOTP } from "antd-input-otp";
import { useDispatch } from "react-redux";
import { register } from "../../../reducers/authReducer";
import { useHistory } from "react-router-dom";

const { Title, Text } = Typography;
const { Content, Footer } = Layout;

const InputOTPs = ({ isModalVisible, setIsModalVisible,getOTP,payloadRegister,isLogged ,onCreateSuccess = () => {}}) => {
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in secondss
  const timerRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // Clear the inputs and set focus on the first input when the modal is visible
    if (isModalVisible) {
      // Start the countdown timer when the modal becomes visible
      if (!timerRef.current) {
        timerRef.current = setInterval(() => {
          setTimeLeft((prev) => {
            if (prev <= 1) {
              clearInterval(timerRef.current);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    }

    return () => clearInterval(timerRef.current); // Cleanup the interval on component unmount
  }, [isModalVisible]);

  const formattedTimeLeft = useMemo(() => {
    const minutes = String(Math.floor(timeLeft / 60)).padStart(2, '0');
    const seconds = String(timeLeft % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  }, [timeLeft]);

  const handleFinish = (values) => {
    const { otp } = values;
    if (!otp || otp.includes(undefined) || otp.includes("")) {
      return form.setFields([
        {
          name: "otp",
          errors: ["Bạn cần nhập đầy đủ OTP"],
        },
      ]);
    }
    registerAccount(otp)
  };

  const registerAccount = async (otp) => {
    try {
      let convertValue = otp?.toString();
      convertValue = convertValue.replace(/,/g, "");
      getOTP()
      dispatch(
        register({...payloadRegister,otp:convertValue}, {
          onSuccess: (res) => {
            console.log(res)
            notification.success({
              message: `Tạo tài khoản thành công`,
            });
            if(isLogged){
              onCreateSuccess(res?.data?.data?.id)
            }
            else{
              history.push('/login');
            }
 
          },
          onError: () => {
            notification.error({
              message: `Tạo tài khoản thất bại. Tên tài khoản,email hoặc số điện thoại đã tồn tại.`,
            });
          },
        })
      );
      // const res = await axios.post(`${process.env.NEXT_PUBLIC_SERVER_API}/auth/save/user`, {...payloadRegister,otp:convertValue});
      // console.log(res)
      // if (res.status == 200 && res.data?.status?.statusCode == 200) {
      //   await loginUser({
      //     username:payloadRegister.username,
      //     password:payloadRegister.password,
      //   });
      //   setIsModalVisible(false)
      //   message.success('Đăng ký thành công!')
      //   router.push('/tham-gia-cong-dong');
      // }
      // else{
      //   message.error('Đăng ký không thành công, email hoặc tên tài khoản đã được đăng ký.')
      // }
    } catch (error) {
      message.error('Đăng ký không thành công, email hoặc tên tài khoản đã được đăng ký.')
    }
  }

  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      onCancel={() => setIsModalVisible(false)}
      maskClosable={false}
    >
      <Space
        direction="vertical"
        size="large"
        align="center"
        style={{
          background: "#fff",
          padding: 24,
          borderRadius: 8,
          width: "95%",
        }}
      >
        <Title level={2}>Xác thực OTP</Title>
        <Text>Vui lòng nhập mã OTP được gửi trong email.</Text>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            name="otp"
            className="center-error-message"
            rules={[{ validator: async () => Promise.resolve() }]}
          >
            <InputOTP autoFocus inputType="numeric" length={6} />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              block
              htmlType="submit"
              type="primary"
              style={{ marginTop: 16, height: "auto" }}
            >
              Xác nhận
            </Button>
          </Form.Item>
        </Form>
        <Text type="secondary">Vui lòng nhập mã OTP được gửi về email của bạn: {formattedTimeLeft}</Text>
        <Text type="secondary">
          Bạn không nhận được mã? <Text style={{
            color: '#1677ff',
            textDecoration: 'none',
            outline: 'none',
            cursor: 'pointer',
            transition: 'color 0.3s',
          }} onClick={() => {
            getOTP()
            setTimeLeft(300)
          }}>Gửi lại</Text>
        </Text>
      </Space>
    </Modal>
  );
};
export default InputOTPs;
