import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DatePicker, Form, Image, Input, InputNumber, message, Modal, notification, Switch, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { addAccount, addBanner, getBanner, updateBanner } from '../../reducers/staffReducer';
import { getInfoUser } from '../../reducers/authReducer';
import ImgCrop from 'antd-img-crop';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../constant/data';
import moment from 'moment';
import dayjs from 'dayjs';
import { handleBeforeUpload } from '../../utils';

const UpBanner = (props) => {
  const dispatch = useDispatch();
  const [detail, setDetail] = useState({})
  const [startDate, setStartDate] = useState( dayjs('2024-02-02 00:01'))
  const [endDate, setEndDate] = useState( dayjs('2024-02-02 00:01'))
  const { show, handleCancel ,id} = props;
  const [form] = Form.useForm();
  const [images, setImages] = useState('');
  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });
  useEffect(() => {
    dispatch(getInfoUser());
  }, [dispatch]);

  const handleCancelIns = () => {
    handleCancel();
  };

  const handleChangeLogo = (info) => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    if (status === 'done') {
      const urlList = fileList.map((file) => {
        return file.response?.data;
      });
      setImages(urlList);
    } else if (status === 'error') {
    }
  };

  const convertValueTime = (value,typeTime) => {
    if(typeTime == 'timeShow'){
      const  date = moment(
        value,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD-MM-YYYY 00:00:00");
      const time = moment(
        value,
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH:mm");
      return {startDate: date,sTimeToShow:time}
    
    }
    else{
      const  date = moment(
        value,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD-MM-YYYY 23:58:58");
      const time = moment(
        value,
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH:mm");
      return {endDate: date,eTimeToShow:time}
    }
  }

  const onIns = (values) => {
      const body = {
        "id":id,
        "bannerName":values?.bannerName,
        "urlImage":images[0] || '',
        "isShow":values?.isShow,
        "indexShow":values?.indexShow,
        "deleted":false,
        "isDefault":false,
        ...convertValueTime(values?.timeShow,'timeShow'),
        ...convertValueTime(values?.timeHidden,'timeHidden')
      };

      dispatch(
        updateBanner(body, {
          onSuccess: (message) => {
            notification.success({
              message: `Thêm banner thành công`,
            });
            handleCancelIns();
          },
          onError: (res) => {
            notification.error({
              message: res.data?.status?.message,
            });
          },
        })
      );
  };

  const handleDateChange = (value,typeTime) => {
    if(typeTime == 'timeShow'){
      const  date = moment(
        value,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD-MM-YYYY 00:00:00");
      const time = moment(
        value,
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH:mm");
      form.setFieldsValue({ ...form.getFieldsValue(), startDate: date,sTimeToShow:time ,timeShow:value});
      setStartDate(value ? dayjs(value) : dayjs('2024-02-02 00:01'));
    }
    else{
      const  date = moment(
        value,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD-MM-YYYY 23:58:58");
      const time = moment(
        value,
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH:mm");
      form.setFieldsValue({ ...form.getFieldsValue(), startDate: date,sTimeToShow:time,timeHidden:value });
      setEndDate(value ? dayjs(value) : dayjs('2024-02-02 00:01'))
    }

  }

  const handleDateChangeReConvert = (value,time,typeTime) => {
    if(typeTime == 'timeShow'){

      const timeShow = moment(
        value,
        "DD-MM-YYYY HH:mm"
      ).format("YYYY-MM-DD");
      console.log(`${timeShow} ${time}`)
      setStartDate(dayjs(moment(`${timeShow} ${time}`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm")));

      form.setFieldsValue({ ...form.getFieldsValue() ,timeShow:`${timeShow} ${time}`});
    }
    else{
      const timeEnd = moment(
        value,
        "DD-MM-YYYY HH:mm"
      ).format("YYYY-MM-DD");
      setEndDate(dayjs(moment(`${timeEnd} ${time}`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm")));
      form.setFieldsValue({ ...form.getFieldsValue() ,timeHidden:`${timeEnd} ${time}`});
    }

  }

  useEffect(() => {
     dispatch(
      getBanner({id}, {
        onSuccess: (data) => {
          form.setFieldsValue({
            ...data?.data?.data?.data
          });
          setDetail(data?.data?.data?.data)
          setImages([data?.data?.data?.data.urlImage])
          handleDateChangeReConvert(data?.data?.data?.data?.startDate,data?.data?.data?.data?.sTimeToShow,'timeShow')
          handleDateChangeReConvert(data?.data?.data?.data?.endDate,data?.data?.data?.data?.eTimeToShow,'timeHidden')
        },
        onError: (res) => {
          console.log(res)
        },
      })
    );
  

  }, [id])


    console.log(props,show)
  return (
    <Modal
      title={`Thêm banner`}
      open={show}
      style={{minWidth:"600px"}}
      onCancel={handleCancelIns}
      okButtonProps={{ form: 'insertBanner', key: 'submit', htmlType: 'submit' }}
    >
      {!!detail && <Form
        form={form}
        id="insertBanner"
        onFinish={onIns}
        autoComplete="off"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
      >
        <Form.Item
          label="Tên banner"
          name="bannerName"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Tên banner' }]}
        >
          <Input placeholder="Tên banner" />
        </Form.Item>

        <Form.Item label="Banner" style={{ fontWeight: '500' }}>
          <ImgCrop rotationSlider aspect={16 / 5}>
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
              headers={JWT_HEADER}
              method="POST"
              onChange={handleChangeLogo}
              beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
            >
              {images ? (
                <Image
                  className="imgLogo"
                  src={`${REACT_APP_API_KEY_IMAGE}${images}`}
                  alt="avatar"
                  preview={false}
                />
              ) : (
                <div style={{ fontWeight: 'bold' }}>
                  <PlusOutlined />
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          label="Chọn thời gian hiển thị"
          name="timeShow"
          style={{ fontWeight: '500' ,width:"100%"}}
          rules={[{ required: true, message: 'Chọn thời gian hiển thị' }]}>
            <div className="attr-detail attr-size mb-10">
              <DatePicker
                  format={'YYYY-MM-DD HH:mm'}
                  placeholder="Chọn thời gian"
                  showTime
                  value={ startDate}
                  onChange={(value, dateString) => {
                    console.log(dateString)
                    handleDateChange(dateString,'timeShow');
                  }}
                />
            </div>
        </Form.Item>
        <Form.Item
          label="Chọn thời gian ẩn"
          name="timeHidden"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Chọn thời gian ẩn' }]}>
            <div className="attr-detail attr-size mb-10">
              <DatePicker
                  format={'YYYY-MM-DD HH:mm'}
                  placeholder="Chọn thời gian"
                  showTime
                  value={endDate}
                  onChange={(value, dateString) => {
                    console.log(dateString)
                    handleDateChange(dateString,'timeHidden');
                  }}
                />
            </div>
        </Form.Item>
    
        <Form.Item
          style={{ fontWeight: "500" }}
          label="Hiển thị"
          name="isShow"
          rules={[{ required: true }]}
        >
          <Switch
          />
        </Form.Item>
      </Form>}
    </Modal>
  );
};
export default UpBanner;
