import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Col,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  SwapOutlined,
} from '@ant-design/icons';

import Filter from '../../constant/Filter';
import CustomSreach from '../../constant/Sreach';
import { lockStaff } from '../../reducers/staffReducer';
import axios from '../../services/Api';

import InsStaff from './InsStaff';
import UpdStaff from './UpdStaff';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../constant/data';

const Staff = () => {
  const dispatch = useDispatch();
  const supplierId = localStorage.getItem('supplierId');
  const [showIns, setShowIns] = useState({ show: false });
  const [showUpd, setShowUpd] = useState({ show: false, id: null });
  const [totalPage, setTotalPage] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [renderDel, setRenderDel] = useState(false);
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);

  useEffect(() => {
    if (supplierId !== '') {
      getDataStaff();
    }
    setRenderDel(false);
  }, [showIns.show, showUpd.show, supplierId, page, size, renderDel === true, renderSreach]);

  const getDataStaff = () => {
    axios
      .get(`/employee/get?supplierId=${supplierId}&page=${page}&size=${size}&query=${query}`)
      .then((response) => {
        setDataTable(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const onsearch = () => {
    getDataStaff()
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const onReloadSearch = () => {
    setQuery('');
    setRenderSreach(!renderSreach);
  };

  const onDelete = (record) => {
    dispatch(
      lockStaff(record?.id, {
        onSuccess: (message) => {
          notification.success({
            message: `Thành công`,
          });
          setRenderDel(true);
        },
        onError: (message) => {
          notification.error({
            message: `Thất bại`,
          });
        },
      })
    );
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: 50,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'avatar',
      key: 'avatar',
      width: 100,
      render: (value) => (
        <img
          width={40}
          height={40}
          style={{ borderRadius: 20 }}
          src={
            value !== '' && value !== null && value !== undefined
              ? `${REACT_APP_API_KEY_IMAGE}${value}`
              : 'https://cdn-icons-png.flaticon.com/512/4944/4944197.png'
          }
        />
      ),
    },
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value) => (
        <span
          style={{
            borderRadius: 5,
            background: value === true ? '#B3E5D1' : '#E5B4B3',
            fontSize: '14px',
            color: value === true ? '#194D3A' : '#863A2D',
            padding: 4,
            border: value === true ? '1px solid #B3E5D1' : '1px solid #E5B4B3',
          }}
        >
          {value === true ? 'Mở' : 'Khóa'}
        </span>
      ),
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (record) => (
        <Space>
          <Popconfirm
            title={`Bạn có chắc chắn muốn ${record?.status ? 'khoá' :'mở'} tài khoản?`}
            onConfirm={() => onDelete(record)}
          >
            <Popover content={<h4>Đổi trạng thái tài khoản</h4>}>
              <SwapOutlined
                style={{
                  background: '#406FBF',
                  borderRadius: 3,
                  fontSize: '12px',
                  color: 'white',
                  padding: 5,
                  border: '1px solid #406FBF',
                }}
              />
            </Popover>
          </Popconfirm>
          <Popover content={<h4> Sửa thông tin nhân viên</h4>}>
            <EditOutlined
              style={{
                background: 'rgb(55, 181, 157)',
                borderRadius: 3,
                fontSize: '12px',
                color: 'white',
                padding: 5,
                border: '1px solid rgb(55, 181, 157)',
              }}
              onClick={() => onShowUpd(record)}
            />
          </Popover>
        </Space>
      ),
    },
  ];

  const onShowIns = async () => {
    setShowIns({
      show: true,
    });
  };

  const onCancelShowIns = () => {
    setShowIns({
      show: false,
    });
  };

  const onShowUpd = async (record) => {
    setShowUpd({
      show: true,
      id: record?.id,
    });
  };

  const onCancelShowUpd = () => {
    setShowUpd({
      show: false,
      id: null,
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  {' '}
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Danh sách nhân viên</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24}>
            <Col span={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
            <Col span={12}>
              <Button
                style={{
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                }}
                type="primary"
                onClick={onShowIns}
              >
                <PlusOutlined />
                Thêm
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach
                      styleInput={{ width: '80%' }}
                      placeholder="Tìm kiếm theo tên hoặc số điện thoại"
                      value={query}
                      onChange={onChangeQuery}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onsearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
            scroll={{ x: 'calc(400px + 50%)' }}
          />
        </Col>
      </Row>
      <InsStaff show={showIns.show} handleCancel={onCancelShowIns} />
      <UpdStaff show={showUpd.show} id={showUpd.id} handleCancel={onCancelShowUpd} />
    </>
  );
};

export default Staff;
