import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { HomeOutlined, PlusOutlined, VerticalAlignTopOutlined, EditOutlined ,HolderOutlined} from '@ant-design/icons';
import { Breadcrumb, Button, Col, notification, Popover, Row, Space, Table, Tooltip } from 'antd';
import axios from '../../services/Api';
import moment from 'moment';
import { REACT_APP_API_KEY_IMAGE } from '../../constant/data';
import InsertBanner from './InsertBanner';
import UpBanner from './UpBanner';
import { onDragPosition, upTopBanner } from '../../reducers/staffReducer';

const Banners = () => {
  const dispatch = useDispatch();
  const supplierId = localStorage.getItem('supplierId');
  const [showIns, setShowIns] = useState({ show: false });
  const [showUpd, setShowUpd] = useState({ show: false, id: null });
  const [totalPage, setTotalPage] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [renderDel, setRenderDel] = useState(false);
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);
  const [draggedItemId, setDraggedItemId] = useState(null);

  useEffect(() => {
    if (supplierId !== '') {
      getDataStaff();
    }
    setRenderDel(false);
  }, [showIns.show, showUpd.show, supplierId, page, size, renderDel === true, renderSreach]);

  const getDataStaff = () => {
    axios
      .get(`/banner/get?&page=${page}&size=${size}&query=${query}`)
      .then((response) => {
        setDataTable(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const onsearch = () => {
    getDataStaff();
  };

  const onReloadSearch = () => {
    setQuery('');
    setRenderSreach(!renderSreach);
  };

  // Handle drag start, storing the dragged item's id
  const handleDragStart = (id) => {
    setDraggedItemId(id);
  };

  // Handle drop, updating the order of dataTable based on the drop position
  const handleDrop = (droppedItemId) => {
    const draggedItemIndex = dataTable.findIndex(item => item.id === draggedItemId);
    const droppedItemIndex = dataTable.findIndex(item => item.id === droppedItemId);
    const previousItemId = droppedItemIndex > 0 ? dataTable[droppedItemIndex - 1].id : null;
    if (draggedItemIndex !== -1 && droppedItemIndex !== -1) {
      const updatedData = [...dataTable];
      const [draggedItem] = updatedData.splice(draggedItemIndex, 1);
      updatedData.splice(droppedItemIndex, 0, draggedItem);
      setDataTable(updatedData);
      console.log('Dragged Item ID:', draggedItemId);
      console.log('Dropped on Item ID:', droppedItemId);
      console.log('Previous Item ID:', previousItemId); 

      
      if(previousItemId == null){
        onPushToTop({id:draggedItemId})
      }else{
        dispatch(
          onDragPosition({fromId: draggedItemId,toId: droppedItemId}, {
            onSuccess: (message) => {
              notification.success({
                message: `Đổi vị trí thành công`,
              });
              getDataStaff();
            },
            onError: (res) => {
              notification.error({
                message: res.data?.status?.message,
              });
            },
          })
        );
      }

    }
  };

  // Table columns with drag and drop attributes
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) =>(
        <div> 
          <HolderOutlined /> 
          <span> </span>
           {
          
          i + 1
        }</div>
      
      ),
      width: 50,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'urlImage',
      key: 'urlImage',
      width: 100,
      render: (value) => (
        <img
          width={40}
          height={40}
          style={{ borderRadius: 20 }}
          src={
            value
              ? `${REACT_APP_API_KEY_IMAGE}${value}`
              : 'https://cdn-icons-png.flaticon.com/512/4944/4944197.png'
          }
        />
      ),
    },
    {
      title: 'bannerName',
      dataIndex: 'bannerName',
      key: 'bannerName',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian hiển thị',
      dataIndex: 'sTimeToShow',
      key: 'sTimeToShow',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {moment(row?.startDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY')} {row?.sTimeToShow}
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian ẩn',
      dataIndex: 'eTimeToShow',
      key: 'eTimeToShow',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {moment(row?.endDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY')} {row?.eTimeToShow}
        </Tooltip>
      ),
    },
    {
      title: 'Thứ tự show',
      dataIndex: 'indexShow',
      key: 'indexShow',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isShow',
      key: 'isShow',
      width: 100,
      render: (value) => (
        <span
          style={{
            borderRadius: 5,
            background: value === true ? '#B3E5D1' : '#E5B4B3',
            fontSize: '14px',
            color: value === true ? '#194D3A' : '#863A2D',
            padding: 4,
            border: value === true ? '1px solid #B3E5D1' : '1px solid #E5B4B3',
          }}
        >
          {value === true ? 'Đang show' : 'Ẩn'}
        </span>
      ),
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (record) => (
        <Space>
          <Popover content={<h4> Sửa thông tin</h4>}>
            <EditOutlined
              style={{
                background: 'rgb(55, 181, 157)',
                borderRadius: 5,
                fontSize: '12px',
                color: 'white',
                padding: 5,
                border: '1px solid rgb(55, 181, 157)',
              }}
              onClick={() => onShowUpd(record)}
            />
          </Popover>
          <Popover content={<h4> Đẩy lên đầu</h4>}>
          <VerticalAlignTopOutlined 
            style={{
              background: 'rgb(20 99 210)',
              borderRadius: 5,
              fontSize: '12px',
              color: 'white',
              padding: 5,
              border: '1px solid rgb(55, 181, 157)',
            }}
            onClick={() => onPushToTop(record)}
          />
          </Popover>
        </Space>
      ),
    },
  ];

  const onShowIns = async () => {
    setShowIns({ show: true });
  };

  const onCancelShowIns = () => {
    setShowIns({ show: false });
  };

  const onShowUpd = async (record) => {
    setShowUpd({ show: true, id: record?.id });
  };

  const onPushToTop = async (record) => {
    dispatch(
      upTopBanner({id: record?.id }, {
        onSuccess: (message) => {
          notification.success({
            message: `Đẩy lên đầu thành công`,
          });
          getDataStaff();
        },
        onError: (res) => {
          notification.error({
            message: res.data?.status?.message,
          });
        },
      })
    );
  };

  const onCancelShowUpd = () => {
    setShowUpd({ show: false, id: null });
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Danh sách banner</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24} style={{ marginBottom: '10px' }}>
            <Col span={12}>
              {/* <Filter onClickFilter={onClickFilter} /> */}
            </Col>
            <Col span={12}>
              <Button
                style={{ backgroundColor: 'rgb(55, 181, 157)', border: 'rgb(55, 181, 157)', float: 'right' }}
                type='primary'
                icon={<PlusOutlined />}
                onClick={onShowIns}
              >
                Thêm mới
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: 1300 }}
            pagination={{
              current: page + 1,
              total: totalPage,
              pageSize: size,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              onChange: (page) => setPage(page - 1),
              onShowSizeChange: (current, pageSize) => onShowSizeChange(current, pageSize),
            }}
            onRow={(record, index) => ({
              draggable: true,
              onDragStart: () => handleDragStart(record.id),
              onDragOver: (e) => e.preventDefault(), // Allow dropping by preventing the default behavior
              onDrop: () => handleDrop(record.id),
            })}
          />
        </Col>
      </Row>

      {/* Insert modal */}
      {showIns.show && (
        <InsertBanner
          show={showIns.show}
          handleCancel={onCancelShowIns}
          supplierId={supplierId}
          setRenderDel={setRenderDel}
        />
      )}

      {/* Update modal */}
      {showUpd.show && (
        <UpBanner
          show={showUpd.show}
          id={showUpd.id}
          handleCancel={onCancelShowUpd}
          supplierId={supplierId}
          setRenderDel={setRenderDel}
        />
      )}
    </>
  );
};

export default Banners;
