import React, { useState } from 'react';

import { Button, Col, Row } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
export default function Filter({ onClickFilter }) {
  return (
    <Row span={24} style={{ paddingBottom: 10 }}>
      <Col span={24}>
        <Button type="primary" onClick={onClickFilter}>
          <FilterOutlined />
          Bộ lọc
        </Button>
      </Col>
    </Row>
  );
}
