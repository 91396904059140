import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePicker, Form, Modal, notification } from 'antd';

import CustomSelect from '../../../constant/Select';
import { updTimeBooking } from '../../../reducers/bookingReducer';
import axios from '../../../services/Api';

import moment from 'moment';

function UpdTimeCalendar(props) {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const supplierId = localStorage.getItem('supplierId');
  const { show, data, checkStartTime, handleCancel } = props;
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [employee, setEmployee] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState('');
  const employeeList = employee?.map((e, i) => ({ key: e.id, value: e.id, label: e.fullName }));

  useEffect(() => {
    if (supplierId !== '') {
      getEmployee();
    }
    if (data !== null) {
      setEmployeeName(data?.employeeName);
      setEmployeeId(data?.resourceId);
      setStartTime(moment(data?.start).format('DD-MM-YYYY HH:mm:ss'));
      setEndTime(moment(data?.end).format('DD-MM-YYYY HH:mm:ss'));
    }
  }, [show]);

  const getEmployee = () => {
    axios.get(`/employee/get?supplierId=${supplierId}&page=0&size=1000`).then((response) => {
      setEmployee(response?.data?.data);
    });
  };

  const onUpdTimeBooking = () => {
    const body = {
      id: data?.id,
      startTime: startTime + ':00',
      endTime: endTime + ':59',
      employeeId: employeeId,
    };
    console.log('body', body);
    dispatch(
      updTimeBooking(body, {
        onSuccess: () => {
          handleCancel();
        },
        onError: (err) => {
          notification.error({
            message: `${err}`,
          });
        },
      })
    );
  };

  const onChangeEmployee = (id, data) => {
    setEmployeeId(id);
    setEmployeeName(data?.children);
  };
  const onChangeTime = (e, date) => {
    console.log('12', date);
    setStartTime(date[0]);
    setEndTime(date[1]);
  };

  return (
    <>
      <Modal
        title={`Sửa thời gian Booking`}
        visible={show}
        onCancel={handleCancel}
        onOk={onUpdTimeBooking}
      >
        <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Form.Item label="Chọn thời gian" style={{ fontWeight: 'bold' }}>
            <div className="selectCustomer">
              <RangePicker
                disabled={checkStartTime === true ? [false, false] : [true, false]}
                showTime={true}
                format="DD-MM-YYYY HH:mm"
                placeholder={[
                  moment(data?.start).format('DD-MM-YYYY HH:mm'),
                  moment(data?.end).format('DD-MM-YYYY HH:mm'),
                ]}
                onChange={onChangeTime}
              />
            </div>
          </Form.Item>
          <Form.Item label="Chọn nhân viên" style={{ fontWeight: 'bold' }}>
            <div className="selectCustomer">
              <CustomSelect
                styles={{ with: '100%' }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                options={employeeList}
                value={employeeName}
                onChange={onChangeEmployee}
              />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default memo(UpdTimeCalendar);
