import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Popconfirm, Row, Switch, TimePicker, Upload, notification } from 'antd';
import ImgCrop from 'antd-img-crop';

import CustomSelect from '../../../constant/Select';
import axios from '../../../services/Api';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../../constant/data';
import { addSubSupplier, checkUser } from '../../../reducers/supplierReducer';
import '../Supplier.css';
import ModalRegisterUser from './ModalRegisterUser';
import GoogleMaps from '../../../components/GoogleMap';
import { useLoadScript } from '@react-google-maps/api';
import { handleBeforeUpload } from '../../../utils';
import SearchAccount from './SearchAccount';

const InsSubSupplier = () => {
  const { isLoaded } = useLoadScript({
    libraries: ['places'],
    googleMapsApiKey: 'AIzaSyAtQwrcO3SLM-5PmFgHLFd31FE-x_o3cg8', // Add your API key
  });
  const router = useParams();
  const dispatch = useDispatch();

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const history = useHistory();
  const initState = {
    district: null,
    province: null,
    ward: null,
    supplierType:false
  };

  const [fieldState, setFieldState] = useState(initState);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [provinceId, setProvinceId] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [wardId, setWardId] = useState('');
  const [id, setId] = useState(0);
  const provinceList = provinces?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const districtList = districts?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const wardList = wards?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const [images, setImages] = useState('');
  const [banner, setBanner] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [endTime, setEndTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalSearchAccount, setOpenModalSearchAccount] = useState(false);
  const [markerDefaults, setMarkerDefaults] = useState();
  const [userId, setUserId] = useState("");
  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });

  const handleChangeLogo = (info) => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    if (status === 'done') {
      const urlList = fileList.map((file) => {
        return file.response?.data;
      });
      setImages(urlList);
      form.setFieldValue('image',urlList)
    } else if (status === 'error') {
    }
  };

  useEffect(() => {
    getProvince();
    setFileList([]);
  }, []);

  const getProvince = () => {
    axios.get(`/address/province?page=0&size=500`).then((response) => {
      setProvinces(response?.data?.data);
    });
  };

  const getDistrict = (provinceId) => {
    axios.get(`/address/district?page=0&size=500&provinceId=${provinceId}`).then((response) => {
      setDistricts(response?.data?.data);
    });
  };

  const getWard = (districtId) => {
    axios.get(`/address/ward?page=0&size=500&districtId=${districtId}`).then((response) => {
      setWards(response?.data?.data);
    });
  };

  const onChangeSelect = (type) => (ev) => {
    if (type === 'province') {
      getDistrict(ev);
      setProvinceId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: provinceList?.find((e) => e.key === ev)?.label,
        district: null,
        ward: null,
      }));
    }
    if (type === 'district') {
      getWard(ev);
      setDistrictId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: districtList?.find((e) => e.key === ev)?.label,
        ward: null,
      }));
    }
    if (type === 'ward') {
      setWardId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: wardList?.find((e) => e.key === ev)?.label,
      }));
    }
  };

  const onSave = (value) => {
    if (!markerDefaults) {
      notification.error({
        message: 'Bạn cần chọn vị trí trước khi lưu thông tin!',
      });
      return;
    }
    let st1 = startTime.split(':')[0];
    let st2 = parseInt(st1) * 60;
    let st3 = startTime.split(':')[1];
    let startTimeUpd = parseInt(st2) + parseInt(st3);

    let en1 = endTime.split(':')[0];
    let en2 = parseInt(en1) * 60;
    let en3 = endTime.split(':')[1];
    let endTimeUpd = parseInt(en2) + parseInt(en3);
    let body = {
      name: value.name,
      address: value.address,
      phone: value.phone,
      description: value.description,
      provinceId: provinceId,
      districtId: districtId,
      wardId: wardId,
      image: images,
      banner: banner.length > 0 ? banner : null,
      latLong: '21.051544 - 105.778248',
      startTime: startTimeUpd,
      endTime: endTimeUpd,
      supplierId: Number(router.id),
      userId: userId,
      supplierType:value.supplierType ? 1 : 0
    };
    dispatch(
      checkUser(body, {
        onSuccess: (res) => {
          if (!!res.data?.data?.data?.id) {
            body = { ...body, userId: res.data?.data?.data?.id };
          }
          insertChildSupplier(body);
        },
        onError: (er) => {
          notification.error({
            message: `Không tìm thấy tài khoản nào có số điện thoại này. Vui lòng thêm tài khoản ncc trước khi tạo.`,
          });
        },
      })
    );
  };

  const insertChildSupplier = (body) => {
    dispatch(
      addSubSupplier(body, {
        onSuccess: (response) => {
          if(response?.data?.data?.status?.statusCode == 200){
            notification.success({
              message: `Lưu thông tin thành công`,
            });
            history.push('/child-supplier');
          }
          else{
            notification.error({
              message: response?.data?.data?.status?.message == "Không tìm thấy user" ? "Vui lòng thêm tài khoản chi nhánh" : response?.data?.data?.status?.message || 'Đã có lỗi xảy ra',
            });
          }
        },
        onError: (er) => {
          if (er.data.message === '') {
            notification.error({
              message: `Lưu thông tin thất bại`,
            });
          } else {
            notification.error({
              message: `${er?.data?.status?.message || 'Tạo chi nhánh không thành công. Vui lòng kiểm tra lại các thông tin đã nhập.'}`,
            });
          }
        },
      })
    );
  };

  const handleChangeImage = (info) => {
    setFileList(info?.fileList);
    let fileList = [...info.fileList];
    const urlList = fileList.map((file) => {
      return file.response?.data;
    });
    setBanner(urlList);
    form.setFieldValue('banner',urlList)
  };

  const onPreview = async (file) => {
    const check = file.response;
    if (check === undefined) {
      window.open(file.url, '_blank');
    } else {
      const link = file?.response?.image;
      window.open(`${REACT_APP_API_KEY}${link}`, '_blank');
    }
  };

  const onChangeStartTime = (date, value) => {
    setStartTime(value);
  };

  const onChangeEndTime = (date, value) => {
    setEndTime(value);
  };

  const handleSearchUser = () => {
    console.log(form);
  };

  useEffect(() => {
    form.setFieldValue("supplierType", false)
  }, []);

  return (
    <>
      <div style={{ color: 'black', textAlign: 'center' }}>
        <h2>Tạo Chi nhánh</h2>
      </div>

      <Row span={24} style={{ marginTop: '10px' }}>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={22} lg={18} xl={18} xxl={18}>
          <Form
            form={form}
            onFinish={onSave}
            autoComplete="off"
            labelCol={{ xs: 24, sm: 11, md: 8, lg: 6, xl: 6, xxl: 4 }}
            wrapperCol={{ xs: 24, sm: 13, md: 16, lg: 18, xl: 18, xxl: 20 }}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item
                 label="Logo" name={'image'} rules={[{ required: true, message: 'Vui lòng upload logo' }]} style={{ fontWeight: '500' }}
                 >
                  <ImgCrop rotate>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                      headers={JWT_HEADER}
                      method="POST"
                      onChange={handleChangeLogo}
                      beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                    >
                      {images ? (
                        <Image
                          className="imgLogo"
                          src={`${REACT_APP_API_KEY_IMAGE}${images}`}
                          alt="avatar"
                          preview={false}
                        />
                      ) : (
                        <div style={{ fontWeight: 'bold' }}>
                          <PlusOutlined />
                          <div style={{ marginTop: 4, fontWeight: 'bold' }}>Logo</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Form.Item label="Banner" name={"banner"} rules={[{ required: true, message: 'Vui lòng upload banner' }]} style={{ fontWeight: '500' }}>
                  <Upload
                    multiple
                    action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                    headers={JWT_HEADER}
                    method="POST"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleChangeImage}
                    onPreview={onPreview}
                    beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                  >
                    {banner.length < 5 && '+ Upload'}
                  </Upload>
                </Form.Item>
                <Form.Item label="Thời gian mở cửa" style={{ fontWeight: '500' }}>
                  <TimePicker
                    format=" HH:mm"
                    placeholder={startTime !== '' ? startTime : 'Chọn thời gian'}
                    onChange={onChangeStartTime}
                  />
                </Form.Item>
                <Form.Item label="Thời gian đóng cửa" style={{ fontWeight: '500' }}>
                  <TimePicker
                    format=" HH:mm"
                    placeholder={endTime !== '' ? endTime : 'Chọn thời gian'}
                    onChange={onChangeEndTime}
                  />
                </Form.Item>
                <Form.Item
                  label="Tên Cộng đồng"
                  name="name"
                  rules={[{ required: true, message: 'Nhập tên Cộng đồng' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Tên Cộng đồng" />
                </Form.Item>
                <Form.Item
                  label="Tài khoản chủ chi nhánh"
                  name="accountAdminBranch"
                  rules={[{ required: true, message: 'Nhập tài khoản chủ chi nhánh' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input disabled placeholder="Tài khoản chủ chi nhánh" />
                </Form.Item>
                <Form.Item
                  label="Private"
                  style={{ fontWeight: '500' }}
                  name="supplierType"
                  rules={[{ required: true,}]}
                >
                  <Switch />
                </Form.Item>

                <Form.Item
                  label="Số điện thoại"
                  name="phone"
                  rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Số điện thoại" maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}/>
                </Form.Item>

                <Form.Item
                  label="Thành Phố"
                  name="province"
                  rules={[{ required: true, message: 'Nhập thành phố' }]}
                  style={{ fontWeight: '500' }}
                >
                  <CustomSelect
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    value={fieldState.province}
                    options={provinceList}
                    onChange={onChangeSelect('province')}
                  />
                </Form.Item>
                <Form.Item
                  label="Quận/ Huyện"
                  name="district"
                  rules={[{ required: true, message: 'Nhập quận/huyện' }]}
                  style={{ fontWeight: '500' }}
                >
                  <CustomSelect
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    value={fieldState.district}
                    options={districtList}
                    onChange={onChangeSelect('district')}
                  />
                </Form.Item>
                <Form.Item
                  label="Phường/ Xã"
                  name="ward"
                  rules={[{ required: true, message: 'Nhập phường/xã' }]}
                  style={{ fontWeight: '500' }}
                >
                  <CustomSelect
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    value={fieldState.ward}
                    options={wardList}
                    onChange={onChangeSelect('ward')}
                  />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ"
                  name="address"
                  rules={[{ required: true, message: 'Nhập địa chỉ' }]}
                  style={{ fontWeight: '500' }}
                >
                  <TextArea autoSize placeholder="Địa chỉ" />
                </Form.Item>
                <Form.Item
                  label="Vị trí "
                  name="latLong"
                  rules={[{ message: 'Nhập vị trí latLong' }]}
                  style={{ fontWeight: '500' }}
                >
                  {isLoaded && (
                    <GoogleMaps
                      markerDefaults={markerDefaults}
                      setMarkerDefaults={setMarkerDefaults}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label="Mô tả"
                  name="description"
                  rules={[{ required: false, message: 'Nhập mô tả' }]}
                  style={{ fontWeight: '500' }}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 6 }} placeholder="Mô tả" />
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={{ paddingBottom: 20, display: 'flex', gap: 10, justifyContent: 'end' }}
              >
                {/* <Popconfirm
                  title="Bạn muốn tìm tài khoản mới hay tìm kiếm tài khoản có sẵn"
                  okText="Tạo tài khoản mới"
                  cancelText="Tìm tài khoản"
                  onConfirm={() => {
                    setOpenModal(true);
                  }}
                  onCancel={() => {
                    setOpenModalSearchAccount(true);
                  }}
                >
                <Button
                  type="primary"
                >
                  Thêm tài khoản chi nhánh
                </Button>
                </Popconfirm> */}
                 <Button
                  type="primary"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Thêm tài khoản chi nhánh
                </Button>
                
                <Button
                  style={{
                    backgroundColor: '#37B59D',
                    borderRadius: 5,
                    border: '#37B59D',
                    color: 'white',
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Xác nhận
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={1} lg={4} xl={4} xxl={4} />
      </Row>
      {openModal && <ModalRegisterUser
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        handleCreateSuccess={(data) => {
          setUserId(data?.id)
          form?.setFieldValue('accountAdminBranch',data?.name)
        }}
      />}

      {openModalSearchAccount && <SearchAccount
        open={openModalSearchAccount}
        onClose={() => {
          setOpenModalSearchAccount(false);
        }}
        handleCreateSuccess={(data) => {
          // setUserId(data?.id)
          // form?.setFieldValue('accountAdminBranch',data?.name)
        }}
      />}
       
    </>
  );
};

export default InsSubSupplier;
