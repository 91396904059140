import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb, Button, Col, Row, Space, Switch, Tabs } from 'antd';
import { HomeOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import formatMoney from '../../components/ConvertMoney';
import Filter from '../../constant/Filter';
import FilterTime from '../../constant/FilterTime';
import axios from '../../services/Api';

import ColumnReport from './ColumnReport';
import PieReport from './PieReport';

import './report.css';

import moment from 'moment';

export default function Report() {
  const [dataAPI, setDataAPI] = useState([]);
  const [dataRPEmployee, setDataRPEmployee] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateView, setStartDateView] = useState('');
  const [endDateView, setEndDateView] = useState('');
  const [moneySV, setMoney] = useState('');
  const [moneyEmployee, setMoneyEmployee] = useState(undefined);
  const [checkTab, setCheckTab] = useState('reportSV');

  const supplierId = 1;
  const dateFormat = 'DD-MM-YYYY';
  const [checkSwitch, setCheckSwitch] = useState('Thống kê theo tiền');
  const [getDataSwitch, setGetCheckSwitch] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [query, setQuery] = useState('');
  const [renderSreach, setRenderSreach] = useState(false);

  useEffect(() => {
    const today = new Date();
    const todayDate = new Date().toLocaleDateString('sv-SE');
    today.setDate(today.getDate() - 30);
    const date = new Date(today).toLocaleDateString('sv-SE');
    setStartDate(moment(date).format('DD-MM-YYYY') + ' ' + '00:00:00');
    setEndDate(moment(todayDate).format('DD-MM-YYYY') + ' ' + '23:59:59');
    setStartDateView(moment(date).format('DD-MM-YYYY'));
    setEndDateView(moment(todayDate).format('DD-MM-YYYY'));
  }, []);

  useEffect(() => {
    if (supplierId !== '' && startDate !== '' && endDate !== '') {
      getDataReportService(startDate, endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (supplierId !== '' && startDate !== '' && endDate !== '') {
      getDataReportEmpolyee(startDate, endDate);
    }
  }, [startDate, endDate]);

  const onChangeSwitch = () => {
    if (checkSwitch === 'Thống kê theo tiền') {
      setCheckSwitch('Thống kê theo số lượt phục vụ');
      setGetCheckSwitch(!getDataSwitch);
    } else {
      setCheckSwitch('Thống kê theo tiền');
      setGetCheckSwitch(!getDataSwitch);
    }
  };
  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  const onChange = (key) => {
    setCheckTab(key);
  };

  const getDataReportService = (startDate, endDate) => {
    axios
      .get(`/report/supplier?supplierId=${supplierId}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => {
        setMoney(response?.data?.data.money);
        if (response.data.status.statusCode === 200) {
          setDataAPI(response?.data?.data.listServices);
        }
      });
  };

  const getDataReportEmpolyee = (startDate, endDate) => {
    axios
      .get(`/report/employee?supplierId=${supplierId}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => {
        console.log('Log API thống kê theo nhân viên', response?.data?.data);
        setMoneyEmployee(response?.data?.data.money);
        if (response.data.status.statusCode === 200) {
          setDataRPEmployee(response?.data?.data);
        }
      });
  };

  const onChangeTime = (e, date) => {
    setStartDate(date[0] + ' ' + '00:00:00');
    setEndDate(date[1] + ' ' + '23:59:59');
  };

  const onReloadSearch = () => {
    const today = new Date();
    const todayDate = new Date().toLocaleDateString('sv-SE');
    today.setDate(today.getDate() - 7);
    const date = new Date(today).toLocaleDateString('sv-SE');
    setStartDate(moment(date).format('DD-MM-YYYY') + ' ' + '00:00:00');
    setEndDate(moment(todayDate).format('DD-MM-YYYY') + ' ' + '23:59:59');
    setStartDateView(moment(date).format('DD-MM-YYYY'));
    setEndDateView(moment(todayDate).format('DD-MM-YYYY'));
    setRenderSreach(!renderSreach);
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const onSearch = () => {
    if (query !== '') {
      const body = {
        query: query,
        supplierId: supplierId,
        startDate: startDate,
        endDate: endDate,
      };
      axios.post(`/report/supplier?supplierId=`, body).then((response) => {
        setDataRPEmployee(response?.data?.data);
        setDataAPI(response?.data?.data);
      });
    } else {
      setRenderSreach(!renderSreach);
    }
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={8} />
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  {' '}
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Thống kê</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24}>
            <Col span={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 10, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <FilterTime
                      placeholder={[startDateView, endDateView]}
                      format={dateFormat}
                      onChangeTime={onChangeTime}
                      styleRangePicker={{ width: '80%' }}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onSearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Col span={24}>
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          items={[
            {
              label: `Thống kê theo dịch vụ`,
              key: 'reportSV',
            },
            {
              label: `Thống kê theo nhân viên`,
              key: 'reportEmployee',
            },
          ]}
        />

        <Row>
          <Col xs={24} sm={24} md={10} lg={6} xl={6}>
            <h3 style={{ marginBottom: 40, marginLeft: 20 }}>
              {' '}
              {checkTab === 'reportSV' ? `Tổng tiền: ${FormatMoney(moneySV)}VND ` : checkSwitch}
              {checkTab === 'reportSV' ? (
                ''
              ) : (
                <Space style={{ marginBottom: 5, marginLeft: 20 }} direction="vertical">
                  <Switch onChange={onChangeSwitch} />{' '}
                </Space>
              )}
            </h3>
          </Col>
          <Col xs={24} sm={10} md={5} lg={6} xl={6} />
          <Col xs={24} sm={6} md={3} lg={4} xl={4} />
        </Row>
        {checkTab === 'reportSV' ? (
          <ColumnReport data={dataAPI} />
        ) : (
          <PieReport dataRPEmployee={dataRPEmployee} getDataSwitch={getDataSwitch} />
        )}
      </Col>
    </>
  );
}
