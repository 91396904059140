import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  SyncOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  message,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';

import formatMoney from '../../components/ConvertMoney';
import Filter from '../../constant/Filter';
import CustomSreach from '../../constant/Sreach';
import { deleteService } from '../../reducers/serviceVbReducer';
import axios from '../../services/Api';
import ModalAddUser from './ModalAddUser';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../constant/data';
import supplierReducer, { getSupplierInfo } from '../../reducers/supplierReducer';

const ServiceVb = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const supplierId = localStorage.getItem('supplierId');
  const [totalPage, setTotalPage] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [renderDel, setRenderDel] = useState(false);
  const [query, setQuery] = useState('');
  const [showAddUser, setShowAddUser] = useState({ show: false, id: null });
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);
  const [supplierInfo, setSupplierInfo] = useState(false);

  const supplierInfos = useSelector((store) => store?.supplierReducer?.supplierInfo);

  useEffect(() => {
    setSupplierInfo(supplierInfos?.data?.data[0])
  }, [supplierInfos]);

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  useEffect(() => {
    if (supplierId !== '') {
      getDataService();
    }
    setRenderDel(false);
  }, [supplierId, page, size, renderDel === true, renderSreach]);

  const getDataService = () => {
    axios
      .get(`/service/get/supplier?supplier=${supplierId}&page=${page}&size=${size}`)
      .then((response) => {
        setDataTable(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const syncFromParent = () => {
    axios
      .post(`/service/synchronize-service?supplierId=${supplierId}`)
      .then((response) => {
        // console.log(response)
        if(response?.data?.status?.statusCode == '200'){
          message.success("Đồng bộ dịch vụ thành công")
          getDataService();;
        }else{
          message.error(response?.data?.status?.message || "Đồng bộ dịch vụ thất bại")
        }
      });
  };

  const onSearch = () => {
    if (query !== '') {
      axios.get(`/service/search?supplierId=${supplierId}&query=${query}`).then((response) => {
        setDataTable(response?.data?.data);
      });
    } else {
      setRenderSreach(!renderSreach);
    }
  };

  const onReloadSearch = () => {
    setQuery('');
    setRenderSreach(!renderSreach);
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const onDelete = (record) => {
    dispatch(
      deleteService(record?.id, {
        onSuccess: (message) => {
          notification.success({
            message: `Xóa dịch vụ thành công`,
          });
          setRenderDel(true);
        },
        onError: (message) => {
          notification.error({
            message: `Xóa thất bại`,
          });
        },
      })
    );
  };

  const onShowAddUser = (record) => {
    setShowAddUser({ show: true, id: record?.id });
  };

  const onCannelShowAddUser = (record) => {
    setShowAddUser({ show: false, id: null });
  };

  const convertDataType = (value) => {
    switch (value) {
      case 1:
        return 'Giá tiền';
      case 2:
        return 'Khoảng giá';
      case 3:
        return 'Giá liên hệ';
      default:
        return 'Giá tiền';
    }
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: 50,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      key: 'image',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <img
          width={45}
          height={30}
          style={{ borderRadius: 5 }}
          src={`${REACT_APP_API_KEY_IMAGE}${!value ? null : value[0]}`}
        />
      ),
      width: 100,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    // {
    //   title: 'Nhân viên',
    //   dataIndex: 'employeeName',
    //   key: 'namemployeeNamee',
    //   width: 200,
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (value) => (
    //     <Tooltip placement="topLeft" title={value}>
    //       {value} demo
    //     </Tooltip>
    //   ),
    // },
    {
      title: 'Loại giá',
      dataIndex: 'type',
      width: 200,
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {convertDataType(value)}
        </Tooltip>
      ),
    },
    {
      title: 'Giá dịch vụ',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 3,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Giá giảm',
      dataIndex: 'discount',
      key: 'discount',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 3,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Giá tối thiểu',
      dataIndex: 'minPrice',
      key: 'minPrice',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 3,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Giá tối da',
      dataIndex: 'maxPrice',
      key: 'maxPrice',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 3,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian phục vụ',
      dataIndex: 'serviceTime',
      key: 'serviceTime',
      width: 160,
      ellipsis: {
        showTitle: false,
      },
      sorter: {
        compare: (a, b) => a.serviceTime - b.serviceTime,
        multiple: 3,
      },
      render: (value) => (
        <span>
          <b>{value} phút</b>
        </span>
      ),
    },
    {
      title: 'Nhóm dịch vụ',
      dataIndex: 'groupName',
      key: 'groupName',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
          <Tag color={value ?'green':'volcano'}>{value ? 'Hoạt động':'Ngừng hoạt động'}</Tag>
      ),
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (record) => (
        <Space>
          <Popover content={<h4> Sửa thông tin</h4>}>
            <EditOutlined
              style={{
                background: 'rgb(55, 181, 157)',
                borderRadius: 5,
                fontSize: '12px',
                color: 'white',
                padding: 5,
                border: '1px solid rgb(55, 181, 157)',
              }}
              onClick={() => onShowUpd(record)}
            />
          </Popover>

          <Popover content={<h4> Thêm nhân viên</h4>}>
            <UserAddOutlined
              style={{
                background: '#0693E3',
                borderRadius: 5,
                fontSize: '12px',
                color: 'white',
                padding: 5,
                border: '1px solid #0693E3',
              }}
              onClick={() => onShowAddUser(record)}
            />
          </Popover>

          {/* <Popconfirm title="Bạn có chắc chắn muốn xóa dịch vụ?" onConfirm={() => onDelete(record)}>
            <Popover content={<h4> </h4>}>
              <DeleteOutlined
                style={{
                  background: 'rgb(240, 65, 52)',
                  borderRadius: 5,
                  fontSize: '12px',
                  color: 'white',
                  padding: 5,
                  border: '1px solid rgb(240, 65, 52)',
                }}
              />
            </Popover>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  const onShowUpd = (record) => {
    history.push('/updServiceVb'.concat(`/${record?.id}`), { id: record?.id });
  };

  const onShowIns = async () => {
    history.push('/insServiceVb');
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Danh sách dịch vụ</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24}>
            <Col span={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
            <Col span={12}>
            {
              (supplierInfo?.code != 0) &&  <Button
              style={{
                backgroundColor: '#3784b5',
                border: 'rgb(55, 181, 157)',
                float: 'right',
              }}
              type="primary"
              onClick={() => {
                syncFromParent()
              }}
            >
              <SyncOutlined />
              Đồng bộ từ cộng đồng cha
            </Button>
            }
             
              <Button
                style={{
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                   marginRight:'10px'
                }}
                type="primary"
                onClick={onShowIns}
              >
                <PlusOutlined />
                Thêm
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach
                      styleInput={{ width: '80%' }}
                      placeholder="Tìm kiếm"
                      value={query}
                      onChange={onChangeQuery}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onSearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
            scroll={{ x: 'calc(400px + 50%)' }}
          />
        </Col>
      </Row>
      <ModalAddUser
        show={showAddUser.show}
        id={showAddUser.id}
        handleCancel={onCannelShowAddUser}
      />
    </>
  );
};

export default ServiceVb;
