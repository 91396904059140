import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Breadcrumb,
  Button,
  Col,
  notification,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  CheckOutlined,
  EditOutlined,
  ExportOutlined,
  FileExcelOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  LockOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import CustomSreach from '../../constant/Sreach';
import axios from '../../services/Api';

import ChangeRole from './ChangeRole';

import moment from 'moment';
// import UpdStaff from "./UpdStaff";
// import { lockStaff } from "../../reducers/staffReducer";

const Role = () => {
  //   const dispatch = useDispatch();
  //   const supplierId = localStorage.getItem("supplierId");
  const [showIns, setShowIns] = useState({ show: false });
  //   console.log("showIns", showIns.show);
  //   const [showUpd, setShowUpd] = useState({ show: false, id: null });

  const [totalPage, setTotalPage] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [renderDel, setRenderDel] = useState(false);
  const [query, setQuery] = useState('');

  //   useEffect(() => {
  //     if (supplierId !== "") {
  //       getDataStaff();
  //     }
  //     setRenderDel(false);
  //   }, [
  //     showIns.show,
  //     showUpd.show,
  //     supplierId,
  //     page,
  //     size,
  //     renderDel === true,
  //     query === "",
  //   ]);

  const getDataStaff = () => {
    // axios
    //   .get(`/employee/get?supplierId=${supplierId}&page=${page}&size=${size}`)
    //   .then((response) => {
    //     setDataTable(response?.data?.data);
    //     setTotalPage(response?.data?.totalSize);
    //   });
  };

  const onsearch = () => {
    // if (query !== "") {
    //   axios
    //     .get(`/employee/search?supplierId=${supplierId}&&query=${query}`)
    //     .then((response) => {
    //       setDataTable(response?.data?.data);
    //     });
    // }
  };

  const onDelete = (record) => {
    //     dispatch(
    //       lockStaff(record?.id, {
    //         onSuccess: (message) => {
    //           notification.success({
    //             // message: `${message?.data?.data?.message}`,
    //             message: `Khóa thành công`,
    //           });
    //           setRenderDel(true);
    //         },
    //         onError: (message) => {
    //           notification.error({
    //             // message: `${message?.data?.message}`,
    //             message: `Khóa thất bại`,
    //           });
    //         },
    //       })
    //     );
  };

  const data = [
    {
      key: '1',
      email: 'yeuemladieuakhongthengo@gmail.com',
      account: 'minhdm',
      role: 'Nhân viên',
    },
    {
      key: '2',
      email: 'nkocxinh@gmail.com',
      account: 'add2k',
      role: 'Nhân viên',
    },
    {
      key: '3',
      email: 'kimjoongkook@gmail.com',
      account: 'luukd',
      role: 'Admin',
    },
    {
      key: '4',
      email: 'add2k@gmail.com',
      account: 'duhd',
      role: 'Nhân viên',
    },
    {
      key: '5',
      email: 'kimjoongkook@gmail.com',
      account: 'nhattm',
      role: 'Nhân viên',
    },
    {
      key: '6',
      email: 'mtp@gmail.com',
      account: 'congpt',
      role: 'Nhân viên',
    },
  ];

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: '5%',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (value) => (
        <img
          width={30}
          height={30}
          style={{ borderRadius: 50 }}
          src={
            value !== '' && value !== null && value !== undefined
              ? value
              : 'https://cdn-icons-png.flaticon.com/512/4944/4944197.png'
          }
        />
      ),
      width: '5%',
    },
    {
      title: 'Tài khoản',
      dataIndex: 'account',
      key: 'account',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },

    {
      title: 'Quyền',
      dataIndex: 'role',
      key: 'role',
      width: '10%',
      render: (value) => (
        <span
          style={{
            background: value === 'Admin' ? '#B3E5D1' : '#E5B4B3',
            borderRadius: 5,
            fontSize: '14px',
            color: value === 'Admin' ? '#194D3A' : '#863A2D',
            padding: 4,
            border: value === 'Admin' ? '1px solid #B3E5D1' : '1px solid #E5B4B3',
          }}
        >
          {value === 'Admin' ? 'Admin' : 'Nhân viên'}
        </span>
      ),
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: '5%',
      render: (record) => (
        <Space>
          <Popover content={<h4> Gán quyền</h4>}>
            <CheckOutlined
              style={{
                background: 'rgb(55, 181, 157)',
                borderRadius: 3,
                fontSize: '12px',
                color: 'white',

                padding: 5,
                border: '1px solid rgb(55, 181, 157)',
              }}
              onClick={() => {
                setShowIns({
                  show: true,
                  id: record?.id,
                });
              }}
            />
          </Popover>

          <Popconfirm
            title="Bạn có chắc chắn muốn khóa tài khoản?"
            onConfirm={() => onDelete(record)}
          >
            <Popover content={<h4>Khóa tài khoản</h4>}>
              <LockOutlined
                style={{
                  background: 'rgb(240, 65, 52)',
                  borderRadius: 3,
                  fontSize: '12px',
                  color: 'white',
                  padding: 5,
                  border: '1px solid rgb(240, 65, 52)',
                }}
              />
            </Popover>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onShowIns = async () => {
    //   setShowIns({
    //     show: true,
    //   });
  };

  const onCancelShowIns = () => {
    setShowIns({
      show: false,
    });
  };

  //   const onCancelShowUpd = () => {
  //     setShowUpd({
  //       show: false,
  //       id: null,
  //     });
  //   };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/">
              <b>Danh sách tài khoản</b>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row span={24} style={{ paddingTop: '20px' }}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <div className="customer-search">
            <CustomSreach onClick={onsearch} onChange={onChangeQuery} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} />
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Button
            style={{
              marginTop: '1px',
              marginLeft: '5px',
              backgroundColor: '#0693E3',
              borderRadius: 5,
              border: '#0693E3',
              float: 'right',
              height: '35px',
            }}
            type="primary"
            // onClick={onShowNewUser}
          >
            <ExportOutlined />
            Xuất File
          </Button>
        </Col>

        <Col span={24} style={{ paddingTop: '20px' }}>
          <Table
            // rowSelection={
            //   {
            //     selectedRows: selectedRows,
            //     selectedRowKeys: selectedKeys,
            //     onChange: (selectedRowKeys, selectedRows) => {
            //         onChangeRowSelectedData(selectedRowKeys, selectedRows)
            //     }
            //   }
            // }
            columns={columns}
            dataSource={data}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
          />
        </Col>
      </Row>
      <ChangeRole show={showIns.show} handleCancel={onCancelShowIns} />
      {/* <UpdStaff
        show={showUpd.show}
        id={showUpd.id}
        handleCancel={onCancelShowUpd}
      />{" "} */}
    </>
  );
};

export default Role;
