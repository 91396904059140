import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';

import {
  Affix,
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  message,
  notification,
  Result,
  Row,
  Upload,
} from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import logo from '../../../assets/logovuong.png';
import { sendMess } from '../../../reducers/messReducer';
import axios from '../../../services/Api';
import ConversationList from '../ConversationList';
import MessageDetail from '../Message';

import './Messenger.css';

import moment from 'moment';
import { REACT_APP_API_KEY } from '../../../constant/data';
import { handleBeforeUpload } from '../../../utils';
// import SockJsClient from 'react-stomp';
// import InfiniteScroll from 'react-infinite-scroll-component';

const MY_USER_ID = 0;

const JWT_HEADER = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Cache: 'no-cache',
});

export default function Message(props) {
  const dispatch = useDispatch();

  const supplierId = localStorage.getItem('supplierId');

  const initState = {
    id: '',
    message: '',
    author: '',
    adminId: '',
    type: '',
    channel: '',
    timestamp: '',
  };

  const [messages, setMessages] = useState([initState]);
  const [conversation, setConversation] = useState('');
  const [conversationId, setConversationId] = useState();
  const [sizeScroll, setSizeScroll] = useState(30);
  const [size, setSize] = useState(30);
  const [pageScroll, setPageScroll] = useState(1);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [totalData, setTotalData] = useState();
  const [inputText, setInputText] = useState('');

  console.log('conversation', conversation);
  console.log('messages', messages);
  useEffect(() => {
    if (conversationId !== undefined) {
      getMess();
    }
  }, [conversationId]);

  const getMess = async () => {
    axios
      .get(`/conversation/get/message?conversationId=${conversationId}&page=${page}&size=${size}`)
      .then((response) => {
        setTotalData(response.data.totalSize);
        let dataMess = response.data.data.map((data) => {
          return {
            id: data?.id,
            message: data?.message,
            author: data?.isQuestion,
            adminId: data?.adminId,
            type: data?.type,
            channel: data?.channel,
            timestamp: data?.createdTime,
          };
        });
        setMessages(dataMess);
        setHasMore(true);
      });
  };

  const handleInfinite = async () => {
    if (message.length < totalData) {
      axios
        .get(
          `/conversation/get/message?conversationId=${conversationId}&page=${pageScroll}&size=${sizeScroll}`
        )
        .then((response) => {
          const data = response.data.data;
          setMessages(message.concat(data));
          setHasMore(true);
          setPageScroll((prev) => prev + 1);
        });
    } else {
      setHasMore(false);
    }
  };

  const onsubmit = (conversationId, conversation) => {
    setConversation(conversation);
    setConversationId(conversationId);
  };

  const renderMessages = () => {
    let i = 0;
    let messageCount = messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.author == MY_USER_ID;
      let currentMoment = moment(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.author === current.author;
        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }
        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }
      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;
        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }
      tempMessages.push(
        <MessageDetail
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );
      i += 1;
    }
    return tempMessages;
  };

  const postInputText = () => {
    const body = {
      conversationId: conversationId,
      message: inputText,
      supplierId: supplierId,
      type: 'text',
      channel: '',
    };
    dispatch(
      sendMess(body, {
        onSuccess: () => {
          setInputText('');
        },
      })
    );
    setInputText('');
  };

  const handleKeypress = (e) => {
    if (e.key == 'Enter') {
      postInputText();
    }
  };

  const handleChange = (e) => {
    setInputText(e.target.value);
    // setListImages([])
  };

  return (
    <Row span={24}>
      <Col xs={5} sm={5} md={9} lg={8} xl={7} xxl={5}>
        <div className="sidebar">
          <ConversationList onsubmit={onsubmit} />
        </div>
      </Col>
      <Col xs={19} sm={19} md={15} lg={10} xl={11} xxl={13}>
        {conversationId !== undefined ? (
          <div id="scrollableDiv" className="content">
            <div className="message-list">
              <div className="toolbar">
                <h3 style={{ paddingLeft: 10 }}> {conversation?.name}</h3>
              </div>

              <InfiniteScroll
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                dataLength={messages?.length}
                next={handleInfinite}
                inverse={true}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
              >
                <div className="message-list-container">{renderMessages()}</div>
              </InfiniteScroll>

              <div className="compose">
                <div
                  style={{
                    paddingTop: '9px',
                    paddingLeft: '10px',
                    paddingRight: '5px',
                  }}
                >
                  <Upload
                    multiple={true}
                    method="POST"
                    name="files"
                    showUploadList={false}
                    action={`${REACT_APP_API_KEY}/image/chat`}
                    headers={JWT_HEADER}
                    beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                  >
                    <button
                      style={{
                        cursor: 'pointer',
                        borderStyle: 'none',
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      <svg
                        t="1629879327515"
                        className="icon"
                        viewBox="0 0 1152 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2079"
                        width="25"
                        height="25"
                      >
                        <path
                          d="M1088 128h-64V64C1024 28.75 995.25 0 960 0H64C28.75 0 0 28.75 0 64v768c0 35.25 28.75 64 64 64h64V960c0 35.25 28.75 64 64 64h896c35.25 0 64-28.75 64-64V192c0-35.25-28.75-64-64-64z m-960 64v640H64.125l-0.125-0.125V64.125l0.125-0.125h895.75l0.125 0.125v63.875H192c-35.25 0-64 28.75-64 64z m960 767.875l-0.125 0.125H192.125l-0.125-0.125V192.125l0.125-0.125h895.75l0.125 0.125v767.75z m0 0"
                          p-id="2080"
                        />
                        <path
                          d="M960 352c0 34.25-18.375 66-48 83.125-29.75 17.125-66.375 17.125-96 0-29.75-17.125-48-48.875-48-83.125 0-53 43-96 96.125-96 52.875 0 95.875 43 95.875 96z m64 544H256V768L480 384l256 320H800l224-192v384z m0 0"
                          p-id="2081"
                          fill="#666666"
                        />
                      </svg>
                    </button>
                  </Upload>
                </div>
                {/* <div style={{ height: '40px', paddingTop: '9px', paddingLeft: '5px', paddingRight: '10px' }}>
               <Upload
                 multiple={true}
                 method="POST"
                 name="files"
                 showUploadList={false}
                 action={`${REACT_APP_API_KEY}/chat/reply/file?conversationId=${conversation?.id}`}
               // headers={HEADERS.JWT_HEADER()}
               >
                 <button style={{ cursor: 'pointer', borderStyle: 'none', backgroundColor: "#FFFFFF" }}>
                   <svg t="1660024991062" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1643" width="30" height="25"><path d="M480 580H372a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h108v108a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8V644h108a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8H544V472a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v108z m374.6-291.3c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2z"
                     p-id="1644" fill="#666666"></path></svg>
                 </button>
               </Upload>
             </div> */}

                <input
                  onChange={handleChange}
                  className="compose-input"
                  placeholder="Aaa"
                  onKeyPress={handleKeypress}
                  value={inputText}
                />
                <div className="compose-Submit">
                  <button
                    style={{
                      cursor: 'pointer',
                      paddingLeft: '20px',
                      borderStyle: 'none',
                      backgroundColor: '#FFFFFF',
                    }}
                    onClick={postInputText}
                  >
                    <svg
                      t="1629878730307"
                      className="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1502"
                      width="25"
                      height="25"
                    >
                      <path
                        d="M729.173333 469.333333L157.845333 226.496 243.52 469.333333h485.674667z m0 85.333334H243.541333L157.824 797.504 729.173333 554.666667zM45.12 163.541333c-12.352-34.986667 22.762667-67.989333 56.917333-53.482666l853.333334 362.666666c34.645333 14.72 34.645333 63.829333 0 78.549334l-853.333334 362.666666c-34.133333 14.506667-69.269333-18.474667-56.917333-53.482666L168.085333 512 45.098667 163.541333z"
                        p-id="1503"
                        fill="#666666"
                      />
                    </svg>
                  </button>
                </div>
                {props.rightItems}
              </div>
            </div>
          </div>
        ) : (
          <Result
            style={{ marginTop: '20%' }}
            status="404"
            subTitle="Không có dữ liệu, chọn cuộc hội thoại"
          />
        )}
      </Col>

      <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6} style={{ borderLeft: '1px solid #CCCCCC' }}>
        {conversationId !== undefined ? (
          <Row span={24}>
            <Col span={24}>
              <div style={{ textAlign: 'center', marginTop: 40 }}>
                <img width={100} src={logo} />
                <p>
                  <h2>{conversation?.name}</h2>
                </p>
              </div>
            </Col>
            <Col span={24} style={{ paddingLeft: 20 }}>
              <p>
                <h2>Thông tin</h2>
              </p>
              <p>
                <h3>Tên: {conversation?.name}</h3>
              </p>
              <p>
                <h3>SĐT: {conversation?.phone}</h3>
              </p>
              <p>
                <h3>Email: {conversation?.email}</h3>
              </p>
            </Col>
          </Row>
        ) : (
          <Result
            style={{ marginTop: '65%' }}
            icon={<SmileOutlined />}
            subTitle="Không có thông tin người chat"
          />
        )}
      </Col>
    </Row>
  );
}
