import React, { useEffect, useState } from 'react';

import { Col, List, Modal, Row } from 'antd';

import formatMoney from '../../components/ConvertMoney';
import axios from '../../services/Api';

import moment from 'moment';
export default function ShowDiscountSV(props) {
  const { show, handleCancel, id, detailVoucher } = props;
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(4);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState('');

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  useEffect(() => {
    if (id !== null) {
      getDataListDetail();
    }
  }, [page, size, show, id]);

  const getDataListDetail = () => {
    axios.get(`voucher/get-detail?voucherId=${id}&page=${page}&size=${size}`).then((response) => {
      setDataTable(response?.data?.data);
      setTotalPage(response?.data?.totalSize);
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  return (
    <Modal
      width={'35%'}
      title={`Danh sách Voucher`}
      visible={show}
      onOk={handleCancel}
      onCancel={handleCancel}
    >
      <div
        style={{
          height: '400px',
          overflow: 'auto',
        }}
      >
        <List
          itemLayout="vertical"
          size="small"
          pagination={{
            size: 'small',
            onChange: (page) => {
              setPage(page - 1);
            },
            pageSize: size,
            total: totalPage,
            showSizeChanger: false,
            onShowSizeChange: onShowSizeChange,
            showTotal: (total) => <div>{`Tổng Voucher : ${total}`}</div>,
          }}
          dataSource={dataTable}
          renderItem={(item) => (
            <List.Item
              key={item.title}
              style={{
                marginBottom: '10px',
                boxShadow:
                  'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset',
              }}
            >
              <List.Item.Meta
                title={
                  <Row span={24}>
                    <Col span={24}>
                      <div> Mã Voucher: {item.code}</div>
                    </Col>
                  </Row>
                }
                description={
                  <Row span={16}>
                    <Col span={16}>
                      <Row span={24}>
                        <Col span={24}>
                          Thời gian bắt đầu:{' '}
                          {moment(detailVoucher?.createDate).format('DD-MM-YYYY HH:mm:ss')}
                        </Col>
                        <Col span={24}>
                          Thời gian kết thúc:{' '}
                          {moment(detailVoucher?.endTime).format('DD-MM-YYYY HH:mm:ss')}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      {item.status === false ? (
                        <div style={{ color: '#00D084', fontSize: '1.2em' }}> Chưa sử dụng</div>
                      ) : (
                        <div style={{ color: '#D0021B', fontSize: '1.2em' }}> Đã sử dụng</div>
                      )}
                    </Col>
                  </Row>
                }
              />

              <div>Giá giảm: {FormatMoney(item.coin)} VND</div>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
}
