import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Col,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import Filter from '../../constant/Filter';
import CustomSreach from '../../constant/Sreach';
import { deleteGroup } from '../../reducers/grService';
import axios from '../../services/Api';

import InsGroup from './InsGroup';
import UpdGroup from './UpdGroup';
import { REACT_APP_API_KEY_IMAGE } from '../../constant/data';

const GrService = () => {
  const dispatch = useDispatch();
  const supplierId = localStorage.getItem('supplierId');

  const [showIns, setShowIns] = useState({ show: false });
  const [showUpd, setShowUpd] = useState({ show: false, id: null });
  const [totalPage, setTotalPage] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [renderDel, setRenderDel] = useState(false);
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);
  const dataUser = useSelector((store) => store?.authReducer.infoUser);

  useEffect(() => {
    if (supplierId !== '') {
      getDataGroupService();
    }
    setRenderDel(false);
  }, [showIns.show, showUpd.show, supplierId, page, size, renderDel === true, renderSreach]);

  const getDataGroupService = () => {
    axios.get(`/group-service/get?supplierId=${supplierId}&page=${page}&size=${size}`).then((response) => {
      setDataTable(response?.data?.data);
      setTotalPage(response?.data?.totalSize);
    });
  };

  const onsearch = () => {
    if (query !== '') {
      axios.get(`group-service/search?query=${query}`).then((response) => {
        setDataTable(response?.data?.data);
      });
    } else {
      setRenderSreach(!renderSreach);
    }
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const onDelete = (record) => {
    dispatch(
      deleteGroup(record?.id, {
        onSuccess: (message) => {
          notification.success({
            message: `Xóa nhóm dịch vụ thành công`,
          });
          setRenderDel(true);
        },
        onError: (message) => {
          notification.error({
            message: `Xóa thất bại`,
          });
        },
      })
    );
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: '5%',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      key: 'avaimagetar',
      width: 100,
      render: (value) => (
        <img
          width={40}
          height={40}
          style={{ borderRadius: 20 }}
          src={
            value !== '' && value !== null && value !== undefined
              ? `${REACT_APP_API_KEY_IMAGE}${value}`
              : 'https://cdn-icons-png.flaticon.com/512/4944/4944197.png'
          }
        />
      ),
    },
    {
      title: 'Tên nhóm ',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Mô tả ',
      dataIndex: 'description',
      key: 'description',
      width: '35%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <>
          <span
            style={{
              background: value === true ? '#00d084' : '#BF4340',
              borderRadius: 5,
              fontSize: '13px',
              color: '#FFFFFF',
              fontWeight: 'bold',
              padding: 5,
              border: value === true ? '#00d084' : '#BF4340',
            }}
          >
            {value === true ? 'Mở' : 'Khóa'}
          </span>
        </>
      ),
    },

  ];

  if(dataUser?.email == "vveco@gmail.com"){
    columns.push(
      {
        title: 'Chức năng',
        key: 'action',
        width: '10%',
        fixed: 'right',
        ellipsis: {
          showTitle: false,
        },
        render: (record) => (
          <Space>
            <Popover content={<h4> Sửa thông tin</h4>}>
              <EditOutlined
                style={{
                  background: 'rgb(55, 181, 157)',
                  borderRadius: 3,
                  fontSize: '12px',
                  color: 'white',
                  padding: 5,
                  border: '1px solid rgb(55, 181, 157)',
                }}
                onClick={() => {
                  setShowUpd({
                    show: true,
                    id: record?.id,
                  });
                }}
              />
            </Popover>
  
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa nhóm dịch vụ?"
              onConfirm={() => onDelete(record)}
            >
              <Popover content={<h4> Xóa nhóm dịch vụ</h4>}>
                <DeleteOutlined
                  style={{
                    background: 'rgb(240, 65, 52)',
                    borderRadius: 3,
                    fontSize: '12px',
                    color: 'white',
                    padding: 5,
                    border: '1px solid rgb(240, 65, 52)',
                  }}
                />
              </Popover>
            </Popconfirm>
          </Space>
        ),
      }
    )
    
  }

  const onShowIns = async () => {
    setShowIns({
      show: true,
    });
  };

  const onCancelShowIns = () => {
    setShowIns({
      show: false,
    });
  };

  const onCancelShowUpd = () => {
    setShowUpd({
      show: false,
      id: null,
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const onReloadSearch = () => {
    setQuery('');
    setRenderSreach(!renderSreach);
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Danh sách nhóm dịch vụ</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24}>
            <Col span={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
            {
              (dataUser?.email == "vveco@gmail.com") && <Col span={12}>
              <Button
                style={{
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                }}
                type="primary"
                onClick={onShowIns}
              >
                <PlusOutlined />
                Thêm
              </Button>
            </Col>
            }
            
          </Row>
        </Col>

        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach
                      styleInput={{ width: '80%' }}
                      placeholder="Tìm kiếm"
                      value={query}
                      onChange={onChangeQuery}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onsearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
          />
        </Col>
      </Row>
      <InsGroup show={showIns.show} handleCancel={onCancelShowIns} />
      <UpdGroup show={showUpd.show} id={showUpd.id} handleCancel={onCancelShowUpd} />
    </>
  );
};
export default GrService;
