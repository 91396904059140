import Banners from './screens/Banners/Banners';
import Bill from './screens/Bill/Bill';
import UpdBill from './screens/Bill/UpdBill';
import Blog from './screens/Blog/Blog';
import InsBlog from './screens/Blog/InsBlog';
import ListBlog from './screens/Blog/ListBlog';
import UpdBlog from './screens/Blog/UpdBlog';
import Booking from './screens/Booking/Booking';
import BookingDetail from './screens/Booking/BookingDetail';
import CalendarBooking from './screens/Booking/CalendarBooking/CalendarBooking';
import InsBooking from './screens/Booking/InsBooking';
import UpdateBooking from './screens/Booking/UpdateBooking';
import Customer from './screens/Customer/Customer';
import DemoUserRef from './screens/demoHook/demoUserRef';
import DemoMemo from './screens/demoHook/memo/DemoMemo';
import DemoUseMemo from './screens/demoHook/memo/DemoUseMemo';
import DemoUseReducer from './screens/demoHook/useReducer/demoUseReducer';
import TodoAppwithuseReducer from './screens/demoHook/useReducer/TodoAppwithuseReducer';
import Discount from './screens/Discount/Discount';
import ShowDiscountSV from './screens/Discount/ShowDiscountSV';
import FeedbackAntd from './screens/FeedBack-antd/FeedBackAntd';
import FeedBackService from './screens/FeedBack-antd/FeedBackService';
import FeedBackSupplier from './screens/FeedBack-antd/FeedBackSupplier';
import GrService from './screens/GrService/GrService';
import ViewAllNoti from './screens/NotiWeb/ViewAllNoti';
import Report from './screens/Report/report';
import Role from './screens/Role/Role';
import NotiList from './screens/SendNoti/NotiList';
import InsServiceVb from './screens/ServiceVb/InsServiceVb';
import ServiceVb from './screens/ServiceVb/ServiceVb';
import UpdServiceVb from './screens/ServiceVb/UpdServiceVb';
import Setting from './screens/SettingVB/SettingVB';
import Staff from './screens/Staff/Staff';
import InsSFSchedule from './screens/Staff/StaffSchedule/InsSFSchedule';
import StaffSchedule from './screens/Staff/StaffSchedule/StaffSchedule';
import UpdSFSchedule from './screens/Staff/StaffSchedule/UpdSFSchedule';
import SubSupplier from './screens/Supplier/SubSupplier';
import InsSubSupplier from './screens/Supplier/SubSupplier/InsSubSupplier';
import UpdSubSupplier from './screens/Supplier/SubSupplier/UpdSubSupplier';
import Supplier from './screens/Supplier/Supplier';
import InfoUser from './screens/User/InfoUser';

const routes = [
  { path: '/', name: 'Supplier', component: Supplier, exact: true },
  { path: '/child-supplier', name: 'SubSupplier', component: SubSupplier, exact: true },
  {
    path: '/child-supplier/:id/insert',
    name: 'InsSubSupplier',
    component: InsSubSupplier,
  },
  {
    path: '/child-supplier/:id/update/:supplierId',
    name: 'UpdSubSupplier',
    component: UpdSubSupplier,
    exact: true,
  },
  { path: '/report', name: 'Report', component: Report },
  { path: '/infoUser', name: 'InfoUser', component: InfoUser },
  { path: '/customer', name: 'Customer', component: Customer },
  { path: '/staff', name: 'Staff', component: Staff },
  { path: '/staffSchedule', name: 'StaffSchedule', component: StaffSchedule },
  { path: '/insSFSchedule', name: 'InsSFSchedule', component: InsSFSchedule },
  { path: '/updSFSchedule', name: 'UpdSFSchedule', component: UpdSFSchedule },
  { path: '/serviceVb', name: 'ServiceVb', component: ServiceVb },
  { path: '/insServiceVb', name: 'InsServiceVb', component: InsServiceVb },
  { path: '/updServiceVb', name: 'UpdServiceVb', component: UpdServiceVb },
  { path: '/grService', name: 'GrService', component: GrService },
  { path: '/booking', name: 'Booking', component: Booking },
  { path: '/bookingDetail', name: 'BookingDetail', component: BookingDetail },
  { path: '/insBooking', name: 'InsBooking', component: InsBooking },
  { path: '/booking-detail/:id', name: 'UpdateBooking', component: UpdateBooking },
  { path: '/calendarBooking', name: 'CalendarBooking', component: CalendarBooking },
  { path: '/bill', name: 'Bill', component: Bill },
  { path: '/updBill', name: 'UpdBill', component: UpdBill },
  { path: '/setting', name: 'Setting', component: Setting },
  { path: '/discount', name: 'Discount', component: Discount },
  { path: '/showDiscountSV', name: 'ShowDiscountSV', component: ShowDiscountSV },
  { path: '/blog', name: 'Blog', component: Blog },
  { path: '/insBlog', name: 'InsBlog', component: InsBlog },
  { path: '/update-blog/:id', name: 'UpdateBlog', component: InsBlog },
  { path: '/role', name: 'Role', component: Role },
  { path: '/feedBackService', name: 'FeedBackService', component: FeedBackService },
  { path: '/feedBackSupplier', name: 'FeedBackSupplier', component: FeedBackSupplier },
  { path: '/feedbackAntd', name: 'FeedbackAntd', component: FeedbackAntd },
  { path: '/viewAllNoti', name: 'ViewAllNoti', component: ViewAllNoti },
  { path: '/demoUserRef', name: 'DemoUserRef', component: DemoUserRef },
  { path: '/demoMemo', name: 'DemoMemo', component: DemoMemo },
  { path: '/demoUseMemo', name: 'DemoUseMemo', component: DemoUseMemo },
  { path: '/demoUseReducer', name: 'DemoUseReducer', component: DemoUseReducer },
  { path: '/banners', name: 'banners', component: Banners },
  {
    path: '/todoAppwithuseReducer',
    name: 'TodoAppwithuseReducer',
    component: TodoAppwithuseReducer,
  },
  { path: '/noti-to-sb', name: 'ListNoti', component: NotiList },
  { path: '/blogs', name: 'ListBlog', component: ListBlog ,exact: true},
];

export const routes2 = [
  { path: '/', name: 'Supplier', component: Supplier, exact: true },
  { path: '/child-supplier', name: 'SubSupplier', component: SubSupplier, exact: true },
  {
    path: '/child-supplier/:id/insert',
    name: 'InsSubSupplier',
    component: InsSubSupplier,
  },
  {
    path: '/child-supplier/:id/update/:supplierId',
    name: 'UpdSubSupplier',
    component: UpdSubSupplier,
    exact: true,
  },
  { path: '/blogs', name: 'ListBlog', component: ListBlog ,exact: true},

];

export default routes;
