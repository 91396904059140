import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Image, Input, Modal, notification, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { addGroup } from '../../reducers/grService';
import axios from '../../services/Api';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../constant/data';
import ImgCrop from 'antd-img-crop';
import { handleBeforeUpload } from '../../utils';

export default function UpdGroup(props) {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { show, handleCancel, id } = props;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [listImages, setListImages] = useState([]);
  const [images, setImages] = useState('');

  useEffect(() => {
    if (id !== null) {
      getDataById();
    }
  }, [show, id]);

  const getDataById = () => {
    axios.get(`/group-service/get/id?id=${id}`).then((response) => {
      form.setFieldsValue(response?.data?.data);
      setListImages(response?.data?.data?.image);
      const image = response?.data?.data?.image;
      setImages(image);
    });
  };
  const onShowUpd = (values) => {
    const body = {
      id: id,
      name: values?.name,
      status: true,
      description: values?.description,
      image:images
    };
    dispatch(
      addGroup(body, {
        onSuccess: (message) => {
          notification.success({
            message: `Sửa nhóm  dịch vụ thành công`,
          });
          handleCancelUpd();
        },
        onError: (message) => {
          notification.error({
            message: `Sửa thất bại`,
          });
        },
      })
    );
  };

  const handleCancelUpd = () => {
    handleCancel();
    form.setFieldsValue({
      name: '',
      description: '',
    });
  };

  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });



  const handleChangeLogo = (info) => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    if (status === 'done') {
      const urlList = fileList.map((file) => {
        return file.response?.data;
      });
      setImages(urlList?.length ? urlList[urlList?.length - 1] : null);
    } else if (status === 'error') {
    }
  };


  return (
    <Modal
      title={`SỬA THÔNG TIN NHÓM DỊCH VỤ  `}
      open={show}
      onCancel={handleCancel}
      okButtonProps={{
        form: 'UpdGroupService',
        key: 'submit',
        htmlType: 'submit',
      }}
    >
      <Form
        form={form}
        id="UpdGroupService"
        onFinish={onShowUpd}
        autoComplete="off"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
       <Form.Item label="Logo" style={{ fontWeight: '500' }}>
          <ImgCrop rotate>
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
              headers={JWT_HEADER}
              method="POST"
              onChange={handleChangeLogo}
              beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
            >
              {images ? (
                <Image
                  className="image"
                  src={`${REACT_APP_API_KEY_IMAGE}${images}`}
                  alt="avatar"
                  preview={false}
                />
              ) : (
                <div style={{ fontWeight: 'bold' }}>
                  <PlusOutlined />
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Tên nhóm dịch vụ "
          name="name"
          rules={[{ required: true, message: 'Nhập tên nhóm  dịch vụ' }]}
        >
          <TextArea autoSize placeholder="Tên nhóm  dịch vụ" />
        </Form.Item>
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Mô tả"
          name="description"
          rules={[{ required: true, message: 'Nhập mô tả' }]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="Mô tả" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
