import React from 'react';

import { Image } from 'antd';

import './Message.css';

import moment from 'moment';

export default function MessageDetail(props) {
  const { data, isMine, startsSequence, endsSequence, showTimestamp } = props;
  console.log('data', data);
  const friendlyTimestamp = moment(data.timestamp).format('DD-MM-YYYY HH:mm');
  return (
    <div
      className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`,
      ].join(' ')}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

      <div className="bubble-container">
        {/* <div className="bubble" title={friendlyTimestamp}>
                    {data?.messageImages !== null ?
                        <Image
                            width={200}
                            preview={true}
                            src={data?.messageImages}
                        >
                        </Image> :
                        `${data?.message}`}
                </div> */}
        <div className="bubble" title={friendlyTimestamp}>
          {data?.message}
        </div>
      </div>
    </div>
  );
}
