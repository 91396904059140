import React, { memo } from 'react';

import { Button } from 'antd';

function ContentCallBack({ onIncrease }) {
  console.log('re-render-man ContentCallBack');

  return (
    <div style={{ padding: 20 }}>
      <h1>Call back</h1>
      <Button
        type="primary"
        style={{
          marginRight: '6px',
          backgroundColor: 'rgb(55, 181, 157)',
          borderRadius: 5,
          border: 'rgb(55, 181, 157)',
        }}
        onClick={onIncrease}
      >
        click me (Callback)!
      </Button>
    </div>
  );
}

export default memo(ContentCallBack);
