import { message } from "antd";
import imageCompression from 'browser-image-compression';

export const renderPrice = (key, price, minPrice, maxPrice, discount) => {
  switch (key) {
    case 1:
      return (
        <div style={{ fontSize: '13px' }}>
          <div style={{ textDecoration: !!discount ? 'line-through' : 'none' }}>
            {!!price ? number_format(price) + 'đ' : 'Miễn phí'}
          </div>
          {!!discount ? <div>{!!price ? number_format(discount) + 'đ' : 'Miễn phí'}</div> : ''}
        </div>
      );
    case 2:
      return (
        <div style={{ fontSize: '13px' }}>
          {' '}
          {`${number_format(minPrice)}đ - ${number_format(maxPrice)}đ`}
        </div>
      );
    case 3:
      return <div style={{ fontSize: '13px' }}>{`Giá Liên Hệ`}</div>;
    default:
      return (
        <div style={{ fontSize: '13px' }}>
          <div style={{ textDecoration: !!discount ? 'line-through' : 'none' }}>
            {!!price ? number_format(price) + 'đ' : 'Miễn phí'}
          </div>
          {!!discount ? <div>{!!price ? number_format(discount) + 'đ' : 'Miễn phí'}</div> : ''}
        </div>
      );
  }
};
export default function number_format(number, decimals, dec_point, thousands_sep) {
  // *     example: number_format(1234.56, 2, ',', ' ');
  // *     return: '1 234,56'
  number = (number + '').replace(',', '').replace(' ', '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? '.' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

export const toLowerCaseNonAccentVietnamese = (str) => {
  str = str.toLowerCase();
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const removeHtml = (value) => {
  var regex = /(<([^>]+)>)/gi,
    result = value.replace(regex, "");
  return result;
};

export const handleBeforeUpload = async (file) => {
  const MAX_FILE_SIZE = 1024 * 1024; // 1MB
  // Kiểm tra nếu ảnh dưới 200KB thì không cần nén
  if (file.size < MAX_FILE_SIZE) {
    return file; // Trả về file gốc để upload
  }

  const options = {
    maxSizeMB: 1, // Giới hạn kích thước ảnh sau khi nén là 1 MB
    maxWidthOrHeight: 1024, // Giới hạn độ phân giải ảnh
    useWebWorker: true,
  };

  try {
    // Nén ảnh
    const compressedFile = await imageCompression(file, options);
    return compressedFile; // Trả về file đã nén để upload
  } catch (error) {
    message.error('Lỗi khi nén ảnh');
    return false; // Dừng upload nếu nén thất bại
  }
};