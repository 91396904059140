import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Breadcrumb, Layout } from 'antd';
import routes, { routes2 } from '../../routes';
import Header from './DefaultHeader';
import Sidebar from './DefaultSidebar';

import './DefaultLayout.css';
import { checkSuppliers, getInfoUser } from '../../reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
const { Content } = Layout;

const HomeLayout = () => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const checkSupplier = useSelector((store) => store.authReducer.checkSupplier);
  const toggle = () => setCollapsed((prev) => !prev);

  const loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>;

  useEffect(() => {
    dispatch(getInfoUser())
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1920) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [window.innerWidth]);

  function itemRender(route, params, routes, paths) {
    {
      routes.map((route, idx) => {
        return route.component ? (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(props) => <route.component {...props} />}
          />
        ) : null;
      });
    }
  }

  useEffect(() => {
    dispatch(checkSuppliers());
  }, []);

  return (
    <Layout>
      {checkSupplier && <Sidebar collapsed={collapsed} />}
      <Layout className="site-layout full-screen">
        <Header toggle={toggle} collapsed={collapsed} />
        <Breadcrumb itemRender={itemRender} />
        <Content
          className="site-layout-background"
          style={{ margin: '24px 16px', padding: 20, borderRadius: 10 ,position:'relative'}}
        >
          <Suspense fallback={loading}>
            <Switch>
              {(checkSupplier ? routes : routes2).map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                ) : null;
              })}
            </Switch>
          </Suspense>
        </Content>
        {/* <Footer /> */}
      </Layout>
    </Layout>
  );
};
export default HomeLayout;
