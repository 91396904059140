import axios from '../services/Api';

export const CHANGESTATUS = 'CHANGESTATUS';
export const INSBOOKING = 'INSBOOKING';
export const UPDTIMEBOOKING = 'UPDTIMEBOOKING';
export const UPDBOOKING = 'UPDBOOKING';

export const changeStatus = (status, id, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: CHANGESTATUS,
      data: await axios.post(`/booking/status?status=${status}&id=${id}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const addBooking = (userId, body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSBOOKING,
      data: await axios.post(`/booking/save?userId=${userId}`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const updateBookingDetail = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: UPDBOOKING,
      data: await axios.post(`/booking/update-booking-detail`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err?.data?.status?.message);
  }
};

export const updTimeBooking = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: UPDTIMEBOOKING,
      data: await axios.post(`/booking/update/time`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err?.data?.status?.message);
  }
};
