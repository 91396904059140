// import Editor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from '@ckeditor/ckeditor5-build-classic';
import React, { useEffect, useMemo, useState } from "react";
import { toLowerCaseNonAccentVietnamese } from "../../utils";
import editorConfig from "./EditorConfig";
import "./ckeditor.css";
import { uploadFile } from "../../reducers/commonReducer";
import { useDispatch } from "react-redux";

function CKEditor5({
  name,
  title,
  titleHidden,
  onReady,
  value = "",
  config = {},
  onChange,
  onFocus,
  onBlur,
  keyName = "",
  onChangeFile,
  uniqueKey,
  style,
  required,
  error,
  link,
  linkViewFile,
  textIndent = true,
}) {
  const [fileUploads, setFileUploads] = useState([]);
  const dispatch = useDispatch();
  function myUploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("file", file);
            var formdata = new FormData();
            formdata.append("file", file);
            dispatch(
              uploadFile(formdata, {
                onSuccess: (res) => {
                  resolve({
                    default: `${linkViewFile}${res?.data?.data}`,
                  });
                },
               
              })
            );
          });
        });
      },
    };
  }

  function myUploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
      myUploadAdapter(loader);
  }

  const mergeConfig = useMemo(() => {
    return {
      ...editorConfig,
      ...config,
      extraPlugins: [myUploadPlugin],
    };
  }, [config]);

  const _onReady = (editor) => {
    const toolbarContainer = document.querySelector(
      `#toolbar-container-${uniqueKey}`
    );
    if (toolbarContainer) {
      if (!!toolbarContainer.firstElementChild) {
        toolbarContainer.removeChild(toolbarContainer.firstElementChild);
      }
      toolbarContainer.appendChild(editor.ui.view.toolbar.element);
      window.editor = editor;
    }
    onReady && onReady(editor);
  };

  const handleChange = (event, editor) => {
    const data = editor.getData();
    onChange(data, keyName, event, editor);
  };

  const handleFocus = (event, editor) => {
    const data = editor.getData();
    onFocus && onFocus(data, event, editor);
  };

  const handleBlur = (event, editor) => {
    const data = editor.getData();
    const contentElement = editor.sourceElement;

    const headElement = contentElement.querySelectorAll("h1, h2, h3");
    const category = Array.from(headElement).map((e) => {
      e.id = toLowerCaseNonAccentVietnamese(e.innerText);
      return {
        tagName: e.tagName,
        level: +e.tagName[1],
        link: `#${toLowerCaseNonAccentVietnamese(e.innerText)}`,
        label: e.innerText.trim(),
      };
    });
    onBlur && onBlur(data, category, event, editor);
  };

  useEffect(() => {
    onChangeFile && onChangeFile(fileUploads);
  }, [fileUploads]);

  return (
    <div className="i-cma-w-full" style={{ order: 3 }} name={name}>
      <div
        id={`toolbar-container-${uniqueKey}`}
      />
      <div
        style={{ order: 2, border: error ? "1px solid red" : "" }}
        className={textIndent ? "" : "textIndent"}
      >
        <CKEditor
          required
          editor={Editor}
          data={value || ""}
          config={mergeConfig}
          onReady={_onReady}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </div>
      {error && (
        <div
          style={{
            order: 2,
            marginTop: "5px",
            fontSize: "12px",
            color: "#ff0000",
          }}
        >
          {`Vui lòng nhập ${titleHidden || title}`}
        </div>
      )}
    </div>
  );
}

export default CKEditor5;
