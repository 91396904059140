import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Alert, Breadcrumb, Button, Col, Popconfirm, Popover, Row, Space, Table, Tag, Tooltip, message } from 'antd';
import { HomeOutlined, ReloadOutlined, SearchOutlined,  CloseCircleOutlined,FileDoneOutlined } from '@ant-design/icons';
import Filter from '../../constant/Filter';
import CustomSreach from '../../constant/Sreach';
import axios from '../../services/Api';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { approveUser } from '../../reducers/authReducer';

const Customer = () => {
  const supplierId = localStorage.getItem('supplierId');
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState('');
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);

  useEffect(() => {
    getDataCustomer();
  }, [page, size, renderSreach]);

  const getDataCustomer = () => {
    axios
      // .get(`/customer/get?supplierId=${supplierId}&page=${page}&size=${size}`)
      .get(`/community/get-by-supplier?supplierId=${supplierId}&page=${page}&size=${size}&query=${query}`)
      .then((response) => {
        const arrData = response?.data?.data?.map((e, i) => ({
          id:e.id,
          customerName: e.userResponse.name,
          mail: e.userResponse.email,
          phone: e.userResponse.phone,
          status:e.status,
          createdDate: moment(e.createdDate).format('HH:mm:ss DD-MM-YYYY'),
        }));
        setData(arrData);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const approveUsers = (item) => {
    dispatch(approveUser({
        "data":[
            {
                "id":item.id,
                "status":1
            },
        ]
    })).then((res) => {
      message.success("Phê duyệt thành công!")
      getDataCustomer();
    });
  }

  const cancelUsers = (item) => {
    dispatch(approveUser({
      "data":[
          {
              "id":item.id,
              "status":2
          },
      ]
    })).then((res) => {
      message.success("Từ chối không thành công!")
      getDataCustomer();
    });
  }

  const onSearch = () => {
    getDataCustomer()
  };

  // tìm kiếm và tránh render lại nhiều lần
  // let timer = null;
  // const onChangeSearch = (e) => {
  //   debounce(() => setQuery(e.target.value));
  // };
  // function debounce(callbackFunction) {
  //   if (timer) clearTimeout(timer);
  //   timer = setTimeout(() => callbackFunction(), 1000);
  // }

  const onChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const onReloadSearch = () => {
    setQuery('');
    setRenderSreach(!renderSreach);
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: 70,
    },

    {
      title: 'Họ và tên',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Email',
      dataIndex: 'mail',
      key: 'mail',

      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',

      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
          <Tag color={value == 0 ? 'volcano':value == 1?'green':value == 2?'red':'lime'}>{value === 0 ? 'Chờ phê duyệt':value == 1?'Đã tham gia':value == 2?'Từ chối':'Chưa tham gia'}</Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (record) => (
        record.status !== 1 &&
        <Space>
          <Popconfirm
            title="Bạn có chắc chắn muốn phê duyệt không?"
            onConfirm={() => {
              approveUsers(record)
            }}
          >
            <Popover content={<h4>Phê duyệt</h4>}>
              <FileDoneOutlined
                style={{
                  background: '#406FBF',
                  borderRadius: 3,
                  fontSize: '12px',
                  color: 'white',
                  padding: 5,
                  border: '1px solid #406FBF',
                }}
              />
            </Popover>
          </Popconfirm>
          <Popconfirm
            title="Bạn có chắc chắn muốn từ chối không?"  
            onConfirm={() => {
              cancelUsers(record)
            }}
          >
              <Popover  content={<h4>Từ chối</h4>}>
                <CloseCircleOutlined
                  style={{
                    background: 'red',
                    borderRadius: 3,
                    fontSize: '12px',
                    color: 'white',
                    padding: 5,
                    border: '1px solid red',
                  }}
                  // onClick={() => {
                    
                  // }}
                />
              </Popover>
          </Popconfirm>
        </Space>
      ),
    }
    // {
    //   title: 'Thời gian đăng ký tài khoản',
    //   dataIndex: 'createdDate',
    //   key: 'createdDate',
    // },
  ];

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  {' '}
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Danh sách thành viên</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24}>
            <Col span={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach
                      styleInput={{ width: '80%' }}
                      placeholder="Tìm kiếm theo tên hoặc số điện thoại"
                      value={query}
                      onChange={onChangeSearch}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onSearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
            scroll={{ x: 'calc(400px + 50%)' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Customer;
