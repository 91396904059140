import axios from '../services/Api';

export const INSSERVICEVB = 'INSSERVICEVB';
export const DELETESERVICEVB = 'DELETESERVICEVB';
export const ADDUSERTOSERVICE = 'ADDUSERTOSERVICE';

export const addService = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSSERVICEVB,
      data: await axios.post(`/service/save`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const addUsertoService = (serviceId, employeeId, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: ADDUSERTOSERVICE,
      data: await axios.post(
        `employee/add-service?serviceId=${serviceId}&employeeId=${employeeId}`
      ),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const deleteService = (id, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: DELETESERVICEVB,
      data: await axios.post(`/service/delete?id=${id}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};
