import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Modal, notification, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CustomSelect from '../../constant/Select';
import { addUsertoService } from '../../reducers/serviceVbReducer';
import axios from '../../services/Api';

export default function ModalAddUser(props) {
  const dispatch = useDispatch();
  const { show, id, handleCancel } = props;

  const supplierId = localStorage.getItem('supplierId');

  const [employeeId, setEmployeeId] = useState('');
  const [employee, setEmployee] = useState([]);
  const employeeList = employee?.map((e, i) => ({ key: e.id, value: e.id, label: e.fullName }));

  useEffect(() => {
    if (supplierId !== '') {
      getEmployee();
    }
  }, []);

  const getEmployee = () => {
    axios.get(`/employee/get?supplierId=${supplierId}&page=0&size=1000`).then((response) => {
      setEmployee(response?.data?.data);
    });
  };

  const handleCancelAdd = () => {
    handleCancel();
  };

  const onAdd = () => {
    dispatch(
      addUsertoService(id, employeeId, {
        onSuccess: (message) => {
          notification.success({
            message: `${message?.data?.data?.status?.message}`,
          });
          handleCancelAdd();
        },
        onError: (message) => {
          notification.error({
            message: `Error`,
          });
        },
      })
    );
  };

  const onChangeEmployee = (id, data) => {
    setEmployeeId(id.toString());
  };

  return (
    <Modal
      title={`Thêm nhân viên vào dịch vụ`}
      visible={show}
      onCancel={handleCancelAdd}
      onOk={onAdd}
    >
      <CustomSelect
        mode="multiple"
        styles={{ width: '100%' }}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        allowClear
        placeholder="Chọn nhân viên"
        options={employeeList}
        onChange={onChangeEmployee}
      />
    </Modal>
  );
}
