import React, { memo } from 'react';

function Content() {
  console.log('re-render');
  return (
    <div style={{ padding: 20 }}>
      <h1>Memo</h1>
    </div>
  );
}

export default memo(Content);
