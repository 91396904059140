import React from 'react';

import { Column } from '@ant-design/plots';

const ColumnReport = (props) => {
  const data = props?.data?.map((e, i) => ({
    name: e.name,
    total: e.total,
  }));
  const config = {
    data,
    xField: 'name',
    yField: 'total',

    xAxis: {
      label: {
        intervalPadding: 109,
        position: 'middle',
        style: {
          fill: 'black',
          opacity: 0.6,
          fontSize: 10,
          fontWeight: 'bold',
        },
      },
    },

    appendPadding: 10,
    label: {
      style: {
        fill: 'black',
        fontWeight: 'bold',
        opacity: 0.6,
        fontSize: 10,
        position: 'middle',
      },
      layout: [
        {
          type: 'interval-hide-overlap',
        },
      ],
    },
    scrollbar: {
      type: 'horizontal',
      categorySize: 100,
    },
    meta: {
      total: {
        min: 0,
        max: 20,
      },
    },
    columnStyle: {
      radius: [5, 5, 0, 0],
    },
  };

  return <Column {...config} />;
};

export default ColumnReport;
