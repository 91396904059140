import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Breadcrumb, Button, Col, Form, Input, InputNumber, notification, Row, Switch, Upload } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import CustomSelect from '../../constant/Select';
import { addService } from '../../reducers/serviceVbReducer';
import axios from '../../services/Api';

import moment from 'moment';
import { REACT_APP_API_KEY } from '../../constant/data';
import CKEditor5 from '../../components/Ckeditor'
import { handleBeforeUpload } from '../../utils';

const typePrices = [
  // {
  //   key: 0,
  //   value: 0,
  //   label: 'Giá tiền',
  // },
  {
    key: 1,
    value: 1,
    label: 'Giá tiền',
  },
  {
    key: 2,
    value: 2,
    label: 'Khoảng giá',
  },
  {
    key: 3,
    value: 3,
    label: 'Giá liên hệ',
  },
];

const UpdServiceVb = () => {
  const { TextArea } = Input;
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const initState = {
    groupId: null,
    type: null,
  };

  const supplierId = localStorage.getItem('supplierId');
  const id = window.location.href.split('updServiceVb/')[1];

  const [serviceName, setServiceName] = useState({ name: null, grName: null });
  const [serviceStopTime, setServiceStopTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [fieldState, setFieldState] = useState(initState);
  const [grService, setGrService] = useState([]);
  const grServiceList = grService?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const [listImages, setListImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [employee, setEmployee] = useState([]);

  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });

  useEffect(() => {
    if (id !== undefined) {
      getDataById();
    }
  }, []);

  useEffect(() => {
    getDataGrService();
  }, []);

  const getDataGrService = () => {
    axios.get(`/group-service/get?supplierId=${supplierId}&page=0&size=1000`).then((response) => {
      setGrService(response?.data?.data);
    });
  };

  const employeeList = employee?.map((e, i) => ({ key: e.id, value: e.id, label: e.fullName }));

  useEffect(() => {
    if (supplierId !== '') {
      getEmployee();
    }
  }, []);

  const getEmployee = () => {
    axios.get(`/employee/get?supplierId=${supplierId}&page=0&size=1000`).then((response) => {
      setEmployee(response?.data?.data);
    });
  };

  const getDataById = () => {
    axios.get(`/service/id?id=${id}`).then((response) => {
      console.log(response);
      setServiceName({
        name: response?.data?.data?.name,
        grName: response?.data?.data?.groupName,
      });
      form.setFieldsValue({
        ...response?.data?.data,
        groupId: response?.data?.data?.groupServiceId,
        employeeIds:response?.data?.data?.employees?.map(item=>item.id)
      });
      let serviceStop = moment(response?.data?.data?.serviceStopTime).format('DD-MM-YYYY kk:mm:ss');
      setServiceStopTime(serviceStop);
      setStartTime(response?.data?.data?.startTime);
      setEndTime(response?.data?.data?.endTime);
      setListImages(response?.data?.data?.image);
      setFieldState({
        ...fieldState,
        price: response?.data?.data?.price,
        minPrice: response?.data?.data?.minPrice,
        maxPrice: response?.data?.data?.maxPrice,
        type: response?.data?.data?.type,
        groupId: response?.data?.data?.groupServiceId,
      });
      let imgList = [];
      const list = response?.data?.data?.image;
      list?.map((img) => {
        const arr = {
          uid: img,
          name: img,
          status: 'done',
          url: `${REACT_APP_API_KEY}/auth/test-public/view?fileName=${img}`,
        };
        imgList.push(arr);
      });
      setFileList(imgList);
    });
  };

  const onUpd = (values) => {
    if (startTime === '' || endTime === '') {
      notification.error({
        message: `Yêu cầu chọn thời gian`,
      });
    } else {
      let st1 = startTime.split(':')[0];
      let st2 = parseInt(st1) * 60;
      let st3 = startTime.split(':')[1];
      let startTimeUpd = parseInt(st2) + parseInt(st3);

      let en1 = endTime.split(':')[0];
      let en2 = parseInt(en1) * 60;
      let en3 = endTime.split(':')[1];
      let endTimeUpd = parseInt(en2) + parseInt(en3);

      const body = {
        id: id,
        name: values?.name,
        status: values?.status,
        price: values?.price,
        maxPrice: values?.maxPrice,
        minPrice: values?.minPrice,
        discount: values?.discount,
        serviceTime: values?.serviceTime,
        serviceStopTime: (serviceStopTime == "Invalid date") ? null : serviceStopTime,
        endTime: endTimeUpd,
        startTime: startTimeUpd,
        description: values?.description,
        groupId: fieldState?.groupId,
        type: fieldState?.type,
        supplierId: supplierId,
        serviceType:values.serviceType ? 1 : 0,
        employeeIds:values?.employeeIds,
        image:!listImages ? null :
          listImages.length > 0
            ? listImages
            : [
                '/root/vbnow/product_images/member-avatars/c85fc9ec-bc3c-45d4-8d98-f40dc9b27cd2.jpg',
              ],
      };
      dispatch(
        addService(body, {
          onSuccess: (message) => {
            notification.success({
              message: `Sửa dịch vụ thành công`,
            });
            history.push('/serviceVb');
          },
          onError: (message) => {
            notification.error({
              message: `Sửa thất bại`,
            });
          },
        })
      );
    }
  };

  const onChangeSelect = (ev, keyName) => {
    setFieldState((prev) => ({
      ...prev,
      [keyName]: ev,
    }));
    form.setFieldsValue({ [keyName]: ev });
  };

  const handleChangeImage = (info) => {
    setFileList(info?.fileList);
    let fileList = [...info.fileList];
    const urlList = fileList.map((file) => {
      return file.response?.data;
    });
    
    setListImages([urlList?.length ? urlList[urlList?.length - 1] : null]);
  };

  const onPreview = async (file) => {
    const check = file.response;
    if (check === undefined) {
      window.open(file.url, '_blank');
    } else {
      const link = file?.response?.image;
      window.open(`${REACT_APP_API_KEY}/auth/test-public/upload-file${link}`, '_blank');
    }
  };

  const onChangeEmployee = (id, data) => {
    form.setFieldValue('employeeIds',id)
    // form.setFieldsValue({
    //   ...form.getFieldValue()
    // })
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/serviceVb'}>
                {' '}
                Danh sách dịch vụ
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Sửa dịch vụ</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ marginTop: 10 }}>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={22} lg={18} xl={18} xxl={18}>
          <Form
            form={form}
            id="updServiceVb"
            onFinish={onUpd}
            autoComplete="off"
            labelCol={{ xs: 24, sm: 11, md: 8, lg: 6, xl: 6, xxl: 4 }}
            wrapperCol={{ xs: 24, sm: 13, md: 16, lg: 18, xl: 18, xxl: 20 }}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Banner" style={{ fontWeight: '500' }}>
                  <Upload
                    multiple
                    action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                    headers={JWT_HEADER}
                    method="POST"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleChangeImage}
                    onPreview={onPreview}
                    beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                  >
                    {fileList.length < 5 && '+ Upload'}
                  </Upload>
                </Form.Item>

                {
                  !!id &&
                  <Form.Item
                    style={{ fontWeight: "500" }}
                    label="Trạng thái"
                    name="status"
                    rules={[{ required: true }]}
                  >
                    <Switch
                    />
                  </Form.Item>
                }

                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Thời gian dịch vụ"
                  name="serviceTime"
                  rules={[{ required: false, message: 'Nhập thời gian dịch vụ' }]}
                >
                  <Input placeholder="Thời gian dịch vụ" suffix="phút" />
                </Form.Item>
                <Form.Item
                  label="Nhóm dịch vụ"
                  style={{ fontWeight: '500' }}
                  name="groupId"
                  rules={[{ required: true, message: 'Chọn nhóm dịch vụ' }]}
                >
                  <div className="selectServiceVb">
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={
                        serviceName?.grName !== null ? serviceName.grName : 'Chọn nhóm dịch vụ'
                      }
                      value={fieldState.groupId}
                      options={grServiceList}
                      keyName="groupId"
                      onChange={onChangeSelect}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Tên dịch vụ"
                  name="name"
                  rules={[{ required: true, message: 'Nhập tên dịch vụ' }]}
                >
                  <TextArea autoSize placeholder="Tên dịch vụ" />
                </Form.Item>

                <Form.Item
                  label="Nhân viên phục vụ"
                  style={{ fontWeight: '500' }}
                  name="employeeIds"
                  rules={[{ required: false,}]}
                >
                  <CustomSelect
                    mode="multiple"
                    styles={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder="Chọn nhân viên"
                    options={employeeList}
                    keyName="employeeIds"
                    onChange={onChangeEmployee}
                  />
                </Form.Item>

                <Form.Item
                  label="Private"
                  style={{ fontWeight: '500' }}
                  name="serviceType"
                  rules={[{ required: true,}]}
                >
                  <Switch />
                </Form.Item>


                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Loại giá"
                  name="type"
                  rules={[{ required: true, message: 'Chọn loại giá' }]}
                >
                  <div className="selectServiceVb">
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Chọn loại giá dịch vụ"
                      value={fieldState.type}
                      options={typePrices}
                      keyName="type"
                      onChange={onChangeSelect}
                    />
                  </div>
                </Form.Item>

                {fieldState.type != 2 && fieldState.type != 3 && (
                  <Form.Item
                    style={{ fontWeight: '500' }}
                    label="Giá dịch vụ"
                    name="price"
                    rules={[{ required: true, message: 'Nhập giá dịch vụ' }]}
                  >
                    <InputNumber
                        placeholder="Giá dịch vụ"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        style={{width:'100%'}}
                        suffix="VND"
                    />
                  </Form.Item>
                )}
                {fieldState.type != 2 && fieldState.type != 3 && (
                  <Form.Item
                    style={{ fontWeight: '500' }}
                    label="Giá giảm"
                    name="discount"
                    rules={[{ required: false, message: 'Nhập giá dịch vụ' }]}
                  >
                    <InputNumber
                      placeholder="Giá dịch vụ"
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      style={{width:'100%'}}
                      suffix="VND"
                    />
                  </Form.Item>
                )}
                {(fieldState.type == 2 || fieldState.type == 3) && (
                  <div>
                    <Form.Item
                      style={{ fontWeight: '500' }}
                      label="Giá dịch vụ tối thiểu"
                      name="minPrice"
                      rules={[{ required: fieldState.type == 2 ? true : false, message: 'Nhập giá dịch vụ' }]}
                    >
                      <InputNumber
                        placeholder="Giá dịch vụ"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        style={{width:'100%'}}
                        suffix="VND"
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ fontWeight: '500' }}
                      label="Giá dịch vụ tối đa"
                      name="maxPrice"
                      rules={[{ required: fieldState.type == 2 ? true : false, message: 'Nhập giá dịch vụ' }]}
                    >
                      <InputNumber
                        placeholder="Giá dịch vụ"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        style={{width:'100%'}}
                        suffix="VND"
                      />
                    </Form.Item>
                  </div>
                )}

                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Mô tả"
                  name="description"
                  rules={[{ required: false, message: 'Nhập mô tả' }]}
                >
                  <CKEditor5
                  required={true}
                  title={"Nội dung trả lời"}
                  uniqueKey={"qnaAswers"}
                  name="qnaAswers"
                  link={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                  linkViewFile={`${REACT_APP_API_KEY}/auth/test-public/view?fileName=`}
                  textIndent={false}
                />
                </Form.Item>
                
              </Col>
              <Col span={24}>
                <Button
                  style={{
                    marginLeft: '6px',
                    backgroundColor: 'rgb(55, 181, 157)',
                    border: 'rgb(55, 181, 157)',
                    float: 'right',
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Xác nhận
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={1} lg={4} xl={4} xxl={4} />
      </Row>
    </>
  );
};
export default UpdServiceVb;
