import React from 'react';

import { Input } from 'antd';
export default function CustomSreach({ onChange, value, placeholder, styleInput }) {
  return (
    <>
      <Input style={styleInput} placeholder={placeholder} onChange={onChange} value={value} />
    </>
  );
}
