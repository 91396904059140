import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { ScheduleOutlined, TableOutlined } from '@ant-design/icons';

import formatMoney from '../../components/ConvertMoney';
import CustomSelect from '../../constant/Select';
import BookingSelect from '../../constant/selectBooking';
import { addBooking } from '../../reducers/bookingReducer';
import axios from '../../services/Api';

import './Booking.css';

import moment from 'moment';
import { getInfoUser } from '../../reducers/authReducer';
import { removeHtml } from '../../utils';

const InsBooking = () => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useHistory();
  const [formCustomer] = Form.useForm();
  const [formBooking] = Form.useForm();
  const [form] = Form.useForm();
  const dataUser = useSelector((store) => store.authReducer.infoUser);

  const supplierId = localStorage.getItem('supplierId');

  const [userId, setUserId] = useState(0);
  const [totalmoney, setTotalmoney] = useState([0]);
  const [money, setMoney] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [service, setService] = useState([]);
  const serviceList = service?.map((e, i) => ({
    key: e.id,
    value: e.id,
    label: e.name,
    price: e.price,
    description: e.description,
  }));
  const [employee, setEmployee] = useState([]);
  const employeeList = employee?.map((e, i) => ({ key: e.id, value: e.id, label: e.fullName }));
  const [bookingDetails, setBookingDetails] = useState([]);

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const columns = [
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Giá dịch vụ',
      dataIndex: 'price',
      key: 'price',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '22%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={removeHtml(value)}>
          { removeHtml(value)}
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian',
      key: 'time',
      width: '22%',
      ellipsis: {
        showTitle: false,
      },
      render: (t, r, i) => (
        <div gutter={24}>
          <DatePicker
            showTime={{
              format: 'HH:mm',
            }}
            format="DD-MM-YYYY HH:mm:ss"
            placeholder="Chọn thời gian"
            onChange={onChangeTime(i)}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
          />
        </div>
      ),
    },
    {
      title: 'Nhân viên',
      key: 'nv',
      width: '22%',
      ellipsis: {
        showTitle: false,
      },
      render: (t, r, i) => (
        <div className="selectCustomer">
          <CustomSelect
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            placeholder="Chọn nhân viên"
            options={employeeList}
            onChange={onChangeEmployee(i)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (supplierId !== '') {
      getDataService();
      getEmployee();
    }
  }, []);

  const getDataService = () => {
    axios.get(`/service/get/supplier?supplier=${supplierId}&page=0&size=1000`).then((response) => {
      setService(response?.data?.data);
    });
  };

  const getEmployee = () => {
    axios.get(`/employee/get?supplierId=${supplierId}&page=0&size=1000`).then((response) => {
      setEmployee(response?.data?.data);
    });
  };

  const onIns = (value) => {
    if (
      bookingDetails?.map((e) => e.intendTime)[0] !== undefined &&
      bookingDetails?.map((e) => e.intendTime)[0] !== null &&
      bookingDetails?.map((e) => e.intendTime)[0] !== ''
    ) {
      const body = {
        id: 0,
        totalMoney: money,
        paidMoney: 0,
        discountPercent: 0,
        discountMoney: 0,
        customerName: value?.customerName,
        phone: value?.phone,
        mail: value?.mail,
        innerNote: value?.innerNote !== null ? value?.innerNote : '',
        supplierId: supplierId,
        bookingDetails: bookingDetails,
      };
      dispatch(
        addBooking(userId, body, {
          onSuccess: (res) => {

            if(res?.data?.data?.status?.statusCode == 200){
              notification.success({
                message: `Thêm booking thành công`,
              });
              handleCancelIns();
              history.push('/booking');
            }
            else{
              notification.error({
                message: !!res?.data?.data?.status?.message ? res?.data?.data?.status?.message : `Thêm booking thất bại`,
              });
            }
          },
          onError: () => {
            notification.error({
              message: `Thêm booking thất bại`,
            });
          },
        })
      );
    } else {
      notification.error({
        message: `Chọn thời gian dịch vụ`,
      });
    }
  };

  const handleCancelIns = () => {
    form.setFieldsValue({
      customerName: '',
      phone: '',
      mail: '',
      innerNote: '',
    });
    setBookingDetails([]);
  };

  const onChangeTime = (i) => (date, dateString) => {
    let newArr = [...bookingDetails];
    newArr[i].intendTime = dateString;
    setBookingDetails(newArr);
  };

  const time = new Date();
  const hours = moment(time).format('HH');
  const minute = moment(time).format('mm');

  const disabledTime = (current) => {
    if (current < new Date()) {
      return {
        disabledHours: () => range(0, hours),
        disabledMinutes: () => range(0, minute),
      };
    }
  };

  const disabledDate = (current) => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const date = new Date(today);
    return current && current < date;
  };

  useEffect(() => {
    const money = sumArray(totalmoney);
    setMoney(money);
  }, [totalmoney]);

  function sumArray(totalmoney) {
    let sum = 0;
    totalmoney.map(function (value) {
      sum += value;
    });
    return sum;
  }

  const onChangeSelect = (id, data) => {
    const arrData = data?.map((e, i) => ({
      id: e.value,
      name: e.name,
      price: e.price,
      description: e.description,
      intendTime: '',
      employeeId: 0,
      serviceUserId: e.value,
    }));
    setDataTable(arrData);
    const arrDataDetails = data?.map((e, i) => ({
      quantity: 1,
      price: e.price,
      intendTime: '',
      employeeId: 0,
      serviceUserId: e.value,
    }));
    setTotalmoney(data?.map((e) => e?.price));
    setBookingDetails(arrDataDetails);
  };

  const onChangeEmployee = (i) => (id, data) => {
    let newArr = [...bookingDetails];
    newArr[i].employeeId = data?.value;
    setBookingDetails(newArr);
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/booking'}>
                {' '}
                <TableOutlined /> Danh sách Booking
              </Link>
            </b>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/calendarBooking'}>
                Lịch Booking
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Thêm Booking</b>
          </div>
        </Breadcrumb>
      </Row>
      <br />

      <Form
        form={formCustomer}
        onFinish={onIns}
        labelCol={{ xs: 6, sm: 6, md: 6, lg: 8, xl: 6, xxl: 6 }}
        wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}
      >
        <Row span={24} style={{ borderTop: '1px solid #d9d9d9' }}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={10}
            xl={10}
            xxl={9}
            style={{
              borderRight: '1px solid #d9d9d9',
              paddingTop: '10px',
              paddingRight: '10px',
            }}
          >
            <Form.Item>
              <h1 style={{ fontWeight: 'bold' }}>Thông tin Thành viên</h1>
            </Form.Item>
            <Row span={24}>
              <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
                {/* <Form.Item label="Thành viên" style={{ fontWeight: "bold" }} >
                  <div className='selectCustomer' >
                    <BookingSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Chọn Thành viên"
                    // value={fieldState.province}
                    // options={provinceList}
                    // onChange={onChangeSelect('province')}
                    />
                  </div>
                </Form.Item> */}

                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Họ tên"
                  name="customerName"
                  rules={[{ required: true, message: 'Nhập tên Thành viên' }]}
                >
                  <Input placeholder="Tên Thành viên" />
                </Form.Item>

                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Điện thoại"
                  name="phone"
                  rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                >
                  <Input placeholder="Số điện thoại" 
                    maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                   />
                </Form.Item>

                <Form.Item style={{ fontWeight: '500' }} label="Email" name="mail">
                  <Input placeholder="Nhập Email" />
                </Form.Item>

                <Form.Item style={{ fontWeight: '500' }} label="Ghi chú" name="innerNote">
                  <TextArea autoSize placeholder="Ghi chú" />
                </Form.Item>
              </Col>

              <Col span={24} style={{ paddingTop: '10px' }}>
                <Form.Item style={{ fontWeight: '500' }} label="Tổng tiền">
                  <Input
                    value={FormatMoney(money) + 'VND'}
                    disabled
                    style={{ fontWeight: 'bold', color: '#0693E3' }}
                    placeholder="Tổng tiền"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={14}
            xl={14}
            xxl={15}
            style={{ paddingLeft: '10px', paddingTop: '10px' }}
          >
            <Form form={formBooking} autoComplete="off" hideRequiredMark>
              <Form.Item>
                <h1 style={{ fontWeight: 'bold' }}>Thông tin dịch vụ</h1>
              </Form.Item>

              <Form.Item style={{ fontWeight: 'bold' }}>
                <div className="selectCustomer">
                  <BookingSelect
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder="Chọn dịch vụ"
                    options={serviceList}
                    onChange={onChangeSelect}
                  />
                </div>
              </Form.Item>

              <Form.Item>
                <Table columns={columns} dataSource={dataTable} size="small" pagination={false} />
              </Form.Item>
            </Form>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button
                style={{
                  marginLeft: '6px',
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                }}
                type="primary"
                htmlType="submit"
              >
                Xác nhận
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default InsBooking;
