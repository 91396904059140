import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  notification,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
  PrinterOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import formatMoney from '../../components/ConvertMoney';
import Filter from '../../constant/Filter';
import FilterTime from '../../constant/FilterTime';
import CustomSreach from '../../constant/Sreach';
import axios from '../../services/Api';

import dayjs from 'dayjs';
import moment from 'moment';
import Invoice from './Invoice';

const Bill = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const dateFormat = 'DD-MM-YYYY';
  const supplierId = localStorage.getItem('supplierId');
  
  const [showUpd, setShowUpd] = useState({ show: false, id: null });
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalPage, setTotalPage] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  useEffect(() => {
    const today = new Date();
    const todayDate = new Date().toLocaleDateString('sv-SE');
    today.setDate(today.getDate() - 7);
    const date = new Date(today).toLocaleDateString('sv-SE');
    setStartDate(moment(date).format('DD-MM-YYYY'));
    setEndDate(moment(todayDate).format('DD-MM-YYYY'));
  }, []);

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      getDataBill();
    }
  }, [startDate, endDate, renderSreach]);

  const getDataBill = () => {
    axios
      .get(
        `/bill/get?supplierId=${supplierId}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}`
      )
      .then((response) => {
        setDataTable(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const onPrint = async () => {
    notification.open({
      message: `Chức năng In hóa đơn`,
    });
  };

  const onInsBill = async () => {
    notification.open({
      message: `Chức năng Thêm hóa đơn`,
    });
  };

  const onChangeTimeStart = (e, date) => {
    if (date !== '') {
      setStartDate(date + ' ' + '00:00:00');
    } else {
      const today = new Date();
      today.setDate(today.getDate() - 7);
      const date = new Date(today).toLocaleDateString('sv-SE');
      setStartDate(moment(date).format('DD-MM-YYYY') + ' ' + '00:00:00');
      // setStartDateView(moment(date).format("DD-MM-YYYY"))
    }
  };

  const onChangeTimeEnd = (e, date) => {
    if (date !== '') {
      setEndDate(date + ' ' + '23:59:59');
    } else {
      const today = new Date();
      const todayDate = new Date().toLocaleDateString('sv-SE');
      today.setDate(today.getDate() - 7);
      const date = new Date(today).toLocaleDateString('sv-SE');
      setEndDate(moment(todayDate).format('DD-MM-YYYY') + ' ' + '23:59:59');
      // setEndDateView(moment(todayDate).format("DD-MM-YYYY"))
    }
  };

  const onChangeRowSelectedData = (key, rows) => {
    setSelectedRows(rows);
    setSelectedKeys(key);
  };

  const onSearch = () => {
    if (query !== '') {
      if (startDate !== '' && endDate !== '') {
        const body = {
          query: query,
          supplierId: supplierId,
          startDate: startDate,
          endDate: endDate,
        };
        axios.post(`/bill/search`, body).then((response) => {
          setDataTable(response?.data?.data);
        });
      }
    } else {
      setRenderSreach(!renderSreach);
    }
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const onShowUpd = (record) => {
    history.push('/updBill'.concat(`/${record?.id}`), { id: record?.id });
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const onReloadSearch = () => {
    setQuery('');
    const today = new Date();
    const todayDate = new Date().toLocaleDateString('sv-SE');
    today.setDate(today.getDate() - 7);
    const date = new Date(today).toLocaleDateString('sv-SE');
    setStartDate(moment(date).format('DD-MM-YYYY'));
    setEndDate(moment(todayDate).format('DD-MM-YYYY'));
    setRenderSreach(!renderSreach);
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onCancelShowUpd = () => {
    setShowUpd({
      show: false,
      id: null,
    });
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: '5%',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '13%',
      ellipsis: {
        showTitle: false,
      },
      responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
      render: (value) => (
        <Tooltip placement="topLeft" title={moment(value).format('DD/MM/YYYY - HH:mm:ss')}>
          <span> {moment(value).format('DD/MM/YYYY - HH:mm:ss')}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Mã hóa đơn',
      dataIndex: 'bookingCode',
      key: 'bookingCode',
      width: '12%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'totalMoney',
      key: 'totalMoney',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Thành viên',
      dataIndex: 'customerName',
      key: 'customerName',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'SĐT Thành viên',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: '15%',
      fixed: 'right',
      render: (record) => (
        <Space>
          <Popover content={<h4> Thông tin hóa đơn</h4>}>
            <EditOutlined
              style={{
                background: 'rgb(55, 181, 157)',
                borderRadius: 3,
                fontSize: '12px',
                color: 'white',
                padding: 5,
                border: '1px solid rgb(55, 181, 157)',
              }}
              onClick={() => onShowUpd(record)}
            />
          </Popover>

          <Popover content={<h4>In hóa đơn</h4>}>
            <PrinterOutlined
              style={{
                background: '#4091BF',
                borderRadius: 3,
                fontSize: '12px',
                color: 'white',
                padding: 5,
                border: '1px solid #4091BF',
              }}
              onClick={() => {
                // setShowUpd(record)
                setShowUpd({
                  show: true,
                  id: record?.id,
                });
              }}
            />
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Danh sách hóa đơn</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24}>
            <Col span={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
            <Col span={12}>
              <Button
                style={{
                  marginLeft: '6px',
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                }}
                type="primary"
                onClick={onInsBill}
              >
                <PlusOutlined />
                Thêm
              </Button>
              {/* <Button
                style={{
                  marginLeft: '6px',
                  backgroundColor: '#4091BF',
                  border: '#4091BF',
                  float: 'right',
                }}
                type="primary"
                onClick={onPrint}
              >
                <PrinterOutlined />
                In
              </Button> */}
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <FilterTime
                      valueStart={dayjs(startDate, dateFormat)}
                      valueEnd={dayjs(endDate, dateFormat)}
                      format={dateFormat}
                      onChangeTimeStart={onChangeTimeStart}
                      onChangeTimeEnd={onChangeTimeEnd}
                      styleRangePicker={{ width: '60%' }}
                    />
                  </Col>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach
                      styleInput={{ width: '80%' }}
                      value={query}
                      placeholder="Tìm kiếm"
                      onChange={onChangeQuery}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onSearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            rowKey={(record) => record.id}
            rowSelection={{
              selectedRows: selectedRows,
              selectedRowKeys: selectedKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                onChangeRowSelectedData(selectedRowKeys, selectedRows);
              },
            }}
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
            scroll={{ x: 'calc(350px + 50%)' }}
          />
        </Col>
      </Row>
      
      {showUpd.show && <Invoice show={showUpd.show} id={showUpd.id} handleCancel={onCancelShowUpd} />}
    </>
  );
};

export default Bill;
