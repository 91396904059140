import axios from '../services/Api';

export const INSSERTBLOG = 'INSSERTBLOG';
export const DELETEBLOG = 'DELETEBLOG';

export const addBlog = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSSERTBLOG,
      data: await axios.post(`/api-news/save-supplier`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const deleteBLog = (id, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: DELETEBLOG,
      data: await axios.post(`/blog/delete?id=${id}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const getBlog = (id, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: DELETEBLOG,
      data: await axios.get(`/api-news/supplier-get-detail?id=${id}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};