import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Form, Input, notification } from 'antd';

import { addBlog } from '../../reducers/blogReducer';
import axios from '../../services/Api';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { REACT_APP_API_KEY } from '../../constant/data';

export default function UpdBlog(props) {
  const location = useLocation();
  const { id } = props;
  // const id = location.state.id;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const supplierId = localStorage.getItem('supplierId');

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (id !== undefined) {
      getDataServiceById();
    }
  }, [id]);

  const getDataServiceById = () => {
    axios.get(`/blog/get/id?id=${id}`).then((response) => {
      form.setFieldsValue(response?.data?.data);
      setTitle(response?.data?.data?.title);
      setContent(response?.data?.data?.content);
    });
  };

  const onUpd = () => {
    const body = {
      id: id,
      title: title,
      content: content,
      status: true,
      supplierId: supplierId,
    };
    dispatch(
      addBlog(body, {
        onSuccess: (message) => {
          notification.success({
            // message: `${message?.data?.data?.message}`,
            message: `Sửa bài viết thành công `,
          });
          handleCancelIns();
        },
        onError: (message) => {
          notification.error({
            // message: `${message?.data?.message}`,
            message: `Sửa bài viết thất bại`,
          });
        },
      })
    );
  };

  const handleCancelIns = () => {
    history.push('/blog');
  };

  const API_URL = `${REACT_APP_API_KEY}`;
  const UPLOAD_ENDPOINT = `auth/test-public/upload-file`;

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append('file', file);
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: 'post',
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${API_URL}${res.image}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <Form>
      <Form.Item>
        <TextArea
          autoSize
          placeholder="Tiêu đề"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          value={title}
        />
      </Form.Item>

      <Form.Item>
        <CKEditor
          config={{
            extraPlugins: [uploadPlugin],
          }}
          editor={ClassicEditor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
          onReady={(editor) => {}}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />
      </Form.Item>

      <Form.Item>
        <Button
          style={{
            marginLeft: '6px',
            backgroundColor: 'rgb(55, 181, 157)',
            borderRadius: 5,
            border: 'rgb(55, 181, 157)',
            float: 'right',
          }}
          type="primary"
          htmlType="submit"
          onClick={onUpd}
        >
          Xác nhận
        </Button>
      </Form.Item>
    </Form>
  );
}
