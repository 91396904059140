import React, { useEffect, useState } from 'react';

import { SearchOutlined,ReloadOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Popover, Row, Space, Table, Tooltip } from 'antd';
import axios from '../../../services/Api';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { REACT_APP_API_KEY } from '../../../constant/data';
import CustomSreach from '../../../constant/Sreach';

const SubSupplier = () => {
  const dispatch = useDispatch();
  const supplierId = localStorage.getItem('supplierId');

  const [showIns, setShowIns] = useState({ show: false });
  const [showUpd, setShowUpd] = useState({ show: false, id: null });
  const [totalPage, setTotalPage] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [renderDel, setRenderDel] = useState(false);
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);

  useEffect(() => {
    if (supplierId !== '') {
      getDataGroupService();
    }
    setRenderDel(false);
  }, [showIns.show, showUpd.show, supplierId, page, size, renderDel === true, renderSreach]);

  const getDataGroupService = () => {
    axios
      .get(
        `${REACT_APP_API_KEY}/supplier/get-child?supplierId=${supplierId}&page=${page}&size=${size}`
      )
      .then((response) => {
        setDataTable(response.data.data?.data?.map(item=>({...item,diachi:`${item?.province}, ${item?.district}, ${item?.ward}, ${item?.address}`})));
        setTotalPage(response.data.data?.totalSize);
      });
  };

  const onsearch = () => {
    // if (query !== '') {
    //   axios.get(`/supplier/get-child?supplierId=1${query}`).then((response) => {
    //     setDataTable(response?.data?.data);
    //   });
    // } else {
    //   setRenderSreach(!renderSreach);
    // }
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: '50px',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Tên Cộng đồng ',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Số điện thoại ',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian mở cửa',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian đóng cửa',
      dataIndex: 'endDate',
      key: 'endDate',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diachi',
      key: 'diachi',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Mô tả ',
      dataIndex: 'description',
      key: 'description',
      width: '35%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    // {
    //   title: 'Chức năng',
    //   key: 'action',
    //   width: '100px',
    //   fixed: 'right',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (record) => (
    //     <Space>
    //       <Popover content={<h4> Sửa thông tin</h4>}>
    //         <Link
    //           style={{ color: '#808080' }}
    //           to={`/child-supplier/${supplierId}/update/${record.id}`}
    //         >
    //           <EditOutlined
    //             style={{
    //               background: 'rgb(55, 181, 157)',
    //               borderRadius: 3,
    //               fontSize: '12px',
    //               color: 'white',
    //               padding: 5,
    //               border: '1px solid rgb(55, 181, 157)',
    //             }}
    //           />
    //         </Link>
    //       </Popover>
    //     </Space>
    //   ),
    // },
  ];

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const onReloadSearch = () => {
    setQuery('');
    setRenderSreach(!renderSreach);
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Danh sách Chi nhánh</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9', paddingBottom: '10px' }}>
          <Row span={24}>
            <Col span={12}>{/* <Filter onClickFilter={onClickFilter} /> */}</Col>
            <Col span={12}>
              <Link to={`child-supplier/${supplierId}/insert`}>
                <Button
                  style={{
                    backgroundColor: 'rgb(55, 181, 157)',
                    border: 'rgb(55, 181, 157)',
                    float: 'right',
                  }}
                  type="primary"
                >
                  <PlusOutlined />
                  Thêm
                </Button>
              </Link>
              ,
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach
                      styleInput={{ width: '80%' }}
                      placeholder="Tìm kiếm"
                      value={query}
                      onChange={onChangeQuery}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onsearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default SubSupplier;
