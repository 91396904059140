import { useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  notification,
  Row,
  Select,
  Switch,
  TimePicker,
  Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';

import CustomSelect from '../../constant/Select';
import { addSupplier } from '../../reducers/supplierReducer';
import axios from '../../services/Api';

import GoogleMaps from '../../components/GoogleMap';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../constant/data';
import './Supplier.css';
import { checkSuppliers } from '../../reducers/authReducer';
import moment from 'moment';
import { handleBeforeUpload } from '../../utils';
import dayjs from 'dayjs';

const Supplier = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAtQwrcO3SLM-5PmFgHLFd31FE-x_o3cg8', // Add your API key
  });
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const initState = {
    district: null,
    province: null,
    ward: null,
  };
  const [fieldState, setFieldState] = useState(initState);
  const [groupId, setGroupId] = useState([]);
  const [groupIdTemp, setGroupIdTemp] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [provinceId, setProvinceId] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [wardId, setWardId] = useState('');
  const [id, setId] = useState(0);
  const provinceList = provinces?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const districtList = districts?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const wardList = wards?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const [images, setImages] = useState('');
  const [banner, setBanner] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [endTime, setEndTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [markerDefaults, setMarkerDefaults] = useState();
  const [supId, setSupId] = useState('');
  const [vCoin, setVCoin] = useState(0);
  const [grService, setGrService] = useState([]);
  const grServiceList = grService?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const closeShop = Form.useWatch('closeShop', form);

  const getDataGrService = () => {
    axios.get(`/group-service/get?page=0&size=1000`).then((response) => {
      setGrService(response?.data?.data);
    });
  };

  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });

  const handleChangeLogo = (info) => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    if (status === 'done') {
      const urlList = fileList.map((file) => {
        return file.response?.data;
      });
      setImages(urlList[0] || '');
      console.log(urlList)
    } else if (status === 'error') {
    }
  };

  useEffect(() => {
    getDataGrService();
    getProvince();
    getSupplier();
  }, []);

  useEffect(() => {
    getVCoin();
  }, [supId]);
  const dateFormat = 'YYYY/MM/DD';

  const getSupplier = () => {
    axios.get(`/supplier/info`).then((response) => {
      if (response.data.data.length > 0) {
        const data = response.data.data[0];
        const latLongs = data.latLong?.split(' - ');
        if (!!latLongs) {
          setMarkerDefaults({ lat: Number(latLongs[0]), lng: Number(latLongs[1]) });
        }
        setId(data.id);
        // setGroupServices(data?.groupService)
        setGroupId(data?.groupService)
        setGroupIdTemp(data?.groupService)
        setStartTime(data?.startDate);
        setEndTime(data?.endDate);
        form.setFieldsValue({...data,groupId:data?.groupService,
          timeCloseShop: [dayjs( moment(data?.closeTimeS || new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD'), dateFormat), dayjs(moment(data?.closeTimeE || new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD'), dateFormat)]
        });
        setFieldState((prev) => ({
          ...prev,
          province: data.province,
          district: data.district,
          ward: data.ward,
          // groupId:data?.groupService
        }));
        setImages(data.image === null ? images : data?.image);
        setBanner(data?.banner === null ? banner : data?.banner[0]);
        if (data.id === null) {
          localStorage.setItem('supplierId', -1);
        } else {
          localStorage.setItem('supplierId', data.id);
          setSupId(data.id);
        }
      }
    });
  };

  const getVCoin = () => {
    axios.get(`supplier/get-coin?supplierId=${supId}`).then((response) => {
      setVCoin(response?.data?.data?.vcoin);
    });
  };

  const getProvince = () => {
    axios.get(`/address/province?page=0&size=500`).then((response) => {
      setProvinces(response?.data?.data);
    });
  };

  const getDistrict = (provinceId) => {
    axios.get(`/address/district?page=0&size=500&provinceId=${provinceId}`).then((response) => {
      setDistricts(response?.data?.data);
    });
  };

  const getWard = (districtId) => {
    axios.get(`/address/ward?page=0&size=500&districtId=${districtId}`).then((response) => {
      setWards(response?.data?.data);
    });
  };

  const onChangeSelect = (type) => (ev) => {
    if (type === 'province') {
      getDistrict(ev);
      setProvinceId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: provinceList?.find((e) => e.key === ev)?.label,
        district: null,
        ward: null,
      }));
    }
    if (type === 'district') {
      getWard(ev);
      setDistrictId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: districtList?.find((e) => e.key === ev)?.label,
        ward: null,
      }));
    }
    if (type === 'ward') {
      setWardId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: wardList?.find((e) => e.key === ev)?.label,
      }));
    }
    if (type === 'groupId') {
      setGroupId(ev)
    }
  };

  const onSave = (value) => {
    let closeTimeS = null
    let closeTimeE = null
    if(value.closeShop){
      closeTimeS = value.timeCloseShop[0]
      closeTimeE = value.timeCloseShop[1]
    }

    if (closeTimeS && moment(closeTimeS).isValid()) {
      closeTimeS = new Date(closeTimeS)
      closeTimeS = moment(closeTimeS).format("DD-MM-YYYY 00:00");
    }
  
    if (closeTimeE && moment(closeTimeE).isValid()) {
      closeTimeE = new Date(closeTimeE)
      closeTimeE = moment(closeTimeE).format("DD-MM-YYYY 23:59");
    }

    if (!markerDefaults) {
      notification.error({
        message: 'Bạn cần chọn vị trí trước khi lưu thông tin!',
      });
      return;
    }
    let st1 = startTime.split(':')[0];
    let st2 = parseInt(st1) * 60;
    let st3 = startTime.split(':')[1];
    let startTimeUpd = parseInt(st2) + parseInt(st3);

    let en1 = endTime.split(':')[0];
    let en2 = parseInt(en1) * 60;
    let en3 = endTime.split(':')[1];
    let endTimeUpd = parseInt(en2) + parseInt(en3);
    const body = {
      id: id,
      name: value.name,
      address: value.address,
      phone: value.phone,
      addressWebsite:value.addressWebsite,
      description: value.description,
      provinceId: provinceId,
      districtId: districtId,
      wardId: wardId,
      image: images,
      banner: [banner],
      latLong: `${markerDefaults?.lat} - ${markerDefaults?.lng}`,
      startTime: startTimeUpd,
      endTime: endTimeUpd,
      groupService:groupId,
      supplierType:value.supplierType ? 1 : 0,
      closeShop:value.closeShop,
      closeTimeS,
      closeTimeE
    };
    dispatch(
      addSupplier(body, {
        onSuccess: (res) => {
          if(res?.data?.status == 200 && res?.data?.data?.status?.statusCode == 200){
            notification.success({
              message: `Lưu thông tin thành công`,
            });
          }
          else{
            notification.error({
              message:res?.data?.data?.status?.message || res?.data?.data?.message || `Lưu thông tin thất bại`,
            });
          }
          dispatch(checkSuppliers());
        },
        onError: (er) => {
          if (er.data.message === '') {
            notification.error({
              message: `Lưu thông tin thất bại`,
            });
          } else {
            notification.error({
              message: `${er?.data?.status?.message}`,
            });
          }
        },
      })
    );
  };

  const handleChangeImage = (info) => {
    setFileList(info?.fileList);
    let fileList = [...info.fileList];
    const urlList = fileList.map((file) => {
      return file.response?.data;
    });
    setBanner(urlList[0]);

  };

  const onChangeStartTime = (date, value) => {
    setStartTime(value);
  };

  const onChangeEndTime = (date, value) => {
    setEndTime(value);
  };

  useEffect(() => {
    setGroupId(groupIdTemp)
  }, [groupIdTemp]);

  const handleClearSpecificError = (keyname) => {
    form.setFields([
      {
        name: keyname,
        errors: [],  // Clear lỗi cho trường cụ thể dựa vào keyname
      },
    ]);
  };

  const disabledDate = (current) => {
    // Disable all dates before today
    return current && current < moment().startOf('day');
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Thông tin Cộng đồng</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ marginTop: '10px' }}>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={22} lg={18} xl={18} xxl={18}>
          <Form
            form={form}
            onFinish={onSave}
            autoComplete="off"
            labelCol={{ xs: 24, sm: 11, md: 8, lg: 6, xl: 6, xxl: 4 }}
            wrapperCol={{ xs: 24, sm: 13, md: 16, lg: 18, xl: 18, xxl: 20 }}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Logo" style={{ fontWeight: '500' }}>
                  <ImgCrop rotate>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                      headers={JWT_HEADER}
                      method="POST"
                      onChange={handleChangeLogo}
                      beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                    >
                      {images ? (
                        <Image
                          className="imgLogo"
                          src={`${REACT_APP_API_KEY_IMAGE}${images}`}
                          alt="avatar"
                          preview={false}
                        />
                      ) : (
                        <div style={{ fontWeight: 'bold' }}>
                          <PlusOutlined />
                          <div style={{ marginTop: 4, fontWeight: 'bold' }}>Logo</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Form.Item label="Banner" style={{ fontWeight: '500' }}>
                  <ImgCrop rotationSlider aspect={16 / 5}>
                    <Upload
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                      headers={JWT_HEADER}
                      fileList={fileList}
                      method="POST"
                      onChange={handleChangeImage}
                      beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                    >
                      {banner ? (
                        <Image
                          className="imgLogo"
                          src={`${REACT_APP_API_KEY_IMAGE}${banner}`}
                          alt="avatar"
                          preview={false}
                        />
                      ) : (
                        <div style={{ fontWeight: 'bold' }}>
                          <PlusOutlined />
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                
                <Form.Item label="Thời gian mở cửa" style={{ fontWeight: '500' }}>
                  <TimePicker
                    format="HH:mm"
                    placeholder={startTime !== '' ? startTime : 'Chọn thời gian'}
                    onChange={onChangeStartTime}
                  />
                </Form.Item>
                <Form.Item label="Thời gian đóng cửa" style={{ fontWeight: '500' }}>
                  <TimePicker
                    format="HH:mm"
                    placeholder={endTime !== '' ? endTime : 'Chọn thời gian'}
                    onChange={onChangeEndTime}
                  />
                </Form.Item>
                <Form.Item
                  label="Tên Cộng đồng"
                  name="name"
                  rules={[{ required: true, message: 'Nhập tên Cộng đồng' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Tên Cộng đồng" />
                </Form.Item>
                <Form.Item
                  label="Số điện thoại"
                  name="phone"
                  rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Số điện thoại" maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ website"
                  name="addressWebsite"
                  rules={[{ required: false, message: 'Nhập địa chỉ website' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Địa chỉ website" />
                  {/* <Input addonBefore={selectBefore} addonAfter={selectAfter} defaultValue="mysite" /> */}
                </Form.Item>
                <Form.Item
                  label="Ví thưởng"
                  rules={[{ message: '' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input
                    value={`${vCoin?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || ''}đ`}
                    disabled
                    placeholder=""
                  />
                </Form.Item>
                <Form.Item
                  label="Private"
                  style={{ fontWeight: '500' }}
                  name="supplierType"
                  rules={[{ required: true,}]}
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  label="Đóng cửa tạm thời"
                  style={{ fontWeight: '500' }}
                  name="closeShop"
                >
                  <Switch />
                </Form.Item>
                {
                  !!closeShop &&
                  <Form.Item
                  label="Thời gian tạm nghỉ"
                  style={{ fontWeight: '500', }}
                  name="timeCloseShop"
                  rules={[{ required: true,message:"Vui lòng chọn thời gian nghỉ"}]}
                >
                  <RangePicker format={"DD-MM-YYYY"} disabledDate={disabledDate} style={{width:"400px"}} placeholder={['Thời gian đóng cửa', 'Thời gian mở trở lại']} />
                </Form.Item>} 
                
                <Form.Item
                  label="Nhóm dịch vụ"
                  style={{ fontWeight: '500' }}
                  name="groupId"
                  rules={[{ required: true, message: 'Chọn nhóm dịch vụ' }]}
                >
                  <div className="selectServiceVb" style={{display:'flex', gap:'10px', flex:"1"}}>
                    <Select
                      mode="multiple"
                        placeholder="Chọn nhóm dịch vụ"
                      value={groupId}
                      onChange={(ev) => {
                        console.log(ev)
                        setGroupId([])
                        setGroupIdTemp(ev)
                        form.setFieldValue('groupId',ev)
                        handleClearSpecificError('groupId')
                      }}
                      style={{ width: '100%' }}
                      options={grServiceList}
                    />
                  
                  </div>
                </Form.Item>
                 
                <Form.Item label="Tỉnh/Thành Phố" 
                 rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/Thành phố' }]}
                  name="province"
                  style={{ fontWeight: '500' }}>
                  <div>
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      value={fieldState.province}
                      options={provinceList}
                      onChange={onChangeSelect('province')}
                    />
                  </div>
                </Form.Item>
          
                <Form.Item
                name="district"
                rules={[{ required: true, message: 'Vui lòng chọn Quận/Huyện' }]}
                label="Quận/ Huyện" style={{ fontWeight: '500',required:true }}>
                  <div>
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      value={fieldState.district}
                      options={districtList}
                      onChange={onChangeSelect('district')}
                    />
                  </div>
                </Form.Item>
                <Form.Item 
                name="ward"
                rules={[{ required: true, message: 'Vui lòng chọn Phường/ Xã' }]}
                label="Phường/ Xã" style={{ fontWeight: '500' }}>
                  <div>
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      value={fieldState.ward}
                      options={wardList}
                      onChange={onChangeSelect('ward')}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  label="Địa chỉ"
                  name="address"
                  rules={[{ required: true, message: 'Nhập địa chỉ' }]}
                  style={{ fontWeight: '500' }}
                >
                  <TextArea autoSize placeholder="Địa chỉ" />
                </Form.Item>
                <Form.Item
                  label="Vị trí "
                  name="latLong"
                  rules={[{ message: 'Nhập vị trí latLong' }]}
                  style={{ fontWeight: '500' }}
                >
                  {isLoaded && (
                    <GoogleMaps
                      markerDefaults={markerDefaults}
                      setMarkerDefaults={setMarkerDefaults}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label="Mô tả"
                  name="description"
                  rules={[{ required: false, message: 'Nhập mô tả' }]}
                  style={{ fontWeight: '500' }}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 6 }} placeholder="Mô tả" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  style={{
                    backgroundColor: '#37B59D',
                    borderRadius: 5,
                    border: '#37B59D',
                    color: 'white',
                    float: 'right',
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Lưu thông tin
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={1} lg={4} xl={4} xxl={4} />
      </Row>
    </>
  );
};

export default Supplier;
