import React from 'react';

import { Col, Row } from 'antd';
import { Column } from '@ant-design/plots';

import ColumnRpTotal from './ColumnRpTotal';

const PieReport = (props) => {
  const dataMoney = props?.dataRPEmployee?.map((e, i) => ({
    name: e.name,
    total: e.money,
  }));
  const dataTotal = props?.dataRPEmployee?.map((e, i) => ({
    name: e.name,
    total: e.total,
  }));
  let data = dataTotal.concat(dataMoney);
  const dataRPEmployee = props?.dataRPEmployee;
  const getDataSwitch = props?.getDataSwitch;

  const config = {
    data,
    xField: 'name',
    yField: 'total',
    dodgePadding: 2222,
    xAxis: {
      label: {
        intervalPadding: 109,
        position: 'middle',
        style: {
          fill: 'black',
          opacity: 0.6,
          fontSize: 10,
          fontWeight: 'bold',
        },
      },
    },
    label: {
      intervalPadding: 109,
      style: {
        fill: 'black',
        fontWeight: 'bold',
        opacity: 0.6,
        fontSize: 10,
        position: 'middle',
      },

      layout: [
        {
          type: 'interval-hide-overlap',
        },
      ],
    },

    columnWidthRatio: 0.5,
    columnStyle: {
      radius: [5, 5, 0, 0],
    },

    style: {
      marginLeft: 20,
    },
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          {getDataSwitch === true ? (
            <div>
              <Column {...config} />
            </div>
          ) : (
            <ColumnRpTotal data={dataRPEmployee} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default PieReport;
