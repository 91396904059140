import React, { useState } from 'react';

import { Col, DatePicker, Row } from 'antd';

export default function FilterTime({
  valueStart,
  valueEnd,
  onChangeTimeStart,
  onChangeTimeEnd,
  styleRangePicker,
  format,
}) {
  const { RangePicker } = DatePicker;
  return (
    <>
      {/* <RangePicker
                onChange={onChangeTime}
                style={styleRangePicker}
                placeholder={placeholder}
                format={format}
            /> */}
      <Row span={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <DatePicker
            onChange={onChangeTimeStart}
            style={styleRangePicker}
            placeholder="Từ ngày"
            format={format}
            value={valueStart}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <DatePicker
            onChange={onChangeTimeEnd}
            style={styleRangePicker}
            placeholder="Đến ngày"
            format={format}
            value={valueEnd}
          />
        </Col>
      </Row>
    </>
  );
}
