import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  TimePicker,
  Upload,
  notification,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CustomSelect from '../../../constant/Select';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../../constant/data';
import { getInfoUser } from '../../../reducers/authReducer';
import { addSupplier } from '../../../reducers/supplierReducer';
import axios from '../../../services/Api';
import '../Supplier.css';
import { useLoadScript } from '@react-google-maps/api';
import GoogleMaps from '../../../components/GoogleMap';
import { handleBeforeUpload } from '../../../utils';

const UpdSubSupplier = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAtQwrcO3SLM-5PmFgHLFd31FE-x_o3cg8', // Add your API key
  });
  const router = useParams();
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dataUser = useSelector((store) => store?.authReducer.infoUser);

  const initState = {
    district: null,
    province: null,
    ward: null,
  };
  const [fieldState, setFieldState] = useState(initState);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [provinceId, setProvinceId] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [wardId, setWardId] = useState('');
  const [id, setId] = useState(0);
  const provinceList = provinces?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const districtList = districts?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const wardList = wards?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const [images, setImages] = useState('');
  const [banner, setBanner] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [endTime, setEndTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [markerDefaults, setMarkerDefaults] = useState();

  useEffect(() => {
    dispatch(getInfoUser());
  }, [dispatch]);

  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });

  const handleChangeLogo = (info) => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    if (status === 'done') {
      const urlList = fileList.map((file) => {
        return file.response?.data;
      });
      setImages(urlList);
    } else if (status === 'error') {
    }
  };

  useEffect(() => {
    getProvince();
    getSupplier();
  }, []);

  const getSupplier = () => {
    // axios.get(`/supplier/shift-supplier?supplierId=${router.supplierId}`).then((response) => {
    // if (response.data.data.length > 0) {
    //   const _data = response.data.data;
    //   const data = _data.find((item) => item.id == router.supplierId);
    //   setId(data.id);
    //   setStartTime(data?.startDate);
    //   setEndTime(data?.endDate);
    //   form.setFieldsValue(data);
    //   setFieldState((prev) => ({
    //     ...prev,
    //     province: data.province,
    //     district: data.district,
    //     ward: data.ward,
    //   }));
    //   setImages(data.image === null ? images : data?.image);
    //   setBanner(data?.banner === null ? banner : data?.banner);
    //   if (data?.banner === null) {
    //     setFileList([]);
    //   } else {
    //     setFileList([
    //       {
    //         uid: '-1',
    //         name: 'image.png',
    //         status: 'done',
    //         url: `${REACT_APP_API_KEY}${data?.banner == null ? banner : data?.banner}`,
    //       },
    //     ]);
    //   }
    //   if (data.id === null) {
    //     localStorage.setItem('supplierId', -1);
    //   } else {
    //     localStorage.setItem('supplierId', data.id);
    //   }
    // }
    // });
  };

  const getProvince = () => {
    axios.get(`/address/province?page=0&size=500`).then((response) => {
      setProvinces(response?.data?.data);
    });
  };

  const getDistrict = (provinceId) => {
    axios.get(`/address/district?page=0&size=500&provinceId=${provinceId}`).then((response) => {
      setDistricts(response?.data?.data);
    });
  };

  const getWard = (districtId) => {
    axios.get(`/address/ward?page=0&size=500&districtId=${districtId}`).then((response) => {
      setWards(response?.data?.data);
    });
  };

  const onChangeSelect = (type) => (ev) => {
    if (type === 'province') {
      getDistrict(ev);
      setProvinceId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: provinceList?.find((e) => e.key === ev)?.label,
        district: null,
        ward: null,
      }));
    }
    if (type === 'district') {
      getWard(ev);
      setDistrictId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: districtList?.find((e) => e.key === ev)?.label,
        ward: null,
      }));
    }
    if (type === 'ward') {
      setWardId(ev);
      setFieldState((prev) => ({
        ...prev,
        [type]: wardList?.find((e) => e.key === ev)?.label,
      }));
    }
  };

  const onSave = (value) => {
    if (!markerDefaults) {
      notification.error({
        message: 'Bạn cần chọn vị trí trước khi lưu thông tin!',
      });
      return;
    }
    let st1 = startTime.split(':')[0];
    let st2 = parseInt(st1) * 60;
    let st3 = startTime.split(':')[1];
    let startTimeUpd = parseInt(st2) + parseInt(st3);

    let en1 = endTime.split(':')[0];
    let en2 = parseInt(en1) * 60;
    let en3 = endTime.split(':')[1];
    let endTimeUpd = parseInt(en2) + parseInt(en3);
    const body = {
      id: id,
      name: value.name,
      address: value.address,
      phone: value.phone,
      description: value.description,
      provinceId: provinceId,
      districtId: districtId,
      wardId: wardId,
      image: images,
      banner: banner,
      latLong: `${markerDefaults?.lat} - ${markerDefaults?.lng}`,
      startTime: startTimeUpd,
      endTime: endTimeUpd,
      supplierId: router.id,
      userId: dataUser?.id,
    };
    dispatch(
      addSupplier(body, {
        onSuccess: (res) => {
          if(res?.data?.status == 200 && res?.data?.data?.status?.statusCode == 200){
            notification.success({
              message: `Lưu thông tin thành công`,
            });
          }
          else{
            notification.error({
              message:res?.data?.data?.status?.message || res?.data?.data?.message || `Lưu thông tin thất bại`,
            });
          }
        },
        onError: (er) => {
          if (er.data.message === '') {
            notification.error({
              message: `Lưu thông tin thất bại`,
            });
          } else {
            notification.error({
              message: `${er?.data?.status?.message}`,
            });
          }
        },
      })
    );
  };

  const handleChangeImage = (info) => {
    setFileList(info?.fileList);
    let fileList = [...info.fileList];
    const urlList = fileList.map((file) => {
      return file.response?.data;
    });
    setBanner(urlList);
  };

  const onPreview = async (file) => {
    const check = file.response;
    if (check === undefined) {
      window.open(file.url, '_blank');
    } else {
      const link = file?.response?.image;
      window.open(`${REACT_APP_API_KEY}${link}`, '_blank');
    }
  };

  const onChangeStartTime = (date, value) => {
    setStartTime(value);
  };

  const onChangeEndTime = (date, value) => {
    setEndTime(value);
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Cộng đồng</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ marginTop: '10px' }}>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={22} lg={18} xl={18} xxl={18}>
          <Form
            form={form}
            onFinish={onSave}
            autoComplete="off"
            labelCol={{ xs: 24, sm: 11, md: 8, lg: 6, xl: 6, xxl: 4 }}
            wrapperCol={{ xs: 24, sm: 13, md: 16, lg: 18, xl: 18, xxl: 20 }}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Logo" style={{ fontWeight: '500' }}>
                  <ImgCrop rotate>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                      headers={JWT_HEADER}
                      method="POST"
                      onChange={handleChangeLogo}
                      beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                    >
                      {images ? (
                        <Image
                          className="imgLogo"
                          src={`${REACT_APP_API_KEY_IMAGE}${images}`}
                          alt="avatar"
                          preview={false}
                        />
                      ) : (
                        <div style={{ fontWeight: 'bold' }}>
                          <PlusOutlined />
                          <div style={{ marginTop: 4, fontWeight: 'bold' }}>Logo</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Form.Item label="Banner" style={{ fontWeight: '500' }}>
                  <Upload
                    multiple
                    action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                    headers={JWT_HEADER}
                    method="POST"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleChangeImage}
                    onPreview={onPreview}
                    beforeUpload={handleBeforeUpload} // Gọi hàm nén ảnh trước khi upload
                  >
                    {banner.length < 5 && '+ Upload'}
                  </Upload>
                </Form.Item>
                <Form.Item label="Thời gian mở cửa" style={{ fontWeight: '500' }}>
                  <TimePicker
                    format=" HH:mm"
                    placeholder={startTime !== '' ? startTime : 'Chọn thời gian'}
                    onChange={onChangeStartTime}
                  />
                </Form.Item>
                <Form.Item label="Thời gian đóng cửa" style={{ fontWeight: '500' }}>
                  <TimePicker
                    format=" HH:mm"
                    placeholder={endTime !== '' ? endTime : 'Chọn thời gian'}
                    onChange={onChangeEndTime}
                  />
                </Form.Item>
                <Form.Item
                  label="Tên Cộng đồng"
                  name="name"
                  rules={[{ required: true, message: 'Nhập tên Cộng đồng' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Tên Cộng đồng" />
                </Form.Item>
                <Form.Item
                  label="Số điện thoại"
                  name="phone"
                  rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                  style={{ fontWeight: '500' }}
                >
                  <Input placeholder="Số điện thoại" maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}/>
                </Form.Item>
                <Form.Item label="Thành Phố" style={{ fontWeight: '500' }}>
                  <div>
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      value={fieldState.province}
                      options={provinceList}
                      onChange={onChangeSelect('province')}
                    />
                  </div>
                </Form.Item>
                <Form.Item label="Quận/ Huyện" style={{ fontWeight: '500' }}>
                  <div>
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      value={fieldState.district}
                      options={districtList}
                      onChange={onChangeSelect('district')}
                    />
                  </div>
                </Form.Item>
                <Form.Item label="Phường/ Xã" style={{ fontWeight: '500' }}>
                  <div>
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      value={fieldState.ward}
                      options={wardList}
                      onChange={onChangeSelect('ward')}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  label="Địa chỉ"
                  name="address"
                  rules={[{ required: true, message: 'Nhập địa chỉ' }]}
                  style={{ fontWeight: '500' }}
                >
                  <TextArea autoSize placeholder="Địa chỉ" />
                </Form.Item>
                {/* <Form.Item
                  label="Vị trí latLong"
                  name="latLong"
                  rules={[{ required: true, message: 'Nhập vị trí latLong' }]}
                  style={{ fontWeight: '500' }}
                >
                  <TextArea autoSize placeholder="Vị trí latLong" />
                </Form.Item> */}
                <Form.Item
                  label="Vị trí "
                  name="latLong"
                  rules={[{ message: 'Nhập vị trí latLong' }]}
                  style={{ fontWeight: '500' }}
                >
                  {isLoaded && (
                    <GoogleMaps
                      markerDefaults={markerDefaults}
                      setMarkerDefaults={setMarkerDefaults}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label="Mô tả"
                  name="description"
                  rules={[{ required: true, message: 'Nhập mô tả' }]}
                  style={{ fontWeight: '500' }}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 6 }} placeholder="Mô tả" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  style={{
                    backgroundColor: '#37B59D',
                    borderRadius: 5,
                    border: '#37B59D',
                    color: 'white',
                    float: 'right',
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Lưu thông tin
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={1} lg={4} xl={4} xxl={4} />
      </Row>
    </>
  );
};

export default UpdSubSupplier;
