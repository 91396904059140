import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Col,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  HeartOutlined,
  HomeOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import CustomSreach from '../../constant/Sreach';
import { deleteBLog } from '../../reducers/blogReducer';
import axios from '../../services/Api';

import InsBlog from './InsBlog';
import UpdBlog from './UpdBlog';

import parse from 'html-react-parser';
import moment from 'moment';

const Blog = () => {
  const dispatch = useDispatch();
  const supplierId = localStorage.getItem('supplierId');
  const history = useHistory();

  const [totalPage, setTotalPage] = useState('');
  const [dataTable, setDataTable] = useState([]);
  console.log('dataTable', dataTable?.id);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [renderDel, setRenderDel] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (supplierId !== '') {
      getDataBlog();
    }
    setRenderDel(false);
  }, [supplierId, page, size, renderDel === true, query === '']);

  const getDataBlog = () => {
    axios.get(`/blog/get?supplierId=${supplierId}&page=${page}&size=${size}`).then((response) => {
      setDataTable(response?.data?.data[0]);
      setTotalPage(response?.data?.totalSize);
    });
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const onDelete = (record) => {
    dispatch(
      deleteBLog(record?.id, {
        onSuccess: (message) => {
          notification.success({
            // message: `${message?.data?.data?.message}`,
            message: `Xóa bài viết thành công`,
          });
          setRenderDel(true);
        },
        onError: (message) => {
          notification.error({
            // message: `${message?.data?.message}`,
            message: `Xóa thất bại`,
          });
        },
      })
    );
  };

  // const columns = [
  //   {
  //     title: "STT",
  //     dataIndex: "stt",
  //     render: (t, r, i) => i + 1,
  //     width: "5%",
  //     ellipsis: {
  //       showTitle: false,
  //     },
  //   },
  //   {
  //     title: "Tên bài viết",
  //     dataIndex: "title",
  //     key: "title",
  //     width: "20%",
  //     ellipsis: {
  //       showTitle: false,
  //     },
  //     render: (value) => (
  //       <Tooltip placement="topLeft" title={value}>
  //         {value}
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: "Thời gian tạo",
  //     dataIndex: "createTime",
  //     key: "createTime",
  //     width: "15%",
  //     ellipsis: {
  //       showTitle: false,
  //     },
  //     responsive: ["lg", "xs", "sm", "md", "xl"],
  //     render: (value) => (
  //       <Tooltip placement="topLeft" title={moment(value).format("DD-MM-YYYY kk:mm:ss")}>
  //         <span> {moment(value).format("DD-MM-YYYY kk:mm:ss")}</span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: "Thời gian cập nhật",
  //     dataIndex: "updateTime",
  //     key: "updateTime",
  //     width: "15%",
  //     responsive: ["lg", "xs", "sm", "md", "xl"],
  //     ellipsis: {
  //       showTitle: false,
  //     },
  //     render: (value) => (
  //       <Tooltip placement="topLeft" title={moment(value).format("DD-MM-YYYY kk:mm:ss")}>
  //         <span> {moment(value).format("DD-MM-YYYY kk:mm:ss")}</span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: "Nội dung",
  //     dataIndex: "content",
  //     key: "content",
  //     width: "35%",
  //     ellipsis: {
  //       showTitle: false,
  //     },
  //     render: (value) => (
  //       <span >
  //         {value}
  //       </span>

  //     ),
  //   },
  //   {
  //     title: "Trạng thái",
  //     dataIndex: "status",
  //     key: "status",
  //     width: "10%",
  //     render: (value) => (
  //       <>
  //         <span
  //           style={{
  //             background: value === true ? "#00d084" : "#BF4340",
  //             borderRadius: 5,
  //             fontSize: "13px",
  //             color: "#FFFFFF",
  //             fontWeight: "bold",
  //             padding: 5,
  //             border: value === true ? "#00d084" : "#BF4340",
  //           }}
  //         >
  //           {value === true ? "Hiển thị" : "Không hiển thị"}
  //         </span>
  //       </>
  //     ),
  //   },

  //   {
  //     title: "Chức năng",
  //     key: "action",
  //     width: "10%",
  //     render: (record) => (
  //       <Space>

  //         <Popover content={<h4> Chọn bài viết hiển thị trên Web</h4>}>
  //           <HeartOutlined
  //             style={{
  //               background: "#EB144C",
  //               borderRadius: 3,
  //               fontSize: "12px",
  //               color: "white",
  //               padding: 5,
  //               border: "1px solid #EB144C",
  //             }}
  //             onClick={() => onShowUpd(record)}
  //           />
  //         </Popover>

  //         <Popover content={<h4> Sửa thông tin bài viết</h4>}>
  //           <EditOutlined
  //             style={{
  //               background: "rgb(55, 181, 157)",
  //               borderRadius: 3,
  //               fontSize: "12px",
  //               color: "white",
  //               padding: 5,
  //               border: "1px solid rgb(55, 181, 157)",
  //             }}
  //             onClick={() => onShowUpd(record)}
  //           />
  //         </Popover>

  //         <Popconfirm
  //           title="Bạn có chắc chắn muốn xóa bài viết?"
  //           onConfirm={() => onDelete(record)}
  //         >
  //           <Popover content={<h4> Xóa bài viết</h4>}>
  //             <DeleteOutlined
  //               style={{
  //                 background: "rgb(240, 65, 52)",
  //                 borderRadius: 3,
  //                 fontSize: "12px",
  //                 color: "white",
  //                 padding: 5,
  //                 border: "1px solid rgb(240, 65, 52)",
  //               }}
  //             />
  //           </Popover>
  //         </Popconfirm>
  //       </Space>
  //     ),
  //   },
  // ];

  const onShowIns = async () => {
    history.push('/insBLog');
  };

  const onShowUpd = async (record) => {
    history.push('/updBlog', { id: record?.id });
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onsearch = () => {};

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  {' '}
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Bài viết</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: '20px' }}>
        {dataTable?.id !== undefined ? <UpdBlog id={dataTable.id} /> : <InsBlog />}

        {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <div className="customer-search">
            <CustomSreach onClick={onsearch} onChange={onChangeQuery} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}></Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Button
            style={{ marginLeft: "6px", backgroundColor: "rgb(55, 181, 157)", borderRadius: 5, border: "rgb(55, 181, 157)", float: "right" }}
            type="primary"
            onClick={onShowIns}
          >
            <PlusOutlined /> Thêm
          </Button>
        </Col>

        <Col span={24} style={{ paddingTop: "20px" }}>
          <Table
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={{
              onChange: page => {
                setPage(page - 1)
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
          />
        </Col> */}
      </Row>
    </>
  );
};
export default Blog;
