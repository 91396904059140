import axios from "axios";

const ERROR_AUTHEN = '401';
const NO_ROLE = '403';
const MULTI_DEVICE = '423';

export const file_headers = () => {
    return {
        token: localStorage.getItem("token"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
        "X-XSS-Protection": '1; mode=block',
        "Strict-Transport-Security": 'max-age=31536000; includeSubDomains',
        "X-Content-Type-Options": 'nosniff'
    };
};

export const postFormDataFile = async (url, { obj }) => {
    try {
        const response = await axios({
            method: 'POST',
            url: url,
            data: obj,
            headers: file_headers(),
        });
        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = '/login';
            }
            if (response.data.code === MULTI_DEVICE) {
                window.location = '/login-session';
            }
            return response.data;
        }
        if (response.status === 201) {
            return response;
        }
    } catch (error) {
        console.error('Failed to POST data:', error);
        throw error;
    }
};