import axios from '../services/Api';

export const SENDMESS = 'SENDMESS';

export const sendMess = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: SENDMESS,
      data: await axios.post(`/conversation/reply`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};
