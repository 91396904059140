import axios from '../services/Api';

export const INSACCOUNT = 'INSACCOUNT';
export const LOCKACCOUNT = 'LOCKACCOUNT';
export const UPDSTAFF = 'UPDSTAFF';
export const INSBANNER = 'INSBANNER';
export const UPDATEBANNER = 'UPDATEBANNER';
export const GETBANNER = 'GETBANNER';
export const UPTOPBANNER = 'UPTOPBANNER';
export const DRAG_BANNER = 'DRAG_BANNER';
export const GET_TYPE_NOTI = 'GET_TYPE_NOTI';

export const addAccount = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSACCOUNT,
      data: await axios.post(`/auth/save/employee`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const lockStaff = (id, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: LOCKACCOUNT,
      data: await axios.post(`/employee/delete?id=${id}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const updStaff = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: UPDSTAFF,
      data: await axios.post(`/employee/save`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};


export const addBanner = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSBANNER,
      data: await axios.post(`/banner/insert`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const updateBanner = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: UPDATEBANNER,
      data: await axios.post(`/banner/update`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};


export const getBanner = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: GETBANNER,
      data: await axios.get(`/banner/get-id?id=${body?.id}`, {}),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const upTopBanner = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: UPTOPBANNER,
      data: await axios.post(`/banner/sort-top?id=${body?.id}`, {}),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const onDragPosition = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: DRAG_BANNER,
      data: await axios.post(`/banner/sort-move?fromId=${body?.fromId}&toId=${body?.toId}`, {}),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const getTypeReceiveNoti = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: GET_TYPE_NOTI,
      data: await axios.get(`/notification/get-type-send`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const getTypeNotification = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: GET_TYPE_NOTI,
      data: await axios.get(`/notification/get-type`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const sendNotification = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: GET_TYPE_NOTI,
      data: await axios.post(`/notification/send`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const searchNCC = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: GET_TYPE_NOTI,
      data: await axios.get(`/supplier/search-ad`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};