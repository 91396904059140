import axios from '../services/Api';

export const INSSUPPLIER = 'INSSUPPLIER';
export const INSSUPPLIER_SUB = 'INSSUPPLIER_SUB';
export const FIND_USER = 'FIND_USER';
export const SUPPLIER_INFO = 'SUPPLIER_INFO';

const initState = {
  supplierInfo: null,
};

export const addSupplier = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSSUPPLIER,
      data: await axios.post(`/supplier/save`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const addSubSupplier = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: INSSUPPLIER_SUB,
      data: await axios.post(`/supplier/save-child`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const checkUser = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: FIND_USER,
      data: await axios.get(`/auth/search?phone=${body.phone}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const getSupplierInfo = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: SUPPLIER_INFO,
      data: await axios.get(`/supplier/info`),
    });
    meta?.onSuccess(result);
  } catch (err) {
    meta?.onError(err);
  }
};

const supplierReducer = (state = initState, action) => {
  switch (action.type) {
    case SUPPLIER_INFO:
      return {
        ...state,
        supplierInfo: action?.data,
      };
    default:
      return state;
  }
};

export default supplierReducer;
