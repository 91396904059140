import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import loginBackGroundImageURL from '../../../assets/background3.jpg';
import { Button, Card, Col, Form, Input, message, notification, Row } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

// import image from '../../../assets/background.jpg';
import logo from '../../../assets/logochuan.png';
import InputOTPs from './InputOTPs';
import axios from 'axios';
import { REACT_APP_API_KEY } from '../../../constant/data';

export default function Register() {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [payloadRegister, setPayloadRegister] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const onRegister = (values) => {
    if (values?.password === values?.repassword) {
      const body = {
        username: values?.username,
        password: values?.password,
        email: values?.email,
        phone: values?.phone,
        name: values?.name,
        avatar: '',
      };
      setPayloadRegister(body)
      setIsLoading(true)
      getOTP(body).then(res =>{
        if(res.status == 200){
          message.success("Mã OTP đã được gửi tới gmail của bạn!")
          setIsModalVisible(true)
          setTimeout(function() {
            console.log("loading")   
            setIsLoading(false)
          }, 1000);
        }
        else{
          message.error("Lỗi hệ thống!")
          setTimeout(function() {
            console.log("loading")   
            setIsLoading(false)
          }, 1000);
        }
  
    
      })
    } else {
      notification.error({
        message: `Mật khẩu không trùng khớp`,
      });
    }
  };

  const getOTP = async (payloadRegister) => {
    return await axios({
      method: 'POST',
      url: `${REACT_APP_API_KEY}/auth/send-otp`,
      params: {gmail:payloadRegister?.email},
    });
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${loginBackGroundImageURL})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100%',
        }}
      >
        <Row span={24}>
          <Col xs={0} sm={0} md={0} lg={3} xl={3} xxl={3} />
          <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
            <Row span={24} style={{ paddingTop: 50, paddingBottom: 50 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <div>
                  <img width="60%" src={logo} alt="logo" />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={10}>
                <Card
                  bordered={false}
                  bodyStyle={{ backgroundColor: '#f0f2f5', borderRadius: 10 }}
                  style={{
                    maxWidth: '80%',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    background: 'white',
                    borderRadius: '50px',
                    boxShadow: 'rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <Form
                    form={form}
                    id="insUser-form"
                    name="normal_Resgiter"
                    onFinish={onRegister}
                    autoComplete="off"
                  >
                    <Form.Item
                      style={{ fontWeight: 'bold' }}
                      name="username"
                      rules={[{ required: true, message: 'Nhập tên đăng nhập' }]}
                    >
                      <Input placeholder="Tên đăng nhập" />
                    </Form.Item>

                    <Form.Item
                      style={{ fontWeight: 'bold' }}
                      name="password"
                      rules={[{ required: true, message: 'Nhập mật khẩu' }]}
                    >
                      <Input.Password placeholder="Mật khẩu" />
                    </Form.Item>

                    <Form.Item
                      style={{ fontWeight: 'bold' }}
                      name="repassword"
                      rules={[{ required: true, message: 'Nhập lại mật khẩu' }]}
                    >
                      <Input.Password placeholder="Nhập lại mật khẩu" />
                    </Form.Item>

                    <Form.Item
                      style={{ fontWeight: 'bold' }}
                      name="name"
                      rules={[{ required: true, message: ' Nhập Họ và tên' }]}
                    >
                      <Input placeholder="Họ và tên" />
                    </Form.Item>

                    <Form.Item
                      style={{ fontWeight: 'bold' }}
                      name="phone"
                      rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                    >
                    <Input placeholder="Nhập số điện thoại" maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}  />
                    </Form.Item>

                    <Form.Item
                      style={{ fontWeight: 'bold' }}
                      name="email"
                      rules={[{ required: true, message: 'Nhập email' }]}
                    >
                      <Input placeholder="Nhập email" />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: '100%',
                          height: '40px',
                          borderRadius: 6,
                          backgroundColor: '#1877f2',
                          border: 'none',
                          fontWeight: 'bold',
                          fontSize: '1.1em',
                        }}
                        loading={isLoading}
                      >
                        Xác nhận
                      </Button>
                    </Form.Item>
                    <hr />
                  </Form>
                  <Link to="/login">
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        width: '100%',
                        height: '35px',
                        borderRadius: 6,
                        backgroundColor: '#ABB8C3',
                        border: 'none',
                        fontWeight: 'bold',
                        fontSize: '1.1em',
                      }}
     
                    >
                      Quay lại <ArrowRightOutlined />
                    </Button>
                  </Link>
                </Card>
              </Col>
              <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={2} />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={0} lg={3} xl={3} xxl={3} />
        </Row>
      </div>
      {
        isModalVisible && <InputOTPs isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} getOTP={getOTP} payloadRegister={
          payloadRegister
        } />
      }
    </>
  );
}
