// const ListBlog = () => {
//   return (
//     <div>Hello</div>
//   )
// }
// export default ListBlog;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { EditOutlined, DeleteOutlined ,HomeOutlined,PlusOutlined} from '@ant-design/icons';
import { Breadcrumb, Button, Col, Popconfirm, Popover, Row, Space, Table, Tag, Tooltip, message } from 'antd';
import Filter from '../../constant/Filter';
import axios from '../../services/Api';

import { useDispatch } from 'react-redux';
import { approveUser } from '../../reducers/authReducer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ListBlog = () => {
  const history = useHistory();
  const supplierId = localStorage.getItem('supplierId');
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState('');
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);

  useEffect(() => {
    getDataPost();
  }, [page, size, renderSreach]);

  const getDataPost = () => {
    axios
      .get(`/api-news/supplier-get?supplierId=${supplierId}&page=${page}&size=${size}`)
      .then((response) => {
        const arrData = response?.data?.data;
        setData(arrData);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onShowUpd = (value) => {
    history.push(`update-blog/${value.id}`)
  }


  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: 70,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',

      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',

      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Số lượt xem',
      dataIndex: 'numberView',
      key: 'numberView',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',

      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
          <Tag color={value == 0 ? 'volcano':'green'}>{value === 0 ? 'No Active':'Active'}</Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (record) => (
        <Space>
          <Popover content={<h4> Sửa thông tin</h4>}>
            <EditOutlined
              style={{
                background: 'rgb(55, 181, 157)',
                borderRadius: 5,
                fontSize: '12px',
                color: 'white',
                padding: 5,
                border: '1px solid rgb(55, 181, 157)',
              }}
              onClick={() => onShowUpd(record)}
            />
          </Popover>
          {/* <Popconfirm title="Bạn có chắc chắn muốn xóa dịch vụ?" onConfirm={() => onDelete(record)}>
            <Popover content={<h4> Xóa bài viết</h4>}>
              <DeleteOutlined
                style={{
                  background: 'rgb(240, 65, 52)',
                  borderRadius: 5,
                  fontSize: '12px',
                  color: 'white',
                  padding: 5,
                  border: '1px solid rgb(240, 65, 52)',
                }}
              />
            </Popover>
          </Popconfirm> */}
        </Space>
      ),
    },
    // {
    //   title: 'Thời gian đăng ký tài khoản',
    //   dataIndex: 'createdDate',
    //   key: 'createdDate',
    // },
  ];

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  {' '}
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Danh sách bài viết</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
      <Col span={24} style={{ borderBottom: '1px solid #d9d9d9',marginBottom:'10px' }}>
          <Row span={24}>
            <Col span={12}>
            </Col>
            <Col span={12}>
              <Button
                style={{
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                  marginBottom:"10px"
                }}
                type="primary"
                onClick={() => {
                  history.push('/insBlog');
                }}
              >
                <PlusOutlined />
                Thêm
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            size="small"
            pagination={{
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
            scroll={{ x: 'calc(400px + 50%)' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ListBlog;
