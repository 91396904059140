import axios from '../services/Api';

export const CHANGESTATUS = 'CHANGESTATUS';

export const changeStatusNoti = (status, ids, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: CHANGESTATUS,
      data: await axios.post(`/history-notification/status?status=${status}&ids=${ids}`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const readAll = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: CHANGESTATUS,
      data: await axios.post(`/history-notification/unread-all`),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};