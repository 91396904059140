import React from 'react';

import { Select } from 'antd';
const { Option } = Select;

export default function BookingSelect({
  options = [],
  onChange,
  styles,
  defaultValue,
  title,
  value,
  placeholder,
  showSearch,
  filterOption,
  mode,
  allowClear,
}) {
  return (
    <div>
      <div>{title}</div>
      <Select
        allowClear={allowClear}
        mode={mode}
        filterOption={filterOption}
        showSearch={showSearch}
        defaultValue={defaultValue}
        style={{ ...styles }}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      >
        {options.map((e, i) => (
          <Option
            id={i}
            key={i}
            value={e.value}
            name={e.label}
            price={e.price}
            description={e.description}
          >
            {e.label}
          </Option>
        ))}
      </Select>
    </div>
  );
}
