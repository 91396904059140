import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, message, Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { addAccount } from '../../reducers/staffReducer';
import { getInfoUser } from '../../reducers/authReducer';

const InsStaff = (props) => {
  const dispatch = useDispatch();
  const supplierId = localStorage.getItem('supplierId');

  const { show, handleCancel } = props;
  const [form] = Form.useForm();

  const dataUser = useSelector((store) => store?.authReducer.infoUser);

  useEffect(() => {
    dispatch(getInfoUser());
  }, [dispatch]);

  const handleCancelIns = () => {
    handleCancel();
    form.setFieldsValue({
      username: '',
      password: '',
      name: '',
      email: '',
      phone: '',
    });
  };

  const onIns = (values) => {
    if (values?.password === values?.checkPassword) {
      const body = {
        username: values?.username,
        password: values?.password,
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        userId: dataUser.id,
        supplierId: supplierId,
      };
      dispatch(
        addAccount(body, {
          onSuccess: (response) => {
            if(response?.data?.data?.status?.statusCode == 200){
              notification.success({
                message: `Thêm nhân viên thành công`,
              });
              handleCancelIns();
            }
            else{
              notification.error({
                message: response?.data?.data?.status?.message || 'Đã có lỗi xảy ra',
              });
            }
          },
          onError: (res) => {
            notification.error({
              message: res.data?.status?.message,
            });
          },
        })
      );
    } else {
      message.error('Mật khẩu không chính xác');
    }
  };

  return (
    <Modal
      title={`THÊM NHÂN VIÊN`}
      open={show}
      onCancel={handleCancelIns}
      okButtonProps={{ form: 'insStaff', key: 'submit', htmlType: 'submit' }}
    >
      <Form
        form={form}
        id="insStaff"
        onFinish={onIns}
        autoComplete="off"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
      >
        <Form.Item
          label="Tài khoản"
          name="username"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập tài khoản' }]}
        >
          <Input placeholder="Tài khoản nhân viên" />
        </Form.Item>

        <Form.Item
          label="Mật khẩu"
          name="password"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập mật khẩu' }]}
        >
          <Input.Password placeholder="Mật khẩu" />
        </Form.Item>

        <Form.Item
          label="Nhập lại mật khẩu"
          name="checkPassword"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập lại mật khẩu' }]}
        >
          <Input.Password placeholder="Nhập lại mật khẩu" />
        </Form.Item>

        <Form.Item
          label="Tên nhân viên"
          name="name"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập tên nhân viên' }]}
        >
          <Input placeholder="Tên nhân viên" />
        </Form.Item>

        <Form.Item
          label="Số điện thoại"
          name="phone"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập số điện thoại' }]}
        >
          <Input placeholder="Số điện thoại"  
          maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập Email' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default InsStaff;
